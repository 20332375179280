import { Trash2, Pencil, X, Check } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { Form } from 'react-final-form';

import { AuthUser, UserRole } from "../../@types/authUser";
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher } from "../../utils/fetcher";

interface UserManagementRowProps {
    data: AuthUser
    roles: UserRole[] | undefined
    shouldRefresh: (refresh: boolean) => void
    currentUserId: string
}

function UserManagementRow(props: UserManagementRowProps): JSX.Element {

    const [name, setName] = useState<string>();
    const [userName, setUserName] = useState<string>();
    const [role, setRole] = useState<UserRole>();
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setName(props.data.fullName);
        
        setUserName(props.data.userName);
        if (props.data.userName === "") {
            setDisabled(false);
        }

        if (!!props.data.roles) {
            setRole(props.data.roles[0])
        }
    }, [props.data.id])

    const deleteUser = async () => {
        const confirmed = window.confirm("Opravdu chcete smazat uživatele?");
        if (confirmed) {
            await apiFetcher(`user?userId=${props.data.id}`, {
                method: HTTPMethod.DELETE,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            props.shouldRefresh(true);
        }
    }

    const validate = async () => {
        const dataAreNotNull = !isNullOrEmpty(name) && !isNullOrEmpty(userName) && !isNullOrEmpty(role?.id);
        const response = await apiFetcher("user/validate?userName=" + userName, {
            method: HTTPMethod.GET,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const isUserNameValid = response.json as boolean;
            return dataAreNotNull && isUserNameValid;
        }

        return false;
    }

    function isNullOrEmpty(str: string | null | undefined): boolean {
        return str === null || str === undefined || str.trim() === '';
    }

    const onSubmit = async () => {
        const isValid = await validate();
        if (isValid) {
            if (isNullOrEmpty(props.data.id)) {
                await apiFetcher("user", {
                    method: HTTPMethod.POST,
                    body: { id: "", userName: userName, fullName: name, roles: [role] },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            } else {
                await apiFetcher("user", {
                    method: HTTPMethod.PUT,
                    body: { id: props.data.id, userName: userName, fullName: name, roles: [role] },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            }
            props.shouldRefresh(true);
            setDisabled(true);
        } else {
            alert("V zadaných datech je chyba");
        }
    }

    return (
        <>
            <Form onSubmit={onSubmit}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={4}>
                                <TextField
                                    disabled={disabled}
                                    label="Uživatelské jméno"
                                    name="username"
                                    initialValue={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </Col>
                            <Col xs={4}>
                                <TextField
                                    disabled={disabled}
                                    label="Jméno"
                                    name="fullname"
                                    initialValue={name}
                                    onChange={ (e) => setName(e.target.value)}
                                />
                            </Col>
                            <Col xs={3}>
                                {props.roles && <SelectField
                                    disabled={disabled}
                                    name="Role"
                                    placeholder="Role"
                                    label="Role"
                                    options={props.roles.map((value) => ({
                                        value: value.id,
                                        label: value.normalizedName
                                    }))}
                                    initialValue={role?.id || ""}
                                    onChange={(e) => {
                                        var newRole = props.roles?.filter(role => role.id === e.target.value)[0];
                                        if (!!newRole) {
                                            setRole(newRole);
                                        }

                                    }}
                                    allowEmpty={false}
                                />}
                            </Col>
                            <Col xs={1}>
                                {disabled && props.currentUserId !== props.data.id && <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Tooltip>Upravit</Tooltip>}
                                >
                                    <Button
                                        style={{ marginTop: '25px' }}
                                        variant="info"
                                        onClick={() => setDisabled(false)}
                                    >
                                        <Pencil size={26} />
                                    </Button>
                                </OverlayTrigger>}
                                {!disabled && <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Tooltip>Potvrdit</Tooltip>}
                                >
                                    <Button
                                        type="submit"
                                        style={{ marginTop: '25px' }}
                                        variant="success"
                                    >
                                        <Check size={26} />
                                    </Button>
                                </OverlayTrigger>}
                                {!disabled && <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Tooltip>Reset</Tooltip>}
                                >
                                    <Button
                                        style={{ marginTop: '25px' }}
                                        variant="danger"
                                        onClick={() => { form.reset(); setDisabled(true) }}
                                    >
                                        <X size={26} />
                                    </Button>
                                </OverlayTrigger>}
                                {disabled && props.currentUserId !== props.data.id && < OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Tooltip>Smazat</Tooltip>}
                                >
                                    <Button
                                        style={{ marginTop: '25px' }}
                                        variant="danger"
                                        onClick={deleteUser}
                                    >
                                        <Trash2 size={26} />
                                    </Button>
                                </OverlayTrigger>}
                            </Col>
                        </Row>
                    </form>
                )}
            />
        </>
    )

}

export default UserManagementRow;