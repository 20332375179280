import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import React from 'react';
import Button from 'react-bootstrap/Button';

function QueryError({
	onClick,
}: {
	onClick: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
	) => Promise<QueryObserverResult<unknown, unknown>>;
}): JSX.Element {
	return (
		<div>
			Data se nepovedlo načíst. {/* @ts-ignore */}
			<Button variant="primary" className='ml-2' onClick={onClick}>
				Zkusit znovu
			</Button>
		</div>
	);
}
export default QueryError;
