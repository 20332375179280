import React from 'react';

import useListQuery from './useListQuery';
import { BasicListItem, EquipmentListItem } from '../../@types/lists';
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { BRAND } from '../../constants/lists';
import { mapBasicListToOptions } from '../../utils/lists';

function EquipmentListForm({
	initialValues,
}: {
	initialValues?: EquipmentListItem | null;
}): JSX.Element {
	const brandsQuery = useListQuery(BRAND);
	return (
		<>
			<SelectField
				label="Brand"
				name="brandId"
				options={mapBasicListToOptions(
					(brandsQuery.data as BasicListItem[]) || []
				)}
				initialValue={initialValues?.brandId}
				placeholder="Vyberte brand"
			/>
			<TextField label="Kód" name="code" initialValue={initialValues?.code} />
			<TextField
				as="textarea"
				label="Popis"
				name="description"
				initialValue={initialValues?.description}
			/>
		</>
	);
}

export default EquipmentListForm;
