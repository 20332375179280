import { Global } from '@emotion/react';
import React, { ReactNode } from 'react';

import { globalStyles, SContainer } from './Layout.styled';
import NavMenu from '../NavMenu';

type LayoutProps = {
	children: ReactNode;
	menu?: ReactNode;
	importMenu?: ReactNode;
	submenu?: ReactNode;
};

const Layout = ({ children, menu, importMenu, submenu }: LayoutProps): JSX.Element => {
	return (
		<>
			<Global styles={globalStyles} />
			<NavMenu menu={menu} importMenu={importMenu} submenu={submenu} />
			<SContainer>{children}</SContainer>
		</>
	);
};

export default Layout;
