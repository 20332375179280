import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Form} from 'react-final-form';

import {Column as ColumnType, SelectOptionKey, Filter} from "../../@types/vehicleFilters";
import {translationsValues, backwardsTranslationValues} from "../../constants/translations";
import {selectedOptionsByType, selectOptions, selectOptionsTypes, TYPE, VALUE} from "../../constants/vehicleFilters";
import { useClipboard } from "../../hooks/useClipboard";
import DatePickerField from "../form/fields/DatePickerField";
import SelectField from "../form/fields/SelectField";
import TextField from "../form/fields/TextField";
import {required} from "../form/utils/validators";

interface ColumnProps {
    title: string;
    onSubmit: (filters: Filter[]) => void;
    filters?: Filter[] | null;
    onHide: () => void;
    column: ColumnType;
}

export function getTypeForSelectedOption(type: string, key: SelectOptionKey | string | number): string | undefined {
    // @ts-expect-error
    const option = selectedOptionsByType[type][key];
    return option ? option.type : undefined;
}

export function getLabelForSelectedOption(key: SelectOptionKey | string | number): string | undefined {
    // @ts-expect-error
    const option = selectOptions[key];
    return option ? option.label : undefined;
}

export function Column(props: ColumnProps): JSX.Element {
    const initialSelectedTypes = props.filters ? props.filters.map(obj => obj[TYPE]) : [];
    const [clipboardContent, handleClipboardClick] = useClipboard();

    const [filters, setFilters] = useState<Filter[]>(props.filters || [{type: "", value: ""}]);
    const [selectedTypes, setSelectedTypes] = useState<(string | number)[]>(initialSelectedTypes);

    const handleAddFilter = () => {
        setFilters([...filters, {type: "", value: ""}]);
    };

    const handleSelectChange = (index: number, value: string) => {
        const updatedSelectedTypes = [...selectedTypes];
        updatedSelectedTypes[index] = value;
        setSelectedTypes(updatedSelectedTypes);
    };

    const handleUpdateFilter = (index: number, field: keyof Filter, value: string | number) => {
        const updatedFilters = [...filters];
        updatedFilters[index][field] = value;
        setFilters(updatedFilters);
    };

    const handleRemoveFilter = (index: number) => {
        const updatedFilters = [...filters];
        updatedFilters.splice(index, 1);
        setFilters(updatedFilters);

        const updatedSelectedTypes = [...selectedTypes];
        updatedSelectedTypes.splice(index, 1);
        setSelectedTypes(updatedSelectedTypes);
    };

    const type = props.column.hasPredifenedFilter ? 'SELECT' : props.column.type || 'TEXT';

    const translateFilters = (filters: Filter[]): Filter[] => {
        filters.forEach(f => f.value = backwardsTranslationValues(f.value.toString()));
        return filters;
    }

    // @ts-expect-error
    function handleCopy(i, v){
        handleClipboardClick();
        if(clipboardContent){
            const values = clipboardContent.replace(/[\r\n]/gm, '\n').split('\n').filter(Boolean);

            if(props.column.name === 'Vehicles_VIN'){
                const changedValues = values.map(value => value.slice(-7));
                handleUpdateFilter(i, v, changedValues.join(',').toString());
            } else {
                handleUpdateFilter(i, v, values.join(',').toString());
            }
        }
    }

    return (
        <Form
            onSubmit={() => {
                props.onSubmit(props.column.name !== 'Audit_FieldName' ? filters : translateFilters(filters));
                props.onHide();
            }}
        >
            {({handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    {filters.map((filter, index) => (
                        <>
                            <Row key={index}>
                                <Col>
                                    <SelectField
                                        name={`filters[${index}][${TYPE}]`}
                                        placeholder="Typ"
                                        // @ts-expect-error
                                        options={Object.entries(selectedOptionsByType[type]).map(([value, option]) => ({
                                            value,
                                            label: getLabelForSelectedOption(value)
                                        }))}
                                        /*
                                        Use filter only once
                                        options={Object.entries(selectOptions).filter(([value]) => {
                                            const indexOf = selectedTypes.indexOf(value);
                                            return !(indexOf >= 0 && indexOf !== index);
                                        }).map(([value, option]) => ({value, label: option.label}))}
                                         */
                                        validate={required}
                                        onChange={(e) => {
                                            handleUpdateFilter(index, TYPE, e.target.value);
                                            if (getTypeForSelectedOption(type, e.target.value) === selectOptionsTypes.boolean) {
                                                handleUpdateFilter(index, VALUE, 'true');
                                            }
                                            handleSelectChange(index, e.target.value);
                                        }}
                                        initialValue={filters[index][TYPE] || ""}
                                    />
                                </Col>
                                <Col>
                                    {selectedTypes[index] && getTypeForSelectedOption(type, selectedTypes[index]) !== selectOptionsTypes.boolean && (() => {
                                        const selectedType = getTypeForSelectedOption(type, selectedTypes[index]) || "";
                                        const textsOptions = [selectOptionsTypes.text, selectOptionsTypes.textarea, selectOptionsTypes.number];
                                        const selectsOptions = [selectOptionsTypes.select, selectOptionsTypes.boolean];

                                        if (selectedType === selectOptionsTypes.date) {
                                            return (
                                                <DatePickerField
                                                    name={`filters[${index}][${VALUE}]`}
                                                    placeholder="Hodnota"
                                                    validate={required}
                                                    onCalendarClose={() => {
                                                        handleUpdateFilter(index, VALUE, values.filters[index].value);
                                                    }}
                                                    // @ts-expect-error
                                                    initialValue={filters[index][VALUE] || undefined}
                                                />
                                            );
                                        }

                                        if (selectsOptions.includes(selectedType)) {
                                            return <SelectField
                                                name={`filters[${index}][${VALUE}]`}
                                                placeholder="Vyber"
                                                // @ts-expect-error
                                                options={props.column.options?.map(({name, value}) => ({
                                                    value,
                                                    label: translationsValues(name) ? translationsValues(name) : name
                                                }))}
                                                validate={required}
                                                onChange={(e) =>
                                                    handleUpdateFilter(index, VALUE, e.target.value)
                                                }
                                                initialValue={filters[index][VALUE] || undefined}
                                            />
                                        }

                                        return textsOptions.includes(selectedType) && (
                                            <>
                                                <TextField
                                                    {...(selectedType === "textarea" && {as: "textarea"})}
                                                    type={selectedType}
                                                    name={`filters[${index}][${VALUE}]`}
                                                    placeholder="Hodnota"
                                                    validate={required}
                                                    onChange={(e) =>
                                                        handleUpdateFilter(index, VALUE, e.target.value)
                                                    }
                                                    initialValue={filters[index][VALUE] || undefined}
                                                />
                                                {selectedType === "textarea" && (
                                                    <Button onClick={() => handleCopy(index, VALUE)} variant="light">Vložit obsah schránky</Button>
                                                )}
                                            </>
                                        )
                                    })()}
                                </Col>
                                <Col sm="auto">
                                    <Button variant="danger"
                                            onClick={() => handleRemoveFilter(index)}>Smazat</Button>
                                </Col>
                            </Row>
                            {filters.length - 1 === index && (
                                <Button onClick={handleAddFilter}
                                        disabled={!(selectedTypes.length === filters.length)}>Přidat další
                                    filtr</Button>
                            )}
                        </>
                    ))}

                    {filters.length === 0 && (
                        <Button onClick={handleAddFilter}>Přidat první filtr</Button>
                    )}

                    <div className="mt-4">
                        <Row>
                        <Col/>
                            <Col xs="auto">
                                <Button onClick={props.onHide} variant="secondary" className="me-2">
                                    Zrušit úpravy
                                </Button>
                                <Button type="submit">Uložit</Button>
                            </Col>
                        </Row>
                    </div>
                </form>
            )}
        </Form>
    );
}