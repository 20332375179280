import {
	BMW_DETAIL,
	BMW_LIST,
	LISTS,
	MOTO_LIST,
	OTHER_LIST,
	ROOT,
	BMW_BONUSES,
	BMW_SPECIFICATION,
	BMW_AUDITS,
	BMW_LOANS,
	BMW_CREATE,
	BMW_DOCUMENTS,
	BMW_INTERS,
	EXTENRAL_STORAGE,
	ABOUT,
	SETTINGS,
	USER_MANAGEMENT,
	MOTO_CREATE,
	MOTO_DETAIL,
	MOTO_BONUSES,
	MOTO_SPECIFICATION,
	MOTO_AUDITS,
	MOTO_DOCUMENTS,
	MOTO_INTERS,
	MOTO_LOANS,
	OTHER_DOCUMENTS,
	OTHER_INTERS,
	OTHER_LOANS,
	OTHER_AUDITS,
	OTHER_SPECIFICATION,
	OTHER_BONUSES,
	OTHER_DETAIL,
	OTHER_CREATE,
	IMPORT_AUDIT
} from './constants/routes';
import AboutPage from './screens/AboutPage';
import BMWDetail from './screens/BMWDetail';
import Audits from './screens/BMWDetail/views/Audits';
import Bonuses from './screens/BMWDetail/views/Bonuses';
import Documents from './screens/BMWDetail/views/Documents';
import Inters from './screens/BMWDetail/views/Inters';
import Loans from './screens/BMWDetail/views/Loans';
import Specification from './screens/BMWDetail/views/Specification';
import BMWList from './screens/BMWList';
import ExternalStorageList from './screens/ExternalStorage';
import ImportAudit from './screens/ImportAudit';
import Lists from './screens/Lists';
import MotoList from './screens/MotoList';
import OtherList from './screens/OtherList';
import SettingPage from './screens/SettingPage';
import UserManagement from './screens/UserManagement';


const AppRoutes = [
	{
		path: ROOT,
		requireAuth: false,
		element: <BMWList />,
	},
	{
		path: BMW_LIST,
		requireAuth: false,
		element: <BMWList />,
	},
	{
		path: `${BMW_DETAIL}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${BMW_CREATE}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${BMW_BONUSES}`,
		requireAuth: false,
		element: <Bonuses />,
	},
	{
		path: `${BMW_SPECIFICATION}`,
		requireAuth: false,
		element: <Specification />,
	},
	{
		path: `${BMW_AUDITS}`,
		requireAuth: false,
		element: <Audits />,
	},
	{
		path: `${BMW_LOANS}`,
		requireAuth: false,
		element: <Loans />,
	},
	{
		path: `${BMW_INTERS}`,
		requireAuth: false,
		element: <Inters />,
	},
	{
		path: `${BMW_DOCUMENTS}`,
		requireAuth: false,
		element: <Documents />,
	},
	{
		path: MOTO_LIST,
		requireAuth: false,
		element: <MotoList />,
	},
	{
		path: `${MOTO_CREATE}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${MOTO_DETAIL}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${MOTO_BONUSES}`,
		requireAuth: false,
		element: <Bonuses />,
	},
	{
		path: `${MOTO_SPECIFICATION}`,
		requireAuth: false,
		element: <Specification />,
	},
	{
		path: `${MOTO_AUDITS}`,
		requireAuth: false,
		element: <Audits />,
	},
	{
		path: `${MOTO_LOANS}`,
		requireAuth: false,
		element: <Loans />,
	},
	{
		path: `${MOTO_INTERS}`,
		requireAuth: false,
		element: <Inters />,
	},
	{
		path: `${MOTO_DOCUMENTS}`,
		requireAuth: false,
		element: <Documents />,
	},
	{
		path: OTHER_LIST,
		requireAuth: false,
		element: <OtherList />,
	},
	{
		path: `${OTHER_CREATE}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${OTHER_DETAIL}`,
		requireAuth: false,
		element: <BMWDetail />,
	},
	{
		path: `${OTHER_BONUSES}`,
		requireAuth: false,
		element: <Bonuses />,
	},
	{
		path: `${OTHER_SPECIFICATION}`,
		requireAuth: false,
		element: <Specification />,
	},
	{
		path: `${OTHER_AUDITS}`,
		requireAuth: false,
		element: <Audits />,
	},
	{
		path: `${OTHER_LOANS}`,
		requireAuth: false,
		element: <Loans />,
	},
	{
		path: `${OTHER_INTERS}`,
		requireAuth: false,
		element: <Inters />,
	},
	{
		path: `${OTHER_DOCUMENTS}`,
		requireAuth: false,
		element: <Documents />,
	},
	{
		path: LISTS,
		requireAuth: false,
		element: <Lists />,
	},
	{
		path: EXTENRAL_STORAGE,
		requireAuth: false,
		element: <ExternalStorageList />,
	},
	{
		path: ABOUT,
		requireAuth: false,
		element: <AboutPage />,
	},
	{
		path: SETTINGS,
		requireAuth: false,
		element: <SettingPage />,
	},
	{
		path: USER_MANAGEMENT,
		requireAuth: false,
		element: <UserManagement />,
	},
	{
		path: IMPORT_AUDIT,
		requireAuth: false,
		element: <ImportAudit />,
	},
];

export default AppRoutes;
