import { useQuery } from '@tanstack/react-query';

import { STRUCTURE_DATA} from '../constants/queryKeys';
import { apiFetcher } from '../utils/fetcher';

const useStructureQuery = (structureName: string) => {
	const query = useQuery<any, Error>({
		queryKey: [STRUCTURE_DATA, { structureName }],
		queryFn: async (): Promise<any> => {
			const res = await apiFetcher(`structure/uniqueName/${structureName}`);

			return res.json as any;
		},
		refetchOnWindowFocus: false,
	});
	return query;
};

export default useStructureQuery;
