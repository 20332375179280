import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import useAudits from './useAudits';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { AUDIT } from '../../../constants/detailViews';
import { mapFieldToName } from '../../../utils/audit';
import Navigation from '../Navigation';

function Audits(): JSX.Element {
	const { id } = useParams();
	const { isLoading, audits } = useAudits({
		id,
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 20% 20% 20% 20% 20%;`,
		},
	]);
	const data = { nodes: audits };
	return (
		<Layout>
			<Navigation activeView={AUDIT} id={id as string} />
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Pole</HeaderCell>
										<HeaderCell>Původní hodnota</HeaderCell>
										<HeaderCell>Nová hodnota</HeaderCell>
										<HeaderCell>Změněno</HeaderCell>
										<HeaderCell>Změnil</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item) => (
										<TableRow key={item.id} item={item}>
											<Cell>{mapFieldToName(item.fieldName) ?? item.fieldName}</Cell>
											<Cell>{item.oldValue}</Cell>
											<Cell>{item.newValue}</Cell>
											<Cell>
												{item.modifiedAt ? format(new Date(item.modifiedAt), 'dd.MM.yyyy') : ''}
											</Cell>
											<Cell>{item.modifiedBy}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</>
			)}
		</Layout>
	);
}
export default Audits;
