import React from 'react';
import { SketchPicker } from 'react-color';

import { RowColorListItem } from '../../@types/lists';
import TextField from '../../components/form/fields/TextField';

function RowColorsListForm({
	initialValues,
	setValue,
}: {
	initialValues?: RowColorListItem | null;
	setValue: (name: string, value: string) => void;
}): JSX.Element {
	const [color, setColor] = React.useState<string>(initialValues?.colorHex || '#fff');
	return (
		<>
			{/* TODO: add color picker */}
			<SketchPicker
				color={color}
				onChange={(c) => {
					setColor(c.hex);
					setValue('colorHex', c.hex);
				}}
			/>
			<br />
			<TextField label="Popis" name="description" initialValue={initialValues?.description} />
		</>
	);
}

export default RowColorsListForm;
