import styled from '@emotion/styled';

export const SItem = styled.span`
	display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SItemTitleWrapper = styled.span`
	display: flex;
    align-items: center;
    justify-content: start;
`;

export const SItemTitle = styled.span<{isActive?: boolean}>`
	margin-left: 1rem;
    font-weight: ${({isActive}) => isActive ? 600 : 400 };
    font-size: 14px;
`;

export const SIconEye = styled.span<{isActive?: boolean}>`
	position: relative;
    top: -2px;
`;

