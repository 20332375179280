import React from 'react';

import { BankListItem } from '../../@types/lists';
import TextField from '../../components/form/fields/TextField';

function BanksListForm({ initialValues }: { initialValues?: BankListItem | null }): JSX.Element {
	return (
		<>
			<TextField label="Název" name="name" initialValue={initialValues?.name} />
			<TextField label="Zkratka" name="abbrevation" initialValue={initialValues?.abbrevation} />
		</>
	);
}

export default BanksListForm;
