import React from 'react';
import { Field } from 'react-final-form';

import { VehicleReward } from '../../../../@types/vehicleReward';
import CheckField from '../CheckField';
import CurrencyField from '../CurrencyField';
import SelectField from '../SelectField';
import TextField from '../TextField';

function RewardField({
	initialValues,
	rewards,
	name,
}: {
	initialValues?: VehicleReward | undefined;
	rewards: Array<{ value: string; label: string }>;
	name: string;
}): JSX.Element {
	return (
		<div className="row align-items-end">
			<TextField type="hidden" name={`${name}.id`} initialValue={initialValues?.id} />
			<div className="col-12 col-md-3">
				<CurrencyField
					label="Částka"
					name={`${name}.amount`}
					initialValue={initialValues?.amount}
					shouldParseValue
				/>
			</div>
			<div className="col-12 col-md-3">
				<SelectField
					label="Popis"
					name={`${name}.codeId`}
					options={rewards}
					placeholder="Vyberte odměnu"
					initialValue={initialValues?.codeId}
					shouldParseValue
				/>
			</div>
			<div className="col-12 col-md-3">
				<CheckField
					label="Aktivní"
					name={`${name}.isActive`}
					initialValue={initialValues?.isActive}
					type="checkbox"
					shouldParseValue
				/>
			</div>
		</div>
	);
}

export default RewardField;
