// @ts-nocheck
import { addMonths, format } from 'date-fns';
import { createForm } from 'final-form';
import { noop } from 'lodash';
import { AlertCircle, Search } from 'lucide-react';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';

import { SFlex } from './index.styled';
import ModalDataModel from './ModalDataModel';
import useDetail from './useDetail';
import useDetailMutation from './useDetailMutation';
import SaveButton from '../../../components/buttons/SaveButton';
import CheckField from '../../../components/form/fields/CheckField';
import CurrencyField from '../../../components/form/fields/CurrencyField';
import DatePickerField from '../../../components/form/fields/DatePickerField';
import NumberField from '../../../components/form/fields/NumberField';
import SelectField from '../../../components/form/fields/SelectField';
import TextField from '../../../components/form/fields/TextField';
import { required } from '../../../components/form/utils/validators';
import Loader from '../../../components/Loader';
import Section from '../../../components/Section';
import { usePrompt } from '../../../hooks/useBlocker';
import useVehicleModel from '../../../hooks/useVehicleModel';
import { getInitialDate } from '../../../utils/date';
import useType from '../useType';
import { calculateBonus, numberToPrice, priceToNumber } from '../utils';

function Detail(): JSX.Element {
	const { name: typeName, } = useType();
	const { id } = useParams();
	const navigate = useNavigate();
	const {
		isLoading,
		banks,
		brands,
		marketing,
		webSections,
		locations,
		dealers,
		offers,
		offersTwo,
		leasingCompanies,
		insuranceCompanies,
		salesPersons,
		warehouses,
		invoiceNumbers,
		invoiceNumbersData,
		vehicleDetail,
		vehicleDetailRefetch,
	} = useDetail({ id });
	const { mutation, errorMessage, successMessage } = useDetailMutation({
		id,
		//@ts-ignore
		onSuccess:
			id === null || id === undefined
				? (response) => {
						form.reset();
						navigate(`/${typeName}/${response.json}/detail`);
				  }
				: () => {
						vehicleDetailRefetch().then((data) => {
							form.reset({ ...data.data });
						});
				  },
	});
	const [form] = useState(() =>
		createForm({
			onSubmit: (values, form) => {
				const dirtyFields = form.getState().dirtyFields;
				const {
					vehicleModels_BrandId,
					vehicleModels_Model,
					vehicleModels_Code,
					vehicleModels_Series,
					vehicleModels_Body,
					vehicleModels_ModelGroup,
					...changedFields
				} = dirtyFields;
				// @ts-ignore
				return mutation.mutate({
					formData: values,
					changedFields,
				});
			},
		})
	);
	const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
	const [bonus, setBonus] = useState<string | undefined>();
	const [modalDataModelOpen, setModalDataModelOpen] = useState<boolean>(false);
	const [modelRequestParams, setModelRequestParams] = useState<{
		model: string | undefined;
		brandId: string | undefined;
	}>({ model: undefined, brandId: undefined });

	const vehicleModelQuery = useVehicleModel({
		...modelRequestParams,
		onSuccess: () => {
			setModalDataModelOpen(true);
		},
		onError: () => {
			setModalDataModelOpen(true);
		},
	});

	usePrompt('Opravdu chcete opustit formulář?', isFormDirty && !mutation.isLoading);
	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Form form={form} onSubmit={noop}>
						{({ form: { change }, dirty, errors, handleSubmit, hasValidationErrors, values }) => {
							const updateBonus = () => {
								const newBonus = calculateBonus({
									bonus: values['vehicleSales_SalesBonus'],
									transportPrice: values['vehicleTransports_Price'],
									realPrice: values['realPrice'],
								});
								setBonus(newBonus);
							};
							const updateRealPrice = () => {
								const newPrice =
									values['vehiclePurchases_PriceIncludingTax'] && values['vehiclePurchases_TaxRate']
										? numberToPrice(
												Math.round(
													priceToNumber(values['vehiclePurchases_PriceIncludingTax']) /
														(1 + Number(values['vehiclePurchases_TaxRate']) / 100)
												)
										  )
										: undefined;
								change('realPrice', newPrice);
								updateBonus();
							};
							const updateRegistrationDate = (): void => {
								if (
									values['vehicles_MandatoryRegistrationAmountOfMonts'] &&
									(values['vehicles_CommissioningDate2At'] ||
										values['vehicles_CommissioningDateAt'])
								) {
									const registrationDate = format(
										addMonths(
											new Date(
												values['vehicles_CommissioningDate2At'] ||
													values['vehicles_CommissioningDateAt']
											),
											values['vehicles_MandatoryRegistrationAmountOfMonts']
										),
										'dd.MM.yyyy'
									);
									change('vehicles_EndOfMandatoryRegistrationAt', registrationDate);
								}
							};
							const firstSectionHasErrors =
								errors?.vehicleModels_Model || errors?.vehicleLocations_WarehouseId;
							if (!isFormDirty && dirty) {
								setIsFormDirty(true);
							} else if (isFormDirty && !dirty) {
								setIsFormDirty(false);
							}
							return (
								<>
									{modalDataModelOpen && (
										<ModalDataModel
											modelData={vehicleModelQuery.data}
											selectedModelId={values['vehicleModels_Id']}
											onSelect={(model) => {
												change('vehicleModels_Model', model?.model);
												change('vehicleModels_Code', model?.code);
												change('vehicleModels_Series', model?.series);
												change('vehicleModels_Body', model?.body);
												change('vehicleModels_ModelGroup', model?.modelGroup);
												change('vehicleModels_Id', model?.id);
												setModalDataModelOpen(false);
											}}
											onClose={() => setModalDataModelOpen(false)}
										/>
									)}
									<form onSubmit={handleSubmit}>
										<Row>
											<Col>
												<Section>
													<Accordion alwaysOpen defaultActiveKey={['0']}>
														<Accordion.Item eventKey="0">
															<Accordion.Header
																{...(firstSectionHasErrors && { className: 'error' })}
															>
																Obecné
																{firstSectionHasErrors && (
																	<span className="ps-2">
																		<AlertCircle color="#dc3545" />
																	</span>
																)}
															</Accordion.Header>
															<Accordion.Body>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Značka"
																			name="vehicleModels_BrandId"
																			// TODO: Options prijdou z API (initial config request)
																			// Musime vyfiltrovat brandy pro konkretni detail dle UseInBmw / UseInMoto, atd. fieldu
																			options={brands}
																			placeholder="Vyberte brand"
																			initialValue={vehicleDetail?.vehicleModels_BrandId}
																			validate={required}
																			allowEmpty={false}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SFlex justitfy="start">
																			<div className="col-12 col-md-10">
																				<TextField
																					label="Model"
																					name="vehicleModels_Model"
																					validate={required}
																					disabled={
																						!values['vehicleModels_BrandId'] ||
																						vehicleModelQuery.isFetching
																					}
																					initialValue={vehicleDetail?.vehicleModels_Model}
																					shouldCopyOnDoubleClick
																					/>
																			</div>
																			<div className="mb-3 ms-2" style={{ marginTop: '26px' }}>
																				<button
																					type="button"
																					className="btn btn-info"
																					onClick={() =>
																						setModelRequestParams({
																							model: values['vehicleModels_Model'],
																							brandId: values['vehicleModels_BrandId'],
																						})
																					}
																					disabled={vehicleModelQuery.isFetching}
																				>
																					<Search color="white" size={20} />
																				</button>
																			</div>
																		</SFlex>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Položka"
																			name="vehicles_UniqueId"
																			initialValue={vehicleDetail?.vehicles_UniqueId}
																			shouldCopyOnDoubleClick
																			disabled
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Stav"
																			name="vehicles_State"
																			disabled
																			options={[
																				{ label: 'Ve výrobě', value: 'InProduction' },
																				{ label: 'Na skladě', value: 'OnStock' },
																				{ label: 'Prodáno', value: 'Sold' },
																				{ label: 'Archivované', value: 'Archived' },
																				{ label: 'Stornované', value: 'Storno' },
																			]}
																			initialValue={vehicleDetail?.vehicles_State || 'InProduction'}
																			allowEmpty={false}
																		/>
																	</div>
																	<TextField
																		name="vehicleModels_Id"
																		initialValue={vehicleDetail?.vehicleModels_Id}
																		type="hidden"
																		validate={required}
																		hideErrorMessage
																	/>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Systém"
																			name="vehicles_Identification"
																			options={[
																				{ label: 'Nový', value: 'New' },
																				{ label: 'Výkup', value: 'Buyout' },
																				{ label: 'Komise', value: 'Commission' },
																			]}
																			placeholder="Vyberte systém"
																			initialValue={vehicleDetail?.vehicles_Identification}
																			allowEmpty={false}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Kód"
																			name="vehicleModels_Code"
																			disabled
																			initialValue={vehicleDetail?.vehicleModels_Code}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Order Nr"
																			name="vehiclePurchases_OrderNumber"
																			initialValue={vehicleDetail?.vehiclePurchases_OrderNumber}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Sklad"
																			name="vehicleLocations_WarehouseId"
																			options={warehouses}
																			placeholder="Vyberte sklad"
																			validate={required}
																			initialValue={vehicleDetail?.vehicleLocations_WarehouseId}
																			allowEmpty={false}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Status"
																			name="vehicles_Status"
																			options={[
																				{ label: 'Demo', value: 'Demo' },
																				{ label: 'Stock', value: 'Stock' },
																				{ label: 'Contract', value: 'Contract' },
																				{ label: 'Not Available', value: 'NotAvailable' },
																			]}
																			placeholder="Vyberte status"
																			initialValue={vehicleDetail?.vehicles_Status}
																			allowEmpty={false}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Body"
																			name="vehicleModels_Body"
																			disabled
																			initialValue={vehicleDetail?.vehicleModels_Body}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="VIN"
																			name="vehicles_VIN"
																			initialValue={vehicleDetail?.vehicles_VIN}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="HOM"
																			name="calculated_CurrentWarehouseName"
																			disabled
																			initialValue={vehicleDetail?.calculated_CurrentWarehouseName}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleProductions_PlannedManufactureDate"
																			label="Plánované datum výroby"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleProductions_PlannedManufactureDate
																			)}
																			showDays={false}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Series"
																			name="vehicleModels_Series"
																			disabled
																			initialValue={vehicleDetail?.vehicleModels_Series}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Chassis Nr"
																			name="vehicles_ChassisNumber"
																			initialValue={vehicleDetail?.vehicles_ChassisNumber}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<NumberField
																			label="Tachometr"
																			name="vehicles_Milage"
																			minNumber={0}
																			initialValue={vehicleDetail?.vehicles_Milage}
																		/>
																	</div>
																</div>
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="1">
															<Accordion.Header>Prodej</Accordion.Header>
															<Accordion.Body>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Ceník. cena + PDI"
																			name="vehiclePrices_ListPriceWithPDI"
																			initialValue={vehicleDetail?.vehiclePrices_ListPriceWithPDI}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Akční cena"
																			name="vehiclePrices_ActionPrice"
																			initialValue={vehicleDetail?.vehiclePrices_ActionPrice}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Odměna prodejci"
																			name="calculated_SalesPersonReward"
																			initialValue={vehicleDetail?.calculated_SalesPersonReward}
																			disabled
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Výnos"
																			name="calculated_Earnings"
																			initialValue={vehicleDetail?.calculated_Earnings}
																			disabled
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Prodejce"
																			name="vehicleSales_SalesPersonId"
																			placeholder="Vyberte ze seznamu"
																			options={salesPersons}
																			initialValue={vehicleDetail?.vehicleSales_SalesPersonId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SFlex justify="start">
																			<div className="col-12 col-md-10">
																				<TextField
																					label="Zákazník BMW"
																					name="vehicleSales_CustomerForBmw"
																					initialValue={vehicleDetail?.vehicleSales_CustomerForBmw}
																					shouldCopyOnDoubleClick
																				/>
																			</div>
																			<div className="col-12 col-md-1">
																				<button
																					onClick={() =>
																						change(
																							'vehicleSales_Customer2',
																							values['vehicleSales_CustomerForBmw']
																						)
																					}
																					type="button"
																					className="btn btn-outline-info mb-3 ms-2"
																				>
																					=
																				</button>
																			</div>
																		</SFlex>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Zákazník Invelt"
																			name="vehicleSales_Customer2"
																			initialValue={vehicleDetail?.vehicleSales_Customer2}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Leasingová společnost"
																			name="vehicleSales_LeasingCompanyId"
																			placeholder="Vyberte společnost"
																			options={leasingCompanies}
																			initialValue={vehicleDetail?.vehicleSales_LeasingCompanyId}
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleDispositions_CustomerOrderCheckedAt"
																			label="Datum obj. DISPO"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleDispositions_CustomerOrderCheckedAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Interní obj. DISPO"
																			name="vehicleDispositions_IsCustomerOrder"
																			type="checkbox"
																			onChange={(checked) => {
																				change(
																					'vehicleDispositions_CustomerOrderCheckedAt',
																					checked ? format(new Date(), 'yyyy-MM-dd') : undefined
																				);
																			}}
																			initialValue={
																				vehicleDetail?.vehicleDispositions_IsCustomerOrder
																			}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="technicalCertificates_RequestedAt"
																			label="Datum předání TP prodejci"
																			placeholder={
																				values['technicalCertificates_RequestedAt'] &&
																					values['technicalCertificates_RequestedAt'] !== null
																					? format(
																						new Date(values['technicalCertificates_RequestedAt']),
																						'dd.MM.yyyy'
																					)
																					: values['technicalCertificates_RequestedAt'] === null
																						? undefined
																						: vehicleDetail?.technicalCertificates_RequestedAt
																							? format(
																								new Date(
																									vehicleDetail.technicalCertificates_RequestedAt
																								),
																								'dd.MM.yyyy'
																							)
																							: undefined
																			}
																			disabled
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="TP předán prodejci"
																			name="technicalCertificates_WasRequested"
																			type="checkbox"
																			onChange={(checked) => {
																				change(
																					'technicalCertificates_RequestedAt',
																					checked ? format(new Date(), 'yyyy-MM-dd') : null
																				);
																			}}
																			initialValue={
																				vehicleDetail?.technicalCertificates_WasRequested
																			}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleSales_ContractSignedAt"
																			label="Datum podpisu smlouvy"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleSales_ContractSignedAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Smluvní cena s DPH"
																			name="vehiclePrices_ContractPriceWithTax"
																			initialValue={
																				vehicleDetail?.vehiclePrices_ContractPriceWithTax
																			}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Záloha"
																			name="vehicleDeposit_Deposit"
																			initialValue={vehicleDetail?.vehicleDeposit_Deposit}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Číslo prodejní faktury"
																			name="vehicleSales_InvoiceNumber"
																			placeholder="Vyberte č. faktury"
																			options={invoiceNumbers}
																			initialValue={`${vehicleDetail?.vehicleSales_InvoiceNumber}`}
																			onChange={(selectElement) => {
																				const customerLedgerEntry = invoiceNumbersData?.find(cle => cle.vehicleSales_InvoiceNumber === selectElement.target.value)
																				change(
																					'vehicleSales_SoldAt',
																					customerLedgerEntry?.vehicleSales_SoldAt
																				);
																				change(
																					'vehiclePrices_RetailPriceWithTax',
																					customerLedgerEntry?.amount
																				);
																			}}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			label="Datum retail AG"
																			name="vehicleSales_InternalForBwmSoldAt"
																			initialValue={
																				vehicleDetail?.vehicleSales_InternalForBwmSoldAt
																			}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleSales_SoldAt"
																			label="Datum předání"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleSales_SoldAt
																			)}
																			showFutureDateAlert
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Prodejní cena s DPH"
																			name="vehiclePrices_RetailPriceWithTax"
																			initialValue={vehicleDetail?.vehiclePrices_RetailPriceWithTax}
																			disabled
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<NumberField
																			label="DPH (%)"
																			name="vehiclePrices_Tax"
																			initialValue={!!vehicleDetail?.vehiclePrices_Tax ? vehicleDetail.vehiclePrices_Tax : 21}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Ostatní náklady"
																			name="vehicles_OtherCosts"
																			initialValue={vehicleDetail?.vehicles_OtherCosts}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Úrokové náklady"
																			name="vehicleInterests_Costs"
																			initialValue={vehicleDetail?.vehicleInterests_Costs}
																			disabled
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			label="Konec povinné registrace"
																			name="vehicles_EndOfMandatoryRegistrationAt"
																			placeholder="Vyberte datum"
																			disabled
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicles_EndOfMandatoryRegistrationAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleSales_EstimatedHandoverAt"
																			label="Odhad předání"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleSales_EstimatedHandoverAt
																			)}
																		/>
																	</div>
																</div>
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="2">
															<Accordion.Header>Dispozice</Accordion.Header>
															<Accordion.Body>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleProductions_ManufactureDate"
																			label="Datum výroby"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleProductions_ManufactureDate
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Dealer AG"
																			name="vehicleSales_DealerId"
																			options={dealers}
																			placeholder="Vyberte dealera"
																			initialValue={vehicleDetail?.vehicleSales_DealerId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="PT"
																			name="vehicles_ProcessingType"
																			initialValue={vehicleDetail?.vehicles_ProcessingType}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="DSC"
																			name="vehicles_DealerSearchingCode"
																			disabled
																			initialValue={vehicleDetail?.vehicles_DealerSearchingCode}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Nabídka"
																			name="vehicles_OfferId"
																			placeholder="Vyberte nabídku"
																			options={offers}
																			initialValue={vehicleDetail?.vehicles_OfferId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Nabídka 2"
																			name="vehicles_OfferTwoId"
																			placeholder="Vyberte nabídku"
																			options={offersTwo}
																			initialValue={vehicleDetail?.vehicles_OfferTwoId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Model Group"
																			name="vehicleModels_ModelGroup"
																			disabled
																			initialValue={vehicleDetail?.vehicleModels_ModelGroup}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicles_CommissioningDateAt"
																			label="Datum uvedení do provozu I"
																			placeholder="Vyberte datum"
																			onCalendarClose={updateRegistrationDate}
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicles_CommissioningDateAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicles_CommissioningDate2At"
																			label="Datum uvedení do provozu II"
																			placeholder="Vyberte datum"
																			onCalendarClose={updateRegistrationDate}
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicles_CommissioningDate2At
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="SPZ"
																			name="vehicles_LicencePlate"
																			initialValue={vehicleDetail?.vehicles_LicencePlate}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<NumberField
																			// TODO: Konec povinne registrace - pridat disabled field - spocita se na FE na zaklade mesicu povinne registrace + datum uvedeni do provozu 2 nebo pokud neni, tak + datum uvedeni do provozu 1
																			label="Povinná registrace"
																			name="vehicles_MandatoryRegistrationAmountOfMonts"
																			onBlur={updateRegistrationDate}
																			initialValue={
																				vehicleDetail?.vehicles_MandatoryRegistrationAmountOfMonts
																			}
																			disabled={!values.vehicles_CommissioningDateAt}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Fakturace z AG"
																			name="vehiclePurchases_BwmInvoiceNumber"
																			initialValue={
																				vehicleDetail?.vehiclePurchases_BwmInvoiceNumber
																			}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleSales_InvoiceCreatedAt"
																			label="Datum vystavení faktury"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleSales_InvoiceCreatedAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Nákupní cena s DPH"
																			name="vehiclePurchases_PriceIncludingTax"
																			onBlur={updateRealPrice}
																			initialValue={
																				vehicleDetail?.vehiclePurchases_PriceIncludingTax
																			}
																		/>
																	</div>
																	<div className="col-12 col-md-1">
																		<NumberField
																			label="DPH (%)"
																			name="vehiclePurchases_TaxRate"
																			onBlur={updateRealPrice}
																			initialValue={!!vehicleDetail?.vehiclePurchases_TaxRate ? vehicleDetail?.vehiclePurchases_TaxRate : 21}
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Doprava"
																			name="vehicleTransports_Price"
																			onBlur={updateBonus}
																			initialValue={vehicleDetail?.vehicleTransports_Price}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Seznam prodejců"
																			name="vehicleSales_WarehouseSalesPersonId"
																			placeholder="Vyberte ze seznamu"
																			options={salesPersons}
																			disabled={!values['vehicleSales_IsWarehouseOrder']}
																			initialValue={
																				vehicleDetail?.vehicleSales_WarehouseSalesPersonId
																			}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Skladová objednávka"
																			name="vehicleSales_IsWarehouseOrder"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicleSales_IsWarehouseOrder}
																			onChange={(checked) => {
																				if (!checked) {
																					change('vehicleSales_WarehouseSalesPersonId', undefined);
																				}
																			}}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			name="vehicleSales_OfferFromSA3"
																			label="Nabídka SA3"
																			initialValue={vehicleDetail?.vehicleSales_OfferFromSA3}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label="Alpina VZ"
																			name="vehicleDeposit_AlpinaDeposit"
																			initialValue={vehicleDetail?.vehicleDeposit_AlpinaDeposit}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleDeposit_AlpinaDepositAt"
																			label="Datum alpina VZ"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleDeposit_AlpinaDepositAt
																			)}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="El. bonus"
																			name="vehicles_IsElectricBonusRelevant"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicles_IsElectricBonusRelevant}
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleTransports_ArrivedAt"
																			label="Příjezd kamionu"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleTransports_ArrivedAt
																			)}
																			showFutureDateAlert
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CurrencyField
																			label={`Předplatba bonusu ${bonus ? `(${bonus}%)` : ''}`}
																			name="vehicleSales_SalesBonus"
																			onBlur={updateBonus}
																			initialValue={vehicleDetail?.vehicleSales_SalesBonus}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="BMW M"
																			name="vehicles_IsBmwM"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicles_IsBmwM}
																		/>
																		<CheckField
																			label="GKL Luxury"
																			name="vehicles_IsLuxuryBonusRelevant"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicles_IsLuxuryBonusRelevant}
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Pojišťovna"
																			name="vehicleInsurances_CompanyId"
																			placeholder="Vyberte pojišťovnu"
																			options={insuranceCompanies}
																			initialValue={vehicleDetail?.vehicleInsurances_CompanyId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleInsurances_Since"
																			label="Pojištěno od"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleInsurances_Since
																			)}
																			disabled={!values.vehicleInsurances_CompanyId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleInsurances_To"
																			label="Pojištěno do"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleInsurances_To
																			)}
																			disabled={!values.vehicleInsurances_CompanyId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="POV"
																			name="vehicleInsurances_HasMandatory"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicleInsurances_HasMandatory}
																		/>
																		<CheckField
																			label="HAV"
																			name="vehicleInsurances_HasAccident"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicleInsurances_HasAccident}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Placeno dodavateli"
																			name="vehiclePurchases_IsPaidBySupplier"
																			type="checkbox"
																			initialValue={
																				vehicleDetail?.vehiclePurchases_IsPaidBySupplier
																			}
																		/>
																	</div>
																</div>
															</Accordion.Body>
														</Accordion.Item>
														<Accordion.Item eventKey="3">
															<Accordion.Header>Ostatní</Accordion.Header>
															<Accordion.Body>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Transportní poškození"
																			name="vehicleTransports_WasDamaged"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicleTransports_WasDamaged}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Číslo faktury"
																			name="vehicleTransports_DamagedInvoiceNumber"
																			initialValue={
																				vehicleDetail?.vehicleTransports_DamagedInvoiceNumber
																			}
																			shouldCopyOnDoubleClick
																			disabled={!values.vehicleTransports_WasDamaged}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleTransports_DamagedSendToBmwAt"
																			label="Odesláno na LO"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleTransports_DamagedSendToBmwAt
																			)}
																			disabled={!values.vehicleTransports_WasDamaged}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Uhrazeno"
																			name="vehicleTransports_WasDamagedPaid"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicleTransports_WasDamagedPaid}
																			disabled={!values.vehicleTransports_WasDamaged}
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="BPS"
																			name="vehicles_HasBmwPremiumSelection"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicles_HasBmwPremiumSelection}
																		/>
																	</div>
																</div>

																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<TextField
																			name="vehicleSales_ReservationFor"
																			label="Rezervoval"
																			initialValue={vehicleDetail?.vehicleSales_ReservationFor}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<DatePickerField
																			name="vehicleSales_ReservationTill"
																			label="Rezervace do"
																			placeholder="Vyberte datum"
																			initialValue={getInitialDate(
																				vehicleDetail?.vehicleSales_ReservationTill
																			)}
																		/>
																	</div>

																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Financováno"
																			name="vehiclePurchases_IsFinanceByLoan"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehiclePurchases_IsFinanceByLoan}
																		/>
																		<SelectField
																			label="Banka"
																			name="vehiclePurchases_BankId"
																			placeholder="Vyberte banku"
																			options={banks}
																			disabled={!values['vehiclePurchases_IsFinanceByLoan']}
																			initialValue={vehicleDetail?.vehiclePurchases_BankId}
																		/>
																	</div>
																</div>
																<div className="row align-items-end">
																	<div className="col-12 col-md-3">
																		<CheckField
																			label="Je na webu?"
																			name="vehicles_IsOnWeb"
																			type="checkbox"
																			initialValue={vehicleDetail?.vehicles_IsOnWeb}
																		/>
																		<SelectField
																			label="Sekce na Webu"
																			name="vehicles_WebSectionId"
																			placeholder="Vyberte sekci"
																			options={webSections}
																			disabled={!values['vehicles_IsOnWeb']}
																			initialValue={vehicleDetail?.vehicles_WebSectionId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Umístění (kód)"
																			name="vehicleLocations_LocationCodeId"
																			placeholder="Vyberte umístění"
																			options={locations}
																			initialValue={vehicleDetail?.vehicleLocations_LocationCodeId}
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<TextField
																			label="Umístění"
																			name="vehicleLocations_LocationOnSet"
																			initialValue={vehicleDetail?.vehicleLocations_LocationOnSet}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 col-md-3">
																		<TextField
																			name="vehicles_TextAnalysis"
																			label="Rozbor"
																			initialValue={vehicleDetail?.vehicles_TextAnalysis}
																			shouldCopyOnDoubleClick
																		/>
																	</div>
																	<div className="col-12 col-md-3">
																		<SelectField
																			label="Marketing"
																			name="vehicles_MarketingAnalysis"
																			placeholder="Vyberte marketing"
																			options={marketing}
																			initialValue={vehicleDetail?.vehicles_MarketingAnalysis}
																		/>
																	</div>
																</div>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>

													{/* 
								

									<div className="row">
										<div className="col-12 col-md-2">
											<CurrencyField
												label="Skutečná nákupní cena"
												name="realPrice"
												disabled
												initialValue={
													values['vehiclePurchases_PriceIncludingTax'] &&
													values['vehiclePurchases_TaxRate']
														? String(
																Math.round(
																	values['vehiclePurchases_PriceIncludingTax'] /
																		(1 + values['vehiclePurchases_TaxRate'] / 100)
																)
														  )
														: undefined
												}
											/>
										</div>
									</div> */}
												</Section>
											</Col>
											<Col xs={4}>
												{errorMessage}
												{successMessage}
												<SaveButton
													onClick={handleSubmit}
													disabled={mutation.isLoading || !dirty || hasValidationErrors}
													dirtyFields={form.getState().dirtyFields}
												/>
												<TextField
													label="Pozn. DISPO"
													name="vehicleDispositions_Note"
													rows={6}
													as="textarea"
													initialValue={vehicleDetail?.vehicleDispositions_Note}
													shouldCopyOnDoubleClick
												/>
												<TextField
													label="Pozn. PRODEJ"
													name="vehicleSales_SalesNote"
													rows={6}
													as="textarea"
													initialValue={vehicleDetail?.vehicleSales_SalesNote}
													shouldCopyOnDoubleClick
												/>
												{hasValidationErrors && (
													<Alert variant="danger">Některá pole jsou chybně vyplněna.</Alert>
												)}
											</Col>
										</Row>
									</form>
								</>
							);
						}}
					</Form>
				</>
			)}
		</>
	);
}

export default Detail;
