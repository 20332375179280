import { QueryObserverResult } from '@tanstack/react-query';
import React, { useState } from 'react';

import EditModal from './EditModal';
import { ListType, ListItem } from '../../@types/lists';

const useEditModal = ({
	itemType,
	uniqueName,
	refetch,
	variant = 'edit',
}: {
	itemType: ListType;
	uniqueName: string;
	refetch: () => Promise<QueryObserverResult<ListItem, Error>>;
	variant?: 'edit' | 'add';
}) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState<ListItem | null>(null);

	const handleShowEditModal = (item: ListItem | null) => {
		setSelectedItem(item);
		setShowEditModal(true);
	};

	const closeModal = () => {
		setShowEditModal(false);
		setSelectedItem(null);
	};

	return {
		editModal: showEditModal ? (
			// @ts-ignore
			<EditModal
				onUpdate={() => {
					refetch();
					closeModal();
				}}
				onClose={closeModal}
				initialValues={selectedItem}
				uniqueName={uniqueName}
				itemType={itemType}
				variant={variant}
			/>
		) : null,
		handleShowEditModal,
	};
};

export default useEditModal;
