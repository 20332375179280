import { useLocation } from 'react-router-dom';

const useType = () => {
	const location = useLocation();
	const type = location.pathname.includes('moto')
		? { name: 'moto', id: 1 }
		: location.pathname.includes('other')
		? { name: 'other', id: 2 }
		: { name: 'bmw', id: 0 };

	return type;
};

export default useType;
