import { UserPlus } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { AuthUser, UserRole } from "../../@types/authUser";
import authService from "../../components/Authorization/AuthorizationService";
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import UserManagementRow from "../../components/UserManagementRow";
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher } from "../../utils/fetcher";

interface UserManagementProps {

}

function UserManagement(props: UserManagementProps): JSX.Element {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [users, setUsers] = useState<AuthUser[]>();
	const [refresh, setRefresh] = useState<boolean>(false);
	const [roles, setRoles] = useState<UserRole[]>();
	const [currentUserId, setCurrentUserId] = useState<string>();

	useEffect(() => {
		getUsers();
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		getRoles();
		getCurrentUser();
	}, [])

	const getCurrentUser = async () => {
		const user = await authService.getUser();
		setCurrentUserId((user as AuthUser).id);
	};

	const getUsers = async () => {
		const response = await apiFetcher('user', {
			method: HTTPMethod.GET,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (response.status === 200) {
			setUsers(response.json as AuthUser[]);
			setIsLoading(false)
		}
	}

	const getRoles = async () => {
		const response = await apiFetcher('user/roles', {
			method: HTTPMethod.GET,
			headers: {
				'Content-Type': 'application/json',
			},
		})
		if (response.status === 200) {
			setRoles(response.json as UserRole[]);
		}
	}

	const addUser = () => {
		let newUser: AuthUser = {id:"", fullName: "Nový uživatel", userName: "", roles: [] };
		const updatedUsers = [newUser, ...(users || [])];
		setUsers(updatedUsers);
	}

	return isLoading ? (
		<Layout>
			<Loader />
		</Layout>
	) : (
			<Layout>
				<OverlayTrigger
					placement="bottom"
					trigger={["hover", "focus"]}
					overlay={<Tooltip>Přidat uživatele</Tooltip>}
				>
					<Button
						style={{ marginTop: '25px' }}
						variant="info"
						onClick={addUser}
					>
						<UserPlus size={26} />
					</Button>
				</OverlayTrigger>

				<br />
				<br />
				<br />

				{users?.map((user) => {
					return <UserManagementRow key={`user-management-row-${user.id}`} data={user} roles={roles} shouldRefresh={setRefresh} currentUserId={currentUserId || ""} />
				})}

		</Layout>
	);
}

export default UserManagement;
