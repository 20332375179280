import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';
import Error from '../Error';

function NumberField({
	disabled,
	initialValue,
	label,
	maxNumber,
	minNumber,
	name,
	onBlur,
	placeholder,
	validate,
	shouldParseValue
}: FormFieldType & {
	initialValue?: number;
	minNumber?: number;
	maxNumber?: number;
	onBlur?: () => void;
	placeholder?: string;
	shouldParseValue?: boolean;
}): JSX.Element {
	const { Control } = Form;
	return (
		<ControlLayout name={name} label={label}>
			<Field
				name={name}
				type="number"
				initialValue={initialValue}
				validate={validate}
				{...(shouldParseValue && {
					parse: (value) => value ?? null,
				})}
			>
				{({ input, meta }) => (
					<>
						<Control
							autoComplete="off"
							{...input}
							{...(placeholder && { placeholder })}
							onBlur={(e) => {
								input.onBlur?.(e);
								onBlur?.();
							}}
							max={maxNumber}
							min={minNumber}
							id={name}
							disabled={disabled}
							isInvalid={meta.touched && meta.error}
							// onDoubleClick={(e: React.MouseEvent<HTMLInputElement>) => {
							// 	// @ts-ignore
							// 	navigator.clipboard.writeText(e.target.value);
							// }}
						/>
						<Error {...meta} />
					</>
				)}
			</Field>
		</ControlLayout>
	);
}

export default NumberField;
