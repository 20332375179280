export const sizeUnit = 0.25;
export const units = 'rem';

export function size(value: number): number;
export function size(value: number, displayUnits: boolean): string;

export function size(value: number, displayUnits = true): string | number {
	const multipliedValue = value * sizeUnit;

	if (displayUnits) {
		return multipliedValue + units;
	}

	return multipliedValue * 16;
}

export function space(
	x: number | string,
	y: number | string
): { [key: string]: { marginTop: number | string; marginLeft: number | string } } {
	const xVal = typeof x === 'number' ? size(x) : x;
	const yVal = typeof y === 'number' ? size(y) : y;
	return {
		'& > :not(style) ~ *:not(style)': { marginTop: yVal, marginLeft: xVal },
	};
}
