import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { QueryObserverResult } from '@tanstack/react-query';
import format from 'date-fns/format';
import { Check, Minus } from 'lucide-react';
import React from 'react';
import Col from 'react-bootstrap/Col';

import useEditModal from './useEditModal';
import { BasicListItem, ListItem } from '../../@types/lists';
import CreateButton from '../../components/buttons/CreateButton';
import Content from '../../components/Content';

export const renderIcon = (isActive: boolean): JSX.Element => {
	return isActive ? <Check color="green" /> : <Minus color="#D1D1D1" />;
};

function BasicListTable({
	data,
	uniqueName,
	refetch,
}: {
	data: { nodes: Array<BasicListItem> };
	uniqueName: string;
	refetch: () => Promise<QueryObserverResult<BasicListItem, Error>>;
}): JSX.Element {
	const { editModal, handleShowEditModal } = useEditModal({
		itemType: 'basic',
		uniqueName,
		refetch,
	});
	const { editModal: addModal, handleShowEditModal: handleShowModal } = useEditModal({
		itemType: 'basic',
		uniqueName,
		refetch,
		variant: 'add',
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 12% 27% 7% 7% 7% 10% 10% 10% 10%`,
		},
	]);
	return (
		<>
			{editModal}
			{addModal}
			<Col>
				<CreateButton onClick={() => handleShowModal(null)} label="Přidat položku" />
				<Content innerSpacing={[5, 0]}>
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Kód položky</HeaderCell>
										<HeaderCell>Text položky</HeaderCell>
										<HeaderCell className="text-center">BMW</HeaderCell>
										<HeaderCell className="text-center">Moto</HeaderCell>
										<HeaderCell className="text-center">Ostatní</HeaderCell>
										<HeaderCell>Založil</HeaderCell>
										<HeaderCell>Dne</HeaderCell>
										<HeaderCell>Změnil</HeaderCell>
										<HeaderCell>Dne</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item) => (
										<TableRow
											key={item.id}
											item={item}
											onDoubleClick={(item) => handleShowEditModal(item as ListItem)}
											style={{ cursor: 'pointer' }}
										>
											<Cell>{item.configurationCode}</Cell>
											<Cell>{item.text}</Cell>
											<Cell className="text-center">{renderIcon(item.useInBmw)}</Cell>
											<Cell className="text-center">{renderIcon(item.useInMoto)}</Cell>
											<Cell className="text-center">{renderIcon(item.useInOthers)}</Cell>
											<Cell>{item.createdByUser.fullName}</Cell>
											<Cell>
												{item.createdOn ? format(new Date(item.createdOn), 'dd.MM.yyyy') : ''}
											</Cell>
											<Cell>{item.modifiedByUser.fullName}</Cell>
											<Cell>
												{item.modifiedOn ? format(new Date(item.modifiedOn), 'dd.MM.yyyy') : ''}
											</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</Content>
			</Col>
		</>
	);
}

export default BasicListTable;
