import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import useDocuments from './useDocuments';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { DOCUMENTS } from '../../../constants/detailViews';
import { apiFetcher } from '../../../utils/fetcher';
import Navigation from '../Navigation';

interface Document {
	fileName: string;
	fullPath: string;
	note: string;
}

function Documents(): React.JSX.Element {
	const { id } = useParams();
	const [selectedItem, setSelectedItem] = useState<Document | null>(null);

	const { isLoading, documents } = useDocuments({
		id,
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 20% 40% 40%;`,
		},
	]);
	useQuery<any, Error>({
		queryKey: ['download', selectedItem?.fullPath],
		queryFn: async (): Promise<any> => {
			const res = await apiFetcher(`documents/download?fullpath=${selectedItem?.fullPath}`, {
				toBlob: true,
			});

			return res;
		},
		refetchOnWindowFocus: false,
		enabled: !!selectedItem?.fullPath,
		onSuccess: (data: any) => {
			const objectURL = window.URL.createObjectURL(data.blob);
			const a = document.createElement('a');
			a.href = objectURL;
			a.target = '_blank';
			a.dispatchEvent(
				new MouseEvent('click', {
					bubbles: true,
					cancelable: true,
					view: window,
				})
			);
			window.URL.revokeObjectURL(objectURL);
		},
	});
	const data = { nodes: documents };
	const handleDownload = (item: Document) => {
		setSelectedItem(item);
	};
	return (
		<Layout>
			<Navigation activeView={DOCUMENTS} id={id as string} />
			{isLoading ? (
				<Loader />
			) : !documents || documents.length === 0 ? (
				<></>
			) : (
				<>
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Soubor</HeaderCell>
										<HeaderCell>Poznámka</HeaderCell>
										<HeaderCell>Cesta k souboru</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item, id) => (
										<TableRow
											key={`${item.fullPath}-${id}`}
											item={item}
											onDoubleClick={(item) => {
												handleDownload(item);
											}}
											style={{ cursor: 'pointer' }}
										>
											<Cell>{item.fileName}</Cell>
											<Cell>{item.note}</Cell>
											<Cell>{item.fullPath}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</>
			)}
		</Layout>
	);
}

export default Documents;
