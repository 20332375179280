import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { QueryObserverResult } from '@tanstack/react-query';
import React from 'react';
import Col from 'react-bootstrap/Col';

import { renderIcon } from './BasicListTable';
import useEditModal from './useEditModal';
import { ListItem, WarehouseListItem } from '../../@types/lists';
import CreateButton from '../../components/buttons/CreateButton';
import Content from '../../components/Content';

function WarehousesListTable({
	data,
	uniqueName,
	refetch,
}: {
	data: { nodes: Array<WarehouseListItem> };
	uniqueName: string;
	refetch: () => Promise<QueryObserverResult<WarehouseListItem, Error>>;
}): JSX.Element {
	const { editModal, handleShowEditModal } = useEditModal({
		itemType: 'warehouse',
		uniqueName,
		refetch,
	});
	const { editModal: addModal, handleShowEditModal: handleShowModal } = useEditModal({
		itemType: 'warehouse',
		uniqueName,
		refetch,
		variant: 'add',
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 40% 7% 7% 7%`,
		},
	]);
	return (
		<>
			{editModal}
			{addModal}
			<Col>
				<CreateButton onClick={() => handleShowModal(null)} label="Přidat položku" />
				<Content innerSpacing={[5, 0]}>
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Název</HeaderCell>
										<HeaderCell>BMW</HeaderCell>
										<HeaderCell>Moto</HeaderCell>
										<HeaderCell>Ostatní</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item) => (
										<TableRow
											key={item.id}
											item={item}
											onDoubleClick={(item) => handleShowEditModal(item as ListItem)}
											style={{ cursor: 'pointer' }}
										>
											<Cell>{item.name}</Cell>
											<Cell>{renderIcon(item.useInBmw)}</Cell>
											<Cell>{renderIcon(item.useInMoto)}</Cell>
											<Cell>{renderIcon(item.useInOthers)}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</Content>
			</Col>
		</>
	);
}

export default WarehousesListTable;
