import { mapFieldToName, mapNameToField } from '../utils/audit';

export const translationsValues = (field: string): string | undefined => {
    const map = {
        "New": "Nový",
        "Buyout": "Výkup",
        "Commission": "Komise",
        "InProduction": "Ve výrobě",
        "OnStock": "Na skladě",
        "Sold": "Prodáno",
        "Archived": "Archivováno",
        "Storno": "Stornováno"
    }

    // @ts-ignore
    let value = map[field];
    if (!!value) {
        return value;
    }

    return mapFieldToName(field);
}

export const backwardsTranslationValues = (name: string): string => {
    return mapNameToField(name);
}