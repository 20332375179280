import React from 'react';

import { BasicListItem } from '../../@types/lists';
import CheckField from '../../components/form/fields/CheckField';
import TextField from '../../components/form/fields/TextField';

function BasicListForm({
	initialValues,
	variant,
}: {
	initialValues?: BasicListItem | null;
	variant: 'add' | 'edit';
}): JSX.Element {
	return (
		<>
			{variant === 'add' && (
				<TextField
					label="Kód položky"
					name="configurationCode"
					initialValue={initialValues?.configurationCode}
				/>
			)}
			<TextField
				as="textarea"
				label="Text položky"
				name="text"
				initialValue={initialValues?.text}
			/>
			<div className="d-flex gap-3">
				<CheckField
					label="BMW"
					name="useInBmw"
					type="checkbox"
					initialValue={initialValues?.useInBmw}
				/>
				<CheckField
					label="Moto"
					name="useInMoto"
					type="checkbox"
					initialValue={initialValues?.useInMoto}
				/>
				<CheckField
					label="Ostatní"
					name="useInOthers"
					type="checkbox"
					initialValue={initialValues?.useInOthers}
				/>
			</div>
		</>
	);
}

export default BasicListForm;
