import { useEffect, useState } from "react";
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { AuthUser } from "../../@types/authUser";
import authService from "../Authorization/AuthorizationService";

interface AuthDataProps {
}

function AuthData(props: AuthDataProps): JSX.Element {

    const [user, setUser] = useState<AuthUser>();

    useEffect(() => {
        getUserName();
    }, []);

    const getUserName = async () => {
            const user = await authService.getUser();
            setUser(user as AuthUser);
    };

    return (
        <>
            <OverlayTrigger
                trigger="click"
                key='auth-data-key'
                placement='left'
                overlay={
                    <Popover id={`popover-positioned`}>
                        <Popover.Header as="h3">{user?.fullName}</Popover.Header>
                        <Popover.Body>
                            Login: <strong>{user?.userName}</strong>
                            <br />
                            Role: {user?.roles.map(role => role.normalizedName)}
                        </Popover.Body>
                    </Popover>
                }
            >
                <Nav.Link href="#">
                    {user?.fullName}
                </Nav.Link>
            </OverlayTrigger>
        </>
    )

}

export default AuthData;