import { useQuery } from '@tanstack/react-query';

import { VehicleDetail } from '../../../@types/vehicleDetail';
import { apiFetcher } from '../../../utils/fetcher';
import useType from '../useType';

interface Document {
	fileName: string;
	fullPath: string;
	note: string;
}

const useDocuments = ({ id }: { id?: string }) => {
	const { id: typeID } = useType();
	const detailQuery = useQuery<VehicleDetail, Error>({
		queryKey: ['vehicleDetail', { id }],
		queryFn: async (): Promise<VehicleDetail> => {
			const res = await apiFetcher(`vehicles/${id}?type=${typeID}`);

			return res.json as VehicleDetail;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const documentsQuery = useQuery<Document[], Error>({
		queryKey: ['documents', { id }],
		queryFn: async (): Promise<Document[]> => {
			const res = await apiFetcher(
				`documents?vin=${detailQuery.data?.vehicles_VIN}&type=${detailQuery.data?.vehicles_Type}&uniqueId=${detailQuery.data?.vehicles_UniqueId}`
			);

			return res.json as Document[];
		},
		refetchOnWindowFocus: false,
		enabled: !!detailQuery.data,
	});
	return {
		isLoading: documentsQuery.isLoading || detailQuery.isLoading,
		documents: documentsQuery.data,
	};
};

export default useDocuments;
