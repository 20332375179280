import { useMutation } from "@tanstack/react-query";
import { saveAs } from 'file-saver';
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";

import { ListItem } from "../../@types/lists";
import { HTTPMethod } from "../../constants/rest";
import useVehiclesQuery from '../../hooks/useVehiclesQuery';
import excelExport from '../../icons/excelExport.svg';
import { apiFetcher, FetcherError } from "../../utils/fetcher";
import authService from "../Authorization/AuthorizationService";

interface ExportToExcelProps {
    uniqueName: string;
    selectedColumns: any[]
}

function ExportToExcel(props: ExportToExcelProps): JSX.Element {

    const allowedRoles = ["Admin", "Manager"]

    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const {
        getRuleFilter
    } = useVehiclesQuery(props.uniqueName);

    useEffect(() => {
        isAuthorizeForAction();
    }, []);

    const mutation = useMutation<ListItem, FetcherError>({
        // @ts-ignore
        mutationFn: async () => {
            // ts:ignore

            alert("Generování excelu může trvat několik sekund, během níž můžete pokračovat v používání programu.")

            let response = await apiFetcher(`lists/uniqueName/${props.uniqueName}/export?filter=${getRuleFilter()}`, {
                method: HTTPMethod.POST,
                body: JSON.stringify(props.selectedColumns),
                toBlob: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.blob) {
                let url = window.URL.createObjectURL(response.blob);
                saveAs(url, `${props.uniqueName}`);
            }
        },
        onError: () => {
            alert("Při generování excelu vznikla chyba. Prosím opakujte akci. V případě opakovaného problému se obraťte na Administrátora.")
        }
    });

    const isAuthorizeForAction = async () => {
        const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
        setIsButtonVisible(isAuthorize);
    }

    return isButtonVisible ? (
        <>
            <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                overlay={<Tooltip>Export do excelu</Tooltip>}
            >
                <Button variant="light" onClick={() => mutation.mutate()}>
                    <img style={{ height: '1.5em' }} src={excelExport} alt="Export do excelu" />
                </Button>
            </OverlayTrigger>
        </>
    ) : (
    <></>)

}

export default ExportToExcel;