import React, { ReactNode } from 'react';

import { SSection } from './Section.styled';

type SectionProps = {
	children: ReactNode;
	className?: string;
};

const Section: React.FC<SectionProps> = ({ children, className }) => {
	return <SSection className={className}>{children}</SSection>;
};

export default Section;
