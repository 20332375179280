import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';

function CheckField({
	disabled,
	initialValue,
	label,
	name,
	onChange,
	validate,
	type,
	simple,
	shouldParseValue,
}: FormFieldType & {
	initialValue?: boolean;
	onChange?: (checked: boolean) => void;
	type: 'radio' | 'checkbox' | 'switch';
	simple?: boolean;
	shouldParseValue?: boolean;
}): JSX.Element {
	const { Check } = Form;

	return (
		<ControlLayout simple={simple}>
			<label htmlFor={name}>
				<Field
					name={name}
					component="input"
					type={type}
					disabled={disabled}
					initialValue={initialValue}
					validate={validate}
					{...(shouldParseValue && {
						parse: (value) => value ?? null,
					})}
				>
					{({ input, meta }) => (
						<Check
							label={label}
							id={name}
							{...input}
							onChange={(e) => {
								input.onChange(e);
								onChange?.(e.target.checked);
							}}
							{...(meta.touched &&
								meta.error && {
									isInvalid: true,
									feedback: meta.error,
									feedbackType: 'invalid',
								})}
							type={type}
							disabled={disabled}
						/>
					)}
				</Field>
			</label>
		</ControlLayout>
	);
}

export default CheckField;
