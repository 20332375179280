import React from 'react';

import ListTable from "../../components/ListTable";
import {MOTO_ALL} from "../../constants/vehicleFilters";

function MotoList(): JSX.Element {
    return (
        <ListTable type={MOTO_ALL} uniqueName={MOTO_ALL} title="Motorky" config={{ createButtonEnabled: true, deleteButtonEnabled: true, stornoButtonEnabled: true, importEnabled: true, detailEnabled: true }} />
    );
}

export default MotoList;
