import { Save } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import './SaveButton.css';

import authService from "../Authorization/AuthorizationService";

function SaveButton(props: {
	onClick: () => void;
	label?: string;
	disabled?: boolean;
	size?: "lg" | "sm" | undefined;
	dirtyFields?: { [key: string]: boolean };
}): JSX.Element {
	const allowedRoles = ["Admin", "Manager", "Contributor"]
	const [isButtonVisible, setIsButtonVisible] = useState(false);
	const [isSalesmenRole, setIsSalesmanRole] = useState(false);

	useEffect(() => {
		isAuthorizeForAction();
		getIsSalesmanRole()
	}, []);

	useEffect(() => {
		if (isSalesmenRole && !props.disabled && props.dirtyFields) {
			if (Object.keys(props.dirtyFields).length === 1) {
				setIsButtonVisible(props.dirtyFields['vehicleSales_EstimatedHandoverAt']);
			} else {
				setIsButtonVisible(false);
			}
		}
	}, [props.disabled, props.dirtyFields])

	const isAuthorizeForAction = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
		setIsButtonVisible(isAuthorize);
	}
	const getIsSalesmanRole = async () => {
		const user = await authService.getUser();
		if (user && typeof user !== "string") {
			setIsSalesmanRole(user.roles.map(role => role.name).indexOf("Salesman") > -1);
		}
	}

	return isButtonVisible ? (
		<Button
			variant="success"
			type="submit"
			onClick={props.onClick}
			disabled={props.disabled}
			className="custom-sticky sticky-top d-flex align-items-center gap-2 mb-3 mt-2"
            size={props.size}
		>
			<Save size={18} />
			<span>{props.label || 'Uložit změny'}</span>
		</Button>
	) : <div />;
}

export default SaveButton;
