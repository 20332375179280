import React from 'react';

import ListTable from "../../components/ListTable";
import {BMW_ALL} from "../../constants/vehicleFilters";

function BMWList(): JSX.Element {
    return (
        <ListTable type={BMW_ALL} uniqueName={BMW_ALL} title="BMW" config={{ createButtonEnabled: true, deleteButtonEnabled: true, stornoButtonEnabled: true, importEnabled: true, detailEnabled: true }} />
    );
}

export default BMWList;
