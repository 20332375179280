import { noop } from 'lodash';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-final-form';

import { Settings } from '../../@types/settings';
import TextField from '../../components/form/fields/TextField';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher, FetcherError } from "../../utils/fetcher";

interface SettingPageProps {

}

function SettingPage(props: SettingPageProps): JSX.Element {

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<Settings>();

	useEffect(() => {
		getState();
	}, []);

	const getState = async () => {
		const response = await apiFetcher('settings', {
			method: HTTPMethod.GET,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (response.status === 200) {
			setData(response.json as Settings);
			setIsLoading(false);
		}
	}

	return isLoading ? (
		<Layout>
			<Loader />
		</Layout>
	) : (
			<Layout>
				<Form onSubmit={noop}
					render={() => (
						<form>
							<Row>
								<Col xs lg="3">
									<h4>Stránkování</h4>
									<TextField
										disabled={true}
										label="Základní velikost stránkování"
										name="paging.pageSize"
										initialValue={data?.paging.pageSize}
									/>
								</Col>
							</Row>
							<br />
							<br />
							<Row>
								<Col>
									<h4>Process na kontrolování stavu</h4>
								</Col>
							</Row>
							<Row>
								<Col xs lg="3">
									<TextField
										disabled={true}
										label="Zapnuto"
										name="stateCheckerJob.enabled"
										initialValue={data?.stateCheckerJob.enabled ? "Ano" : "Ne"}
									/>
								</Col>
								<Col xs lg="3">
									<TextField
										disabled={true}
										label="Periodicita v hodinách"
										name="stateCheckerJob.periodInHours"
										initialValue={data?.stateCheckerJob.periodInHours}
									/>
								</Col>
							</Row>
							<br />
							<br />
							<Row>
								<Col>
									<h4>Dokumenty</h4>
								</Col>
							</Row>
							<Row>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="BMW"
										name="documentPaths.bmw"
										initialValue={data?.documentPaths.bmw}
									/>
								</Col>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="BMW VIN"
										name="documentPaths.bmw_vin"
										initialValue={data?.documentPaths.bmW_VIN}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="Moto"
										name="documentPaths.moto"
										initialValue={data?.documentPaths.moto}
									/>
								</Col>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="Ostatní"
										name="documentPaths.others"
										initialValue={data?.documentPaths.others}
									/>
								</Col>
							</Row>
							<br />
							<br />
							<Row>
								<Col>
									<h4>Import</h4>
								</Col>
							</Row>
							<Row>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="BMW"
										name="importPaths.bmw"
										initialValue={data?.importPaths.bmw}
									/>
								</Col>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="Moto"
										name="importPaths.moto"
										initialValue={data?.importPaths.moto}
									/>
								</Col>
								<Col xs lg="4">
									<TextField
										disabled={true}
										label="Externí"
										name="importPaths.external"
										initialValue={data?.importPaths.external}
									/>
								</Col>
							</Row>
						</form>
					)}
				/>
		</Layout>
	);
}

export default SettingPage;
