import React from 'react';
import Form from 'react-bootstrap/Form';
import { FieldMetaState } from 'react-final-form';

function Error({ error: errorMessage, touched }: FieldMetaState<any>): JSX.Element | null {
	return errorMessage ? (
		<Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
	) : null;
}

export default Error;
