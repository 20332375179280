import React, { ReactNode } from 'react';

import { SContent } from './Content.styled';

type SectionProps = {
	children: ReactNode;
	innerSpacing?: number | number[];
	className?: string;
};

const Section: React.FC<SectionProps> = ({ children, className, innerSpacing }) => {
	return (
		<SContent innerSpacing={innerSpacing} className={className}>
			{children}
		</SContent>
	);
};

export default Section;
