import styled from '@emotion/styled';
import React from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

import Section from '../../components/Section';
import {
	BANK,
	BRANCH,
	BRAND,
	CATEGORY,
	COLOR,
	DEALER,
	GEAR,
	INSURANCES,
	LEASING_COMPANIES,
	LINE_COLOR,
	LOCATION,
	MARKETING,
	OFFER,
	OFFER_TWO,
	PRODUCTION_STATUS,
	SALES_PERSON,
	SALES_PERSONAL_REWARDS,
	SELLING_CENTER,
	SUPPLIERS,
	TP,
	UPHOLSTER,
	VEHICLE_MODEL,
	WAREHOUSE,
	WEB_SECTION,
} from '../../constants/lists';

const SListGroup = styled(ListGroup)`
	max-height: calc(100vh - 150px);
	overflow-y: scroll;
`;

function SideNavigation({
	activeListName,
	onChange,
}: {
	activeListName: string;
	onChange: (listName: string) => void;
}): JSX.Element {
	const { Item } = ListGroup;
	return (
		<Col xs={2}>
			<Section>
				<SListGroup id="nav">
					<Item
						onClick={() => onChange(BRAND)}
						active={activeListName === BRAND}
						action
						className="py-2"
					>
						Brand
					</Item>
					<Item
						onClick={() => onChange(DEALER)}
						active={activeListName === DEALER}
						action
						className="py-2"
					>
						Dealer CZ / AG
					</Item>
					<Item
						onClick={() => onChange(SUPPLIERS)}
						active={activeListName === SUPPLIERS}
						action
						className="py-2"
					>
						Dodavatelé
					</Item>
					<Item
						onClick={() => onChange(CATEGORY)}
						active={activeListName === CATEGORY}
						action
						className="py-2"
					>
						Kategorie
					</Item>
					<Item
						onClick={() => onChange(LEASING_COMPANIES)}
						active={activeListName === LEASING_COMPANIES}
						action
						className="py-2"
					>
						Leasingové společnosti
					</Item>
					<Item
						onClick={() => onChange(OFFER)}
						active={activeListName === OFFER}
						action
						className="py-2"
					>
						Nabídka (BMW)
					</Item>
					<Item
						onClick={() => onChange(OFFER_TWO)}
						active={activeListName === OFFER_TWO}
						action
						className="py-2"
					>
						Nabídka 2
					</Item>
					<Item
						onClick={() => onChange(SALES_PERSONAL_REWARDS)}
						active={activeListName === SALES_PERSONAL_REWARDS}
						action
						className="py-2"
					>
						Odměna prodejci
					</Item>
					<Item
						onClick={() => onChange(BRANCH)}
						active={activeListName === BRANCH}
						action
						className="py-2"
					>
						Pobočka
					</Item>
					<Item
						onClick={() => onChange(INSURANCES)}
						active={activeListName === INSURANCES}
						action
						className="py-2"
					>
						Pojišťovny
					</Item>
					<Item
						onClick={() => onChange(SELLING_CENTER)}
						active={activeListName === SELLING_CENTER}
						action
						className="py-2"
					>
						Prodávající středisko
					</Item>
					<Item
						onClick={() => onChange(MARKETING)}
						active={activeListName === MARKETING}
						action
						className="py-2"
					>
						Rozbor marketing
					</Item>
					<Item
						onClick={() => onChange(WEB_SECTION)}
						active={activeListName === WEB_SECTION}
						action
						className="py-2"
					>
						Sekce Web
					</Item>
					<Item onClick={() => onChange(TP)} active={activeListName === TP} action className="py-2">
						TP
					</Item>
					<Item
						onClick={() => onChange(LOCATION)}
						active={activeListName === LOCATION}
						action
						className="py-2"
					>
						Umístění
					</Item>
					<Item
						onClick={() => onChange(COLOR)}
						active={activeListName === COLOR}
						action
						className="py-2"
					>
						Barvy
					</Item>
					<Item
						onClick={() => onChange(UPHOLSTER)}
						active={activeListName === UPHOLSTER}
						action
						className="py-2"
					>
						Polstrování
					</Item>
					<Item
						onClick={() => onChange(GEAR)}
						active={activeListName === GEAR}
						action
						className="py-2"
						id="section-1"
					>
						Výbava
					</Item>
					<Item
						onClick={() => onChange(WAREHOUSE)}
						active={activeListName === WAREHOUSE}
						action
						className="py-2"
					>
						Sklady
					</Item>
					<Item
						onClick={() => onChange(VEHICLE_MODEL)}
						active={activeListName === VEHICLE_MODEL}
						action
						className="py-2"
					>
						Modely vozidel
					</Item>
					<Item
						onClick={() => onChange(PRODUCTION_STATUS)}
						active={activeListName === PRODUCTION_STATUS}
						action
						className="py-2"
					>
						Výrobní status
					</Item>
					<Item
						onClick={() => onChange(SALES_PERSON)}
						active={activeListName === SALES_PERSON}
						action
						className="py-2"
					>
						Prodejci
					</Item>

					<Item
						onClick={() => onChange(BANK)}
						active={activeListName === BANK}
						action
						className="py-2"
					>
						Banky
					</Item>
					<Item
						onClick={() => onChange(LINE_COLOR)}
						active={activeListName === LINE_COLOR}
						action
						className="py-2"
					>
						Barvy řádků
					</Item>
				</SListGroup>
			</Section>
		</Col>
	);
}

export default SideNavigation;
