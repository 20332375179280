import { format, isValid } from 'date-fns';
import { cs } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Field, FieldInputProps } from 'react-final-form';
import 'react-datepicker/dist/react-datepicker.css';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';
import Error from '../Error';

registerLocale('cs', cs);

const DatePickerInput = ({
	onCalendarClose,
	disabled,
	input: { name, value, onBlur, onChange },
	minDate,
	maxDate,
	placeholder,
	isInvalid,
	showDays = true,
	showFutureDateAlert,
}: {
	onCalendarClose?: () => void;
	disabled?: boolean;
	input: FieldInputProps<string, any>;
	minDate?: string;
	maxDate?: string;
	placeholder?: string;
	isInvalid?: boolean;
	showDays?: boolean;
	showFutureDateAlert?: boolean;
}): JSX.Element => {
	const [dateToDisplay, setDateToDisplay] = useState<Date | undefined>(
		value !== '' ? new Date(value) : undefined
	);
	const showDataAlert = (onConfirm: () => void, onDenied: () => void) => {
		if (window.confirm('Vybrané datum je v budoucnu. Je tomu skutečně tak?')) {
			onConfirm();
		} else {
			onDenied();
		}
	};
	useEffect(() => {
		if (value === '' && dateToDisplay !== undefined) {
			setDateToDisplay(undefined);
		}
		if (value !== '' && dateToDisplay === undefined) {
			setDateToDisplay(new Date(value));
		}
	}, [value]);

	return (
		<DatePicker
			autoComplete="off"
			className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
			wrapperClassName="form-control"
			readOnly={disabled}
			disabled={disabled}
			locale="cs"
			{...(placeholder && {
				placeholderText: placeholder,
			})}
			dateFormat={showDays ? 'dd.MM.yyyy' : 'MM.yyyy'}
			showMonthYearPicker={!showDays}
			disabledKeyboardNavigation
			selected={dateToDisplay}
			name={name}
			{...(minDate && { minDate: new Date(minDate) })}
			{...(maxDate && { maxDate: new Date(maxDate) })}
			onBlur={onBlur}
			onCalendarClose={onCalendarClose}
			onChange={(date) => {
				if (date && isValid(date)) {
					if (showFutureDateAlert && date > new Date()) {
						showDataAlert(
							() => {
								setDateToDisplay(date);
								onChange(format(new Date(date), 'yyyy-MM-dd'));
							},
							() => {
								setDateToDisplay(undefined);
								onChange('');
							}
						);
					} else {
						setDateToDisplay(date);
						onChange(format(new Date(date), 'yyyy-MM-dd'));
					}
				} else {
					onChange('');
					setDateToDisplay(undefined);
				}
			}}
		/>
	);
};

function DatePickerField({
	disabled,
	initialValue,
	label,
	maxDate,
	minDate,
	name,
	onCalendarClose,
	placeholder,
	validate,
	showDays,
	showFutureDateAlert,
}: FormFieldType & {
	initialValue?: string;
	maxDate?: string;
	minDate?: string;
	onCalendarClose?: () => void;
	placeholder?: string;
	showDays?: boolean;
	showFutureDateAlert?: boolean;
}): JSX.Element {
	return (
		<ControlLayout name={name} label={label}>
			<Field id={name} name={name} initialValue={initialValue} validate={validate}>
				{({ input, meta }) => (
					<>
						<DatePickerInput
							disabled={disabled}
							input={input}
							placeholder={placeholder}
							minDate={minDate}
							maxDate={maxDate}
							isInvalid={meta.touched && meta.error}
							onCalendarClose={onCalendarClose}
							showFutureDateAlert={showFutureDateAlert}
							showDays={showDays}
						/>
						<Error {...meta} />
					</>
				)}
			</Field>
		</ControlLayout>
	);
}

export default DatePickerField;
