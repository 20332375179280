import {AlignJustify, Columns, Eye, EyeOff, SearchIcon, XIcon} from "lucide-react";
import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from "react-bootstrap/Tooltip";
import {ReactSortable} from "react-sortablejs";

import {SIconEye, SItem, SItemTitle, SItemTitleWrapper} from "./ColumnsSelect.styled";
import { Column, ColumnObject } from "../../@types/vehicleFilters";
import {defaultHiddenColumns } from "../../constants/vehicleFilters";
import useUserViewQuery from "../../hooks/useUserViewQuery";
import Loader from "../Loader";


interface Props {
    type: string;
    columns: Column[];
    onColumnsChange: (data: Column[]) => void;
    selectedColumnSettingId?: number | string;
}

interface ColumnsSelectModalProps extends Props {
    show?: boolean;
    onHide: () => void;
}

type ItemType = {
    id: number | string;
} & Column;

function ColumnsSelectModal(props: ColumnsSelectModalProps): JSX.Element {
    const {
        columns,
        show,
        onColumnsChange,
        onHide,
        selectedColumnSettingId,
        type,
    } = props;


    const [data, setData] = useState<ItemType[]>([]);
    const [settingId, setSettingId] = useState<number | string | undefined>(selectedColumnSettingId);

    const [searchText, setSearchText] = useState('');

    const {data: userViewData, isLoading: userViewLoading} = useUserViewQuery(type);

    useEffect(() => {
        setData(
            columns.map((item) => ({
                ...item,
                id: item.name,
            }))
        );
    }, [columns]);

    const handleOnVisibleChange = (id: number | string) => {
        setData((prevData) => {
            return prevData.map((item) =>
                item.id === id ? {...item, hidden: !item.hidden} : item
            );
        });
        setSettingId(undefined);
    };

    const transformedObject: ColumnObject = columns.reduce((acc, column) => {
        acc[column.name] = {
            order: column.order,
            label: column.label,
            hidden: column.hidden,
            type: column.type,
            options: column.options,
            hasPredifenedFilter: column.hasPredifenedFilter,
        };
        return acc;
    }, {} as ColumnObject);

    const handleOnSelectedSettingChange = (id: number | string | undefined) => {
        setSettingId(id);

        // @ts-expect-error
        const columnsRawData = userViewData.find(item => item.id === id)?.structure;

        if (columnsRawData) {
            const columnsData = JSON.parse(columnsRawData)?.columns;

            if (columnsData && columnsData.length > 0) {
                // @ts-expect-error
                const visibleColumns = columnsData.map((item) => {
                    if (transformedObject && transformedObject[item.name]) {
                        return {
                            ...transformedObject[item.name],
                            id: item.name,
                            name: item.name,
                            hidden: defaultHiddenColumns.includes(item.name),
                            columnWidth: item.columnWidth
                        }
                    }
                })

                const hiddenColumns = columns.map((item) => {
                    // @ts-expect-error
                    if (!visibleColumns.find((vItem) => vItem.name === item.name)) {
                        return {
                            ...item,
                            id: item.name,
                            hidden: true
                        }
                    }
                })

                setData([...visibleColumns, ...hiddenColumns].filter(Boolean));
            }

        }
    }

    const handleHideAll = () => {
        setData((prevData) => {
            return prevData.map((item) => ({
                ...item,
                hidden: true,
            }));
        });
    };

    const handleShowAll = () => {
        setData((prevData) => {
            return prevData.map((item) => ({
                ...item,
                hidden: false,
            }));
        });
    };

    const handleSubmit = () => {
        onColumnsChange(data.map((item) => ({
            ...item,
            name: item.name || item.id.toString(),
        })));
        if (settingId) {
            setSettingId(settingId);
        }
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby=""
            size="xl"
            scrollable
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Výběr pohledu
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={4}>
                        <p><strong>Předdefinované pohledy</strong></p>
                        {userViewLoading ? (
                            <Loader/>
                        ) : (
                            <ListGroup>
                                {userViewData && userViewData.length > 0 ? userViewData.map((item: any) => (
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Tooltip>
                                                {item.description}
                                            </Tooltip>
                                        }
                                    >
                                        <ListGroup.Item action active={settingId === item.id}
                                                        onClick={() => handleOnSelectedSettingChange(item.id)}>
                                            {item.name}
                                        </ListGroup.Item>
                                    </OverlayTrigger>
                                )) : (
                                    <p>Pohledy nebyly nalezeny.</p>
                                )}
                            </ListGroup>
                        )}
                    </Col>
                    <Col md={{span: 7, offset: 1}}>
                        <div className="flex align-items-center flex-row justify-content-between d-flex gap-2 p-2">
                            <InputGroup style={{width: "55%"}}>
                                <InputGroup.Text><SearchIcon/></InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Hledat sloupce"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                />
                                <InputGroup.Text
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setSearchText("");
                                    }}
                                ><XIcon/></InputGroup.Text>
                            </InputGroup>
                            <div className="flex align-items-center flex-row justify-content-end d-flex gap-2 p-2">
                                <Button onClick={handleHideAll} variant="outline-secondary">
                                    Skryt vše <EyeOff/>
                                </Button>
                                <Button onClick={handleShowAll} variant="primary">
                                    Zobrazit vše <Eye/>
                                </Button>
                            </div>
                        </div>
                        <ListGroup>
                            <ReactSortable list={data} setList={(data) => {
                                setData(data);
                                setSettingId(undefined);
                            }}>
                                {data.sort((a, b) => {
                                    if (a.hidden && !b.hidden) return 1;
                                    if (!a.hidden && b.hidden) return -1;
                                    return 0;
                                }).map((item) => {
                                    return item && (
                                        <ListGroup.Item key={item.id} action variant="light"
                                                        className={!item.label.toLowerCase().includes(searchText.toLowerCase()) ? "d-none" : ""}>
                                            <SItem>
                                                <SItemTitleWrapper>
                                                    <AlignJustify size={16}/>
                                                    <SItemTitle isActive={!item.hidden}>
                                                        {item.label}
                                                    </SItemTitle>
                                                </SItemTitleWrapper>

                                                <Form.Check
                                                    label={<SIconEye>{item.hidden ? <EyeOff/> : <Eye/>}</SIconEye>}
                                                    checked={!item.hidden}
                                                    onChange={() => handleOnVisibleChange(item.id)}
                                                    value={item.id}
                                                    name="columns"
                                                    type="switch"
                                                    inline
                                                />
                                            </SItem>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ReactSortable>
                        </ListGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSubmit} size="lg">Zobrazit</Button>
                <Button onClick={onHide} variant="secondary" size="lg">
                    Zrušit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ColumnsSelect(props: Props): JSX.Element {

    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={
                    <Tooltip>
                        Výběr pohledu
                    </Tooltip>
                }
            >
                <Button variant="light" onClick={() => setModalShow(true)}>
                    <Columns size={26}/>
                </Button>
            </OverlayTrigger>

            <ColumnsSelectModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                {...props}
            />
        </>
    );
}

export default ColumnsSelect;