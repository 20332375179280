import React from 'react';

import { WarehouseListItem } from '../../@types/lists';
import CheckField from '../../components/form/fields/CheckField';
import TextField from '../../components/form/fields/TextField';

function WarehouseListForm({
	initialValues,
}: {
	initialValues?: WarehouseListItem | null;
}): JSX.Element {
	return (
		<>
			<TextField as="textarea" label="Název" name="name" initialValue={initialValues?.name} />
			<div className="d-flex gap-3">
				<CheckField
					label="BMW"
					name="useInBmw"
					type="checkbox"
					initialValue={initialValues?.useInBmw}
				/>
				<CheckField
					label="Moto"
					name="useInMoto"
					type="checkbox"
					initialValue={initialValues?.useInMoto}
				/>
				<CheckField
					label="Ostatní"
					name="useInOthers"
					type="checkbox"
					initialValue={initialValues?.useInOthers}
				/>
			</div>
		</>
	);
}

export default WarehouseListForm;
