import { useQuery } from '@tanstack/react-query';

import { VehicleDetail } from '../../../@types/vehicleDetail';
import { UPHOLSTER, COLOR, GEAR } from '../../../constants/lists';
import { apiFetcher } from '../../../utils/fetcher';
import useListQuery from '../../Lists/useListQuery';
import useType from '../useType';

const useSpecification = ({ id }: { id?: string }) => {
	const { id: typeID } = useType();
	const upholsterQuery = useListQuery(UPHOLSTER);
	const colorsQuery = useListQuery(COLOR);
	const gearQuery = useListQuery(GEAR);
	const detailQuery = useQuery<VehicleDetail, Error>({
		queryKey: ['vehicleDetail', { id }],
		queryFn: async (): Promise<VehicleDetail> => {
			const res = await apiFetcher(`vehicles/${id}?type=${typeID}`);

			return res.json as VehicleDetail;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const isLoading = [
		colorsQuery.isLoading,
		upholsterQuery.isLoading,
		detailQuery.isLoading,
		gearQuery.isLoading,
	].some((item) => item === true);
	return {
		isLoading,
		colors: colorsQuery.data,
		upholster: upholsterQuery.data,
		vehicleDetail: detailQuery.data,
		gear: gearQuery.data,
		vehicleDetailRefetch: detailQuery.refetch,
	};
};

export default useSpecification;
