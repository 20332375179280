import React from 'react';

import useListQuery from './useListQuery';
import { BasicListItem, ProductionStatusListItem } from '../../@types/lists';
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { BRAND } from '../../constants/lists';
import { mapBasicListToOptions } from '../../utils/lists';

function ProductionStatusListForm({
	initialValues,
	variant
}: {
	initialValues?: ProductionStatusListItem | null;
	variant: 'add' | 'edit';
}): JSX.Element {
	const brandsQuery = useListQuery(BRAND);
	return (
		<>
			{variant === 'add' && (
				<TextField
					label="Status"
					name="productionStatusCode"
					initialValue={initialValues?.productionStatusCode}
				/>
			)}
			<TextField label="Popis" name="description" initialValue={initialValues?.description} />
		</>
	);
}

export default ProductionStatusListForm;
