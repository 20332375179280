import { FilterIcon } from 'lucide-react';
import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';

import { Column as ColumnType, Filter } from '../../@types/vehicleFilters';
import { translationsValues } from '../../constants/translations';
import { TYPE, VALUE } from '../../constants/vehicleFilters';
import { Column, getLabelForSelectedOption } from '../ColumnsFilter/Column';

interface ColumnFilterProps {
	title: string;
	onSubmit: (filters: Filter[]) => void;
	filters?: Filter[] | null;
	column: ColumnType;
}

interface ColumnFilterModalProps extends ColumnFilterProps {
	show?: boolean;
	onHide: () => void;
}

function ColumnFilterModal(props: ColumnFilterModalProps): JSX.Element {
	const { show, onHide, title } = props;

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<strong>{title}</strong> - filtr
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Column
					onSubmit={props.onSubmit}
					onHide={props.onHide}
					title={title}
					filters={props.filters}
					column={props.column}
				/>
			</Modal.Body>
		</Modal>
	);
}

export function renderTranslation(value: string): string {
	// @ts-expect-error
	return translationsValues(value) ? translationsValues(value) : value;
}

export function renderValue(column: ColumnType, value: string | number): string | number {
	if (!column.hasPredifenedFilter) {
		// @ts-expect-error
		return renderTranslation(value);
	}

	const option =
		column.options &&
		column.options.find((option) => {
			if (typeof value === 'boolean') {
				const nvalue = value ? '1' : '0';
				return option.value === nvalue;
			}
			return option.value === value;
		});

	if (option) {
		return renderTranslation(option.name);
	}

	// @ts-expect-error
	return renderTranslation(value);
}

function ColumnFilter(props: ColumnFilterProps): JSX.Element {
	const [modalShow, setModalShow] = useState(false);
	const { filters } = props;

	function onSubmit(data: Filter[]) {
		props.onSubmit(data);
		setModalShow(false);
	}

	const popover = (
		<Popover id="popover-basic">
			{filters && filters.length > 0 ? (
				<>
					<Popover.Header as="h3">Vybrané filtry</Popover.Header>
					<Popover.Body>
						<Table bordered>
							<thead>
								<tr>
									<th>Typ</th>
									<th>Hodnota</th>
								</tr>
							</thead>
							<tbody>
								{filters.map((item: Filter, index) => (
									<tr key={index}>
										<td>{getLabelForSelectedOption(item[TYPE])}</td>
										<td className="text-break">{renderValue(props.column, item[VALUE])}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Popover.Body>
				</>
			) : (
				<Popover.Body>Přidejte filtr sloupce</Popover.Body>
			)}
		</Popover>
	);
	return (
		<>
			<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
				<Button
					variant={filters && filters.length > 0 ? 'primary' : 'light'}
					onClick={() => setModalShow(true)}
					size="sm"
				>
					<FilterIcon size={16} onClick={() => setModalShow(true)} />{' '}
					{filters && filters.length > 0 && <Badge bg="danger">{filters.length}</Badge>}
				</Button>
			</OverlayTrigger>

			<ColumnFilterModal
				title={props.title}
				filters={filters}
				onSubmit={onSubmit}
				show={modalShow}
				onHide={() => setModalShow(false)}
				column={props.column}
			/>
		</>
	);
}

export default ColumnFilter;
