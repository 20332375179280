import { useQuery } from '@tanstack/react-query';

import { CustomerLedgerEntry } from '../../../@types/customerLedgerEntry';
import { apiFetcher } from '../../../utils/fetcher';

const useInvoiceQuery = (id?: string) => {
	const customerLedgerEntryQuery = useQuery<CustomerLedgerEntry[], Error>({
		queryKey: ['invoiceNumber', { id }],
		queryFn: async (): Promise<CustomerLedgerEntry[]> => {
			const res = await apiFetcher(`vehicles/${id}/invoiceNumber`);

			return res.json as CustomerLedgerEntry[];
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});

	const mapCustomerLedgerEntriesToOptions = (customerLedgerEntries: CustomerLedgerEntry[]): Array<{ value: string; label: string }> => {
		if (!customerLedgerEntries || customerLedgerEntries.length === 0) return [];
		return customerLedgerEntries.map((customerLedgerEntry) => (
			{
				value: `${customerLedgerEntry.vehicleSales_InvoiceNumber}`,
				label: `${customerLedgerEntry.vehicleSales_InvoiceNumber}, ${customerLedgerEntry.name}`
			}));
	}

	return {
		isLoading: !!id ? customerLedgerEntryQuery.isLoading : false,
		invoiceNumbers: !!id ? mapCustomerLedgerEntriesToOptions(customerLedgerEntryQuery.data as CustomerLedgerEntry[]) : [],
		refetch: customerLedgerEntryQuery.refetch,
		data: !!id ? customerLedgerEntryQuery.data : []
	};
};

export default useInvoiceQuery;
