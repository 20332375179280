import { useQuery } from '@tanstack/react-query';

import { WarehouseListItem } from '../../../@types/lists';
import { WAREHOUSE } from '../../../constants/lists';
import { apiFetcher } from '../../../utils/fetcher';
import { mapListWithNameToOptions } from '../../../utils/lists';
import useListQuery from '../../Lists/useListQuery';

export interface Loan {
	id: string;
	warehouseId: string;
    vehicleId: string;
	loanedFrom: string;
	loanedTo?: string;
	description?: string;
}

const useLoans = ({ id }: { id?: string }) => {
	const warehousesQuery = useListQuery(WAREHOUSE, id);
	const loansQuery = useQuery<Loan[], Error>({
		queryKey: ['loans', { id }],
		queryFn: async (): Promise<Loan[]> => {
			const res = await apiFetcher(`loans/${id}`);

			return res.json as Loan[];
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	return {
		isLoading: loansQuery.isLoading || warehousesQuery.isLoading,
		loans: loansQuery.data,
		warehouses: mapListWithNameToOptions(warehousesQuery.data as WarehouseListItem[]),
        refetch: loansQuery.refetch,
	};
};

export default useLoans;
