import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { EquipmentListItem } from '../../../@types/lists';

function ModalGearOverview({
	gear,
	onClose,
	selectedGear,
}: {
	gear: EquipmentListItem[];
	selectedGear: string | undefined;
	onClose: () => void;
}): React.JSX.Element {
	const selectedGearToArray = selectedGear ? selectedGear.split(' ') : [];
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 15% 85%`,
		},
	]);
	const gearData = selectedGearToArray
		.map((value) => {
			const selected = gear.find((gear) => gear.code === value);
			return (
				selected && {
					code: selected?.code,
					description: selected?.description,
				}
			);
		})
		.filter((val) => val !== undefined);
	const tableData = { nodes: gearData };
	return (
		<Modal show={true} onHide={onClose} className='modal-lg'>
			<Modal.Header closeButton>
				<Modal.Title>Rozpis výbavy</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table data={tableData} theme={theme}>
					{(tableList: Array<any>) => (
						<>
							<Header>
								<HeaderRow>
									<HeaderCell>Kód</HeaderCell>
									<HeaderCell>Popis</HeaderCell>
								</HeaderRow>
							</Header>
							<Body>
								{tableList.map((item) => (
									<TableRow key={item.id} item={item}>
										<Cell>{item.code}</Cell>
										<Cell>{item.description}</Cell>
									</TableRow>
								))}
							</Body>
						</>
					)}
				</Table>
			</Modal.Body>
		</Modal>
	);
}

export default ModalGearOverview;
