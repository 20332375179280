import {FilterIcon, SearchIcon, XIcon} from "lucide-react";
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';

import {Column, getLabelForSelectedOption} from './Column';
import {Column as ColumnTyp, Filter} from "../../@types/vehicleFilters";
import {TYPE, VALUE} from "../../constants/vehicleFilters";
import {renderValue} from "../ColumnFilter";

interface ColumnsFilterModalProps {
    columns: ColumnTyp[];
    filters: { [key: string]: Filter[] }
    onSubmit: (filters: { [key: string]: Filter[] }) => void;
    show: boolean;
    onHide: () => void;
}


interface FilterContentProps {
    label?: string;
    selectedColumn: string;
    selectedFilters?: Filter[];
    handleRemoveFilter: (columnName: string) => void;
    onSubmit: (filters: Filter[]) => void;
    column: ColumnTyp,
}

const FilterContent: React.FC<FilterContentProps> = ({
                                                         column,
                                                         label,
                                                         selectedColumn,
                                                         selectedFilters,
                                                         handleRemoveFilter,
                                                         onSubmit
                                                     }) => {
    const [isTableVisible, setIsTableVisible] = useState(selectedFilters && selectedFilters.length > 0);

    const handleToggleView = () => {
        setIsTableVisible(!isTableVisible);
    };

    return (
        <Card className="mb-4">
            <Card.Header>Filtr pro <strong>{label}</strong></Card.Header>

            <Card.Body>
                {isTableVisible ? (
                    <div>
                        {selectedFilters && selectedFilters.length > 0 ? (
                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Typ</th>
                                <th>Hodnota</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedFilters?.map((item: Filter, index: number) => (
                                <tr key={index}>
                                    <td>{getLabelForSelectedOption(item[TYPE])}</td>
                                    <td>{renderValue(column, item[VALUE])}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        ) : (
                            <>
                            <p>Není nastaven žádný filtr.</p>
                                <Button variant="primary" onClick={handleToggleView}
                                        className="mt-1">
                                    Přidat první filtr
                                </Button>
                            </>
                        )}
                        <div className="mt-4">
                            <Row>
                                <Col/>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => handleRemoveFilter(selectedColumn)}
                                            className="me-2">
                                        Odebrat filtr
                                    </Button>
                                    <Button variant="primary" onClick={handleToggleView}>
                                        Upravit
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) : (
                    <Column
                        title={selectedColumn}
                        onSubmit={onSubmit}
                        filters={selectedFilters && selectedFilters.length > 0 ? selectedFilters : [{type: "", value: ""}]}
                        onHide={handleToggleView}
                        column={column}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

function ColumnsFilterModal(props: ColumnsFilterModalProps) {
    const {show, onHide, onSubmit, filters} = props;

    const [searchText, setSearchText] = useState("");
    const [filteredColumns, setFilteredColumns] = useState(props.columns);

    const [columnFilters, setColumnFilters] = useState<{ [key: string]: Filter[] }>({});

    const filterColumns = (text: string) => {
        const lowercasedText = text.toLowerCase();
        const filtered = props.columns.filter(column => column.label.toLowerCase().includes(lowercasedText));
        setFilteredColumns(filtered);
    };

    useEffect(() => {
        setColumnFilters(filters);
    }, [filters]);

    useEffect(() => {
        setFilteredColumns(props.columns);
    }, [props.columns.toString()]);

    const handleRemoveFilter = (columnName: string) => {
        const {[columnName]: _, ...newFilters} = columnFilters;
        setColumnFilters(newFilters);
    };

    const updateColumnFilter = (columnName: string, newFilter: Filter[]) => {
        const newFilters = {...columnFilters, [columnName]: newFilter};
        setColumnFilters(newFilters);
    };

    const handleApplyFilters = () => {
        onSubmit(columnFilters);
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby=""
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Rozšířené filtrování</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height: 'calc(100vh - 200px)'}}>
                <Row className="h-100">
                    <Col md={4} className="h-100">
                        <div className="d-flex flex-column h-100">
                            <p className="flex-shrink-0"><strong>Vyberte sloupce, které chcete filtrovat:</strong></p>
                            <InputGroup className="mb-3">
                                <InputGroup.Text><SearchIcon/></InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Hledat sloupce"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        filterColumns(e.target.value);
                                    }}
                                />
                                <InputGroup.Text
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setSearchText("");
                                        filterColumns("");
                                    }}
                                ><XIcon/></InputGroup.Text>
                            </InputGroup>

                            <div className="overflow-y-auto flex-1">
                                <ListGroup>
                                    {filteredColumns.map((column, index) => (
                                        <ListGroup.Item
                                            key={index}
                                            active={Boolean(columnFilters[column.name])}
                                            onClick={() => {
                                                if (columnFilters[column.name]) {
                                                    handleRemoveFilter(column.name);
                                                } else {
                                                    updateColumnFilter(column.name, []);
                                                }
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            {column.label}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </div>
                    </Col>
                    <Col md={{span: 7, offset: 1}} className="h-100">
                        <div className="d-flex flex-column h-100">
                            <p className="flex-shrink-0"><strong>Nastavte filtry pro vybrané sloupce:</strong></p>
                            <div className="overflow-y-auto flex-1" style={{ height: 'calc(100% - 21px)'}}>
                                {Object.keys(columnFilters).map((selectedColumn, index) => (
                                    <div key={index}>
                                        <FilterContent
                                            label={props.columns.find(column => column.name === selectedColumn)?.label}
                                            selectedColumn={selectedColumn}
                                            selectedFilters={columnFilters[selectedColumn]}
                                            handleRemoveFilter={handleRemoveFilter}
                                            onSubmit={(filters) => updateColumnFilter(selectedColumn, filters)}
                                            // @ts-ignore
                                            column={props.columns.find(column => column.name === selectedColumn)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Zavřít
                </Button>
                <Button variant="primary" onClick={handleApplyFilters}>
                    Aplikovat filtry
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function ColumnsFilterButton(props: { onClick: () => void }) {
    return (
        <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip>Rozšířené filtrování</Tooltip>}
        >
            <Button variant="light" onClick={props.onClick}>
                <FilterIcon size={26}/>
            </Button>
        </OverlayTrigger>
    );
}

interface ColumnsFilterProps {
    columns: {
        label: string,
        name: string,
    }[];
    filters: { [key: string]: Filter[] }
    onSubmit: (filters: { [key: string]: Filter[] }) => void;
}

export default function ColumnsFilter(props: ColumnsFilterProps) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };


    return (
        <>
            <ColumnsFilterButton onClick={handleShowModal}/>
            {/* @ts-ignore */}
            <ColumnsFilterModal {...props} show={showModal} onHide={handleHideModal}/>
        </>
    );
}
