import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';
import Error from '../Error';

function TextField({
	disabled,
	initialValue,
	label,
	name,
	placeholder,
	validate,
	as = 'input',
	type = 'text',
	rows = 4,
	onChange,
	shouldCopyOnDoubleClick,
	simple,
	hideErrorMessage,
	shouldParseValue,
}: FormFieldType & {
	initialValue?: string | number;
	placeholder?: string;
	as?: 'input' | 'textarea';
	type?: string;
	rows?: number;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	shouldCopyOnDoubleClick?: boolean;
	simple?: boolean;
	hideErrorMessage?: boolean;
	shouldParseValue?: boolean;
}): JSX.Element {
	const { Control } = Form;
	return (
		<ControlLayout name={name} label={label} simple={simple}>
			<Field
				name={name}
				initialValue={initialValue}
				validate={validate}
				{...(shouldParseValue && {
					parse: (value) => value,
				})}
			>
				{({ input, meta }) => (
					<>
						<Control
							autoComplete="off"
							{...input}
							{...(placeholder && { placeholder })}
							id={name}
							disabled={disabled}
							isInvalid={meta.error}
							type={type}
							{...(as === 'textarea' && {
								as,
								rows,
							})}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								onChange?.(e);
								input.onChange(e);
							}}
							{...(shouldCopyOnDoubleClick && {
								onDoubleClick: (e: React.MouseEvent<HTMLInputElement>) => {
									// @ts-ignore
									navigator.clipboard.writeText(e.target.value);
								},
							})}
						/>
						{!hideErrorMessage && <Error {...meta} />}
					</>
				)}
			</Field>
		</ControlLayout>
	);
}

export default TextField;
