import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';

function CheckGroup({
	disabled,
	fields,
	label,
	name,
	validate,
	type,
	initialValue
}: FormFieldType & {
	fields: Array<{ label: string; value: string | undefined; disabled?: boolean }>;
	type: 'radio' | 'checkbox' | 'switch';
	initialValue?: string;
}): JSX.Element {
	const { Check } = Form;

	return (
		<ControlLayout label={label}>
			<div>
				{fields.map((field, id) => (
					<Field
						id={`${name}-${id}`}
						name={name}
						component="input"
						type={type}
						disabled={disabled}
						value={field.value}
						validate={validate}
						initialValue={initialValue}
					>
						{({ input, meta }) => (
							<Check
								{...input}
								{...field}
								id={`${name}-${id}`}
								{...(meta.touched &&
									meta.error && {
										isInvalid: true,
										feedback: meta.error,
										feedbackType: 'invalid',
									})}
								type={type}
							/>
						)}
					</Field>
				))}
			</div>
		</ControlLayout>
	);
}

export default CheckGroup;
