import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';

function GlobalSuccess({ message }: { message?: string }): React.JSX.Element | null {
	const [isVisible, setIsVisible] = React.useState<boolean>(true);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsVisible(false);
		}, 10000);
		return () => clearTimeout(timer);
	}, []);
	return isVisible ? (
		<Alert variant="success" className="mt-2">
			<strong>{message || 'Změny byly úspěšně uloženy'}</strong>
		</Alert>
	) : null;
}

export default GlobalSuccess;
