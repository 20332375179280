import {getTheme} from '@table-library/react-table-library/baseline';
import {
    Body,
    Cell,
    Header,
    HeaderCell,
    HeaderRow,
    Row as TableRow,
    Table,
} from '@table-library/react-table-library/table';
import {useTheme} from '@table-library/react-table-library/theme';
import {format} from "date-fns";
import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Form} from 'react-final-form';
import {useParams} from 'react-router-dom';

import ModalInters from "./ModalInters";
import useInters from "./useInters";
import { useIntersMutation } from "./useIntersMutation";
import CheckField from "../../../components/form/fields/CheckField";
import CurrencyField from "../../../components/form/fields/CurrencyField";
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import {INTERS} from '../../../constants/detailViews';
import Navigation from '../Navigation';

function Inters(): React.JSX.Element {
    const {id} = useParams();
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    const {documentsQuery, documentsValuesQuery} = useInters({
        id,
    });

    const theme = useTheme([
        getTheme(),
    ]);


    const {mutation, errorMessage, successMessage} = useIntersMutation({
        id,
        onSuccess: () => {
            documentsValuesQuery.refetch();
        },
    });

    const data = {nodes: documentsQuery.data};

    return (
        <Layout>
            {showModal && (
                <ModalInters
                    onClose={() => {
                        setShowModal(false);
                        documentsValuesQuery.refetch();
                        documentsQuery.refetch()
                    }}
                    documentId={selectedItem}
                    vehicleId={id}
                />
            )}
            <Navigation activeView={INTERS} id={id as string}/>
            {documentsQuery.isLoading || documentsValuesQuery.isLoading ? (
                <Loader/>
            ) : (
                <>
                    {errorMessage}
                    {successMessage}
                    <Form onSubmit={(values) => {
                        mutation.mutate(values["vehicles_IsRetailRelevant"]);
                    }}>
                        {({handleSubmit, dirtyFields, values}) => {
                            return (

                                <form onSubmit={handleSubmit}>
                                    <Row className="align-items-center my-4">
                                        <Col sm={12} md={2}>
                                            <CurrencyField
                                                label="Interní částka"
                                                name="internalDocumentFigure"
                                                initialValue={documentsValuesQuery?.data?.internalDocumentFigure}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={2}>
                                            <CurrencyField
                                                label="Úrokové náklady"
                                                name="interestsCosts"
                                                initialValue={documentsValuesQuery?.data?.interestsCosts}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={4} className="pt-3">
                                            <CheckField
                                                label="Připočítat úrokové náklady k interním nákladům"
                                                name="vehicles_IsRetailRelevant"
                                                initialValue={documentsValuesQuery?.data?.countInterestsToInternalCosts}
                                                type="checkbox"
                                                simple
                                                onChange={() => handleSubmit()}
                                            />
                                        </Col>
                                    </Row>
                                </form>)
                        }}
                    </Form>
                    <Table data={data} theme={theme}>
                        {(tableList: Array<any>) => (
                            <>
                                <Header>
                                    <HeaderRow>
                                        <HeaderCell>Doklad</HeaderCell>
                                        <HeaderCell>Částka</HeaderCell>
                                        <HeaderCell>Schválená částka</HeaderCell>
                                        <HeaderCell>Datum dokladu</HeaderCell>
                                        <HeaderCell>Vystavil</HeaderCell>
                                        <HeaderCell>Změněno</HeaderCell>
                                        <HeaderCell>Změnil</HeaderCell>
                                    </HeaderRow>
                                </Header>
                                <Body>
                                    {tableList.map((item) => (
                                        <TableRow
                                            key={item.id}
                                            item={item}
                                            onDoubleClick={(item) => {
                                                setShowModal(true);
                                                setSelectedItem(item.documentId);
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <Cell>
                                                {item?.documentId}
                                            </Cell>
                                            <Cell>
                                                {item?.figure}
                                            </Cell>
                                            <Cell>
                                                {item?.approvedFigure}
                                            </Cell>
                                            <Cell>
                                                {format(new Date(item.documentDate), 'dd.MM.yyyy HH:MM')}
                                            </Cell>
                                            <Cell>
                                                {item?.exhibitedBy}
                                            </Cell>
                                            <Cell>
                                                {format(new Date(item.modifiedOn), 'dd.MM.yyyy HH:MM')}
                                            </Cell>
                                            <Cell>
                                                {item?.modifiedBy}
                                            </Cell>
                                        </TableRow>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                </>
            )}
        </Layout>
    );
}

export default Inters;
