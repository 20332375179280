import { useQuery } from '@tanstack/react-query';

import { apiFetcher } from '../../../utils/fetcher';

interface Audit {
	id: string;
	userName: string;
	tableName: string;
	oldValue: any;
	newValue: any;
	createdOn: string;
	modifiedAt?: string;
	createdBy: string;
	modifiedBy?: string;
}

const useAudits = ({ id }: { id?: string }) => {
	const auditsQuery = useQuery<Audit[], Error>({
		queryKey: ['audit', { id }],
		queryFn: async (): Promise<Audit[]> => {
			const res = await apiFetcher(`audit/${id}`);

			return res.json as Audit[];
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	return {
		isLoading: auditsQuery.isLoading,
		audits: auditsQuery.data,
	};
};

export default useAudits;
