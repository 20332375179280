import React from 'react';

import ListTable from "../../components/ListTable";
import { EXTERNAL } from "../../constants/vehicleFilters";

function ExternalStorageList(): JSX.Element {
    return (
        <ListTable type={EXTERNAL} uniqueName={EXTERNAL} title="Cizí sklad" config={{ createButtonEnabled: false, deleteButtonEnabled: false, stornoButtonEnabled: false, importEnabled: true, detailEnabled: false }} />
    );
}

export default ExternalStorageList;
