import {useQuery} from '@tanstack/react-query';
import { is } from 'date-fns/locale';
import {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {ColumnFilters, Rule, RuleGroup} from '../@types/vehicleFilters';
import {VEHICLE_LIST_DATA} from '../constants/queryKeys';
import {apiFetcher} from '../utils/fetcher';

function areAllOperatorsSame(rules: Rule[]): boolean {
    const firstOperator = rules[0]?.operator;

    return rules.every(rule => rule.operator === firstOperator);
}
function columnFiltersToRuleGroup(columnFilters: ColumnFilters): RuleGroup {
    const rules: (Rule | RuleGroup)[] = [];

    for (const columnName of Object.keys(columnFilters)) {
        const filters = columnFilters[columnName];
        const filterRules: (Rule | RuleGroup)[] = [];

        for (const filter of filters) {
            let rule: Rule | RuleGroup | null = null;

            if (filter.type === "in") {
                rule = {
                    field: columnName,
                    operator: filter.type,
                    // @ts-ignore
                    value: filter.value.split(",").map((item) => item.trim()),
                };
            } else if (filter.type === "isNull") {
                let combineRules: Rule[] = [];

                let isNullRule = {
                    field: columnName,
                    operator: filter.type.toString(),
                    value: filter.value,
                };

                combineRules.push(isNullRule);

                let isEmptyRule = {
                    field: columnName,
                    operator: "equal",
                    value: "",
                };

                combineRules.push(isEmptyRule);

                let isSpaceRule = {
                    field: columnName,
                    operator: "equal",
                    value: " ",
                };

                combineRules.push(isSpaceRule);

                rule = {
                    separator: "Or",
                    rules: combineRules
                }

            } else if (filter.type === "isNotNull") {
                let combineRules: Rule[] = [];

                let isNotNullRule = {
                    field: columnName,
                    operator: filter.type.toString(),
                    value: filter.value,
                };

                combineRules.push(isNotNullRule);

                let isEmptyRule = {
                    field: columnName,
                    operator: "notEqual",
                    value: "",
                };

                combineRules.push(isEmptyRule);

                let isSpaceRule = {
                    field: columnName,
                    operator: "notEqual",
                    value: " ",
                };

                combineRules.push(isSpaceRule);

                rule = {
                    separator: "And",
                    rules: combineRules
                }
            } else {
                rule = {
                    field: columnName,
                    operator: filter.type.toString(),
                    //little hack here "1" is not recognized as true when use as boolean
                    value: filter.value === "1" ? 1 : filter.value,
                };
            }

            if (rule) {
                filterRules.push(rule);
            }
        }

        if (filterRules.length > 0) {
            const separator = areAllOperatorsSame(filterRules.filter((filterRule): filterRule is Rule => {
                return (filterRule as Rule).field !== undefined
            })) ? "Or" : "And";

            rules.push({
                separator,
                rules: filterRules,
            });
        }
    }
    
    return {
        separator: "And",
        rules,
    };
}

const useVehiclesQuery = (listName: string) => {
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [unconvertedFilter, setUnconvertedFilter] = useState<ColumnFilters>({});
    const [orderBy, setOrderBy] = useState("");
    const [sortOrder, setSortOrder] = useState(-1);
    const navigate = useNavigate();
    const location = useLocation();

    const changeFilter = (filter: ColumnFilters) => {
        const rulesObject = columnFiltersToRuleGroup(filter);
        setUnconvertedFilter(filter);
        setPage(1);
        setFilter(rulesObject.rules.length > 0 ? JSON.stringify(columnFiltersToRuleGroup(filter)) : "");
    };

    useEffect(() => {
        const currentParams = new URLSearchParams(location.search);
        const urlLimit = parseInt(currentParams.get('pageSize') || '50', 10);
        const urlPage = parseInt(currentParams.get('pageNumber') || '1', 10);
        const urlFilter = currentParams.get('filter');
        const urlOrderBy = currentParams.get('orderBy') || '';
        const urlSortOrder = parseInt(currentParams.get('sortOrder') || '-1', 10);

        if(urlFilter) {
            changeFilter(JSON.parse(urlFilter));
        }

        setLimit(urlLimit);
        setPage(urlPage);
        setOrderBy(urlOrderBy);
        setSortOrder(urlSortOrder);
    }, []);

    const params = new URLSearchParams();

    params.append('pageSize', limit.toString());
    params.append('pageNumber', page.toString());
    if (filter) {
        params.append('filter', filter);
    }
    if (orderBy !== "") {
        params.append('orderBy', orderBy);
        params.append('sortOrder', sortOrder.toString());
    }

    useEffect(() => {
        const urlParams = new URLSearchParams();
        urlParams.append('pageSize', limit.toString());
        urlParams.append('pageNumber', page.toString());
        if (unconvertedFilter) {
            urlParams.append('filter', JSON.stringify(unconvertedFilter));
        }
        if (orderBy !== "") {
            urlParams.append('orderBy', orderBy);
            urlParams.append('sortOrder', sortOrder.toString());
        }

        navigate(`/${listName}/list?${urlParams.toString()}`);
    }, [params.toString()])

    const query = useQuery<any, Error>({
        queryKey: [VEHICLE_LIST_DATA, {listName, limit, page, filter, orderBy, sortOrder}],
        queryFn: async (): Promise<any> => {
            const res = await apiFetcher(`lists/uniqueName/${listName}?${params.toString()}`);
            return res.json as any;
        },
        refetchOnWindowFocus: false,
    });

    const changeParameters = (newLimit: number, newPage: number) => {
        setLimit(newLimit);
        setPage(newPage);
    };


    const changeOrderBy = (field: string, order: -1 | 0 | 1) => {
        setOrderBy(order === -1 ? "" : field);
        setSortOrder(order);
    }

    const getRuleFilter = () => {
        const currentParams = new URLSearchParams(location.search);
        const urlFilter = currentParams.get('filter');
        if (urlFilter) {
            const parseFilter = JSON.parse(urlFilter);
            const ruleGroup = columnFiltersToRuleGroup(parseFilter);
            if (ruleGroup.rules.length > 0) {
                return JSON.stringify(ruleGroup)
            }
        }

        return "";
    }

    return {
        ...query,
        orderBy,
        sortOrder,
        changeParameters,
        changeFilter,
        changeOrderBy,
        getRuleFilter
    };
};

export default useVehiclesQuery;