import React from 'react';

import { VehicleSupport } from '../../../../@types/vehicleSupport';
import CurrencyField from '../CurrencyField';
import NumberField from '../NumberField';
import TextField from '../TextField';

function SupportField({
	initialValues,
	values,
	name,
	number,
}: {
	initialValues?: VehicleSupport | undefined;
	name: string;
	number: number;
	values?: {
		vehiclesupports_0?: { onePercent?: string };
	};
}): JSX.Element {
	return (
		<div className="row align-items-center">
			{number === 1 && (
				<hr className="mb-0" style={{ borderColor: 'var(--bs-border-color)', opacity: '0.7' }} />
			)}
			<TextField type="hidden" name={`${name}.id`} initialValue={initialValues?.id} />
			<div className="col-12 col-md-1">
				<strong>Podpora {number}:</strong>
			</div>
			<div className="col-12 col-md-1">
				{name === 'vehiclesupports_0' && (
					<NumberField
						label="%"
						name={`${name}.onePercent`}
						initialValue={initialValues?.onePercent}
						shouldParseValue
					/>
				)}
			</div>
			<div className="col-12 col-md-2">
				<CurrencyField
					label="Dohad"
					name={`${name}.estimate`}
					initialValue={initialValues?.estimate}
					shouldParseValue
					disabled={
						name === 'vehiclesupports_0' &&
						values?.vehiclesupports_0?.onePercent !== undefined &&
						Number(values?.vehiclesupports_0?.onePercent) !== 0
					}
				/>
			</div>
			<div className="col-12 col-md-2">
				<CurrencyField
					label="Skutečnost"
					name={`${name}.real`}
					initialValue={initialValues?.real}
					shouldParseValue
				/>
			</div>
			<div className="col-12 col-md-3">
				<TextField
					label="Doklad"
					name={`${name}.documentNumber`}
					initialValue={initialValues?.documentNumber}
					shouldParseValue
				/>
			</div>
			<div className="col-12 col-md-3">
				<TextField
					label="Popis"
					name={`${name}.description`}
					initialValue={initialValues?.description}
					shouldParseValue
				/>
			</div>
			{number !== 4 && (
				<hr className="mb-0" style={{ borderColor: 'var(--bs-border-color)', opacity: '0.7' }} />
			)}
		</div>
	);
}

export default SupportField;
