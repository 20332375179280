import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';
import Error from '../Error';

function SelectField({
	disabled,
	initialValue,
	label,
	name,
	options,
	placeholder,
	validate,
	onChange,
	simple,
	shouldParseValue,
	allowEmpty = true,
}: FormFieldType & {
	initialValue?: string | number;
	options: Array<{ label: string; value: string | number | undefined }>;
	placeholder?: string;
	onChange?: React.ChangeEventHandler<HTMLSelectElement>;
	simple?: boolean;
	shouldParseValue?: boolean;
	allowEmpty?: boolean;
}): JSX.Element {
	const { Select } = Form;
	return (
		<ControlLayout name={name} label={label} simple={simple}>
			<Field
				name={name}
				initialValue={initialValue}
				validate={validate}
				{...(shouldParseValue && {
					parse: (value) => value,
				})}
			>
				{({ input, meta }) => (
					<>
						<Select
							{...input}
							id={name}
							disabled={disabled}
							onChange={(e) => {
								onChange?.(e);
								input.onChange(e);
							}}
							defaultValue={initialValue}
							isInvalid={meta.error}
						>
							{placeholder && (
								<option value="" disabled hidden>
									{placeholder}
								</option>
							)}
							{allowEmpty && <option value=""></option>}
							{options.map((option) => (
								<option value={option.value} key={option.value}>
									{option.label}
								</option>
							))}
						</Select>
						<Error {...meta} />
					</>
				)}
			</Field>
		</ControlLayout>
	);
}

export default SelectField;
