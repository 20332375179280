import styled from '@emotion/styled';

export const SFields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    
    > div {
        width: 20%;
        padding: 3px 6px;
    }
    
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        flex-direction: row;
        flex-wrap: nowrap;
        
        label {
            margin: 0;
            font-size: 12px;
        }
    }
`;

export const SForm = styled.form`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f8f9fa;
    border-radius: 6px;
    padding: 0 8px;
`;

export const SButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: end!important;
    flex-direction: row;
    
    .btn.btn-primary {
        width: 100%;
    }
`;