export const selectOptionsTypes = {
    date: 'date',
    text: 'text',
    textarea: 'textarea',
    number: 'number',
    boolean: 'boolean',
    select: 'select',
}
export const selectOptions = {
    equal: {label: "je rovno"},
    notEqual: {label: "není rovno"},
    lessThan: {label: "je menší než"},
    lessThanInclusive: {label: "je menší než nebo rovno"},
    greaterThan: {label: "je větší než"},
    greaterThanInclusive: {label: "je větší než nebo rovno"},
    contains: {label: "obsahuje"},
    in: {label: "obsahuje (více položek a oddělte čárkou)"},
    doesNotContains: {label: "neobsahuje"},
    isNull: {label: "není zadáno"},
    isNotNull: {label: "je zadáno"},
};

export const selectedOptionsByType = {
    "NUMBER": {
        equal: {type: selectOptionsTypes.number},
        notEqual: {type: selectOptionsTypes.number},
        lessThan: {type: selectOptionsTypes.number},
        lessThanInclusive: {type: selectOptionsTypes.number},
        greaterThan: {type: selectOptionsTypes.number},
        greaterThanInclusive: {type: selectOptionsTypes.number},
        contains: {type: selectOptionsTypes.number},
        doesNotContains: {type: selectOptionsTypes.number},
        isNull: {type: selectOptionsTypes.boolean},
        isNotNull: {type: selectOptionsTypes.boolean},
    },
    "DATE": {
        equal: {type: selectOptionsTypes.date},
        notEqual: {type: selectOptionsTypes.date},
        lessThan: {type: selectOptionsTypes.date},
        lessThanInclusive: {type: selectOptionsTypes.date},
        greaterThan: {type: selectOptionsTypes.date},
        greaterThanInclusive: {type: selectOptionsTypes.date},
        isNull: {type: selectOptionsTypes.boolean},
        isNotNull: {type: selectOptionsTypes.boolean},
    },
    "TEXT": {
        equal: {type: selectOptionsTypes.text},
        notEqual: {type: selectOptionsTypes.text},
        contains: {type: selectOptionsTypes.text},
        doesNotContains: {type: selectOptionsTypes.text},
        in: {type: selectOptionsTypes.textarea},
        isNull: {type: selectOptionsTypes.boolean},
        isNotNull: {type: selectOptionsTypes.boolean},
    },
    "BOOLEAN": {
        equal: {type: selectOptionsTypes.boolean},
    },
    "SELECT": {
        equal: {type: selectOptionsTypes.select},
        notEqual: {type: selectOptionsTypes.select},
        isNull: {type: selectOptionsTypes.boolean},
        isNotNull: {type: selectOptionsTypes.boolean},
    }
}

export const defaultHiddenColumns = ['Id'];

export const TYPE = 'type';
export const VALUE = 'value';
export const BMW_ALL = 'Bmw';
export const MOTO_ALL = 'Moto';
export const OTHERS_ALL = 'Other';
export const EXTERNAL = 'External';
export const UNSPECIFIED = 'Unspecified'
export const IMPORT_AUDIT = 'ImportAudit'