import { useMutation } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-final-form';

import BanksListForm from './BanksListForm';
import BasicListForm from './BasicListForm';
import EquipmentListForm from './EquipmentListForm';
import ProductionStatusListForm from './ProductionStatusListForm';
import RowColorsListForm from './RowColorsListForm';
import SalesPersonListForm from './SalesPersonListForm';
import VehicleModelsListForm from './VehicleModelsListForm';
import WarehouseListForm from './WarehouseListForm';
import {
	BankListItem,
	BasicListItem,
	EquipmentListItem,
	ProductionStatusListItem,
	RowColorListItem,
	SalesPersonListItem,
	VehicleModelListItem,
	WarehouseListItem,
	ListItem,
} from '../../@types/lists';
import authService from "../../components/Authorization/AuthorizationService";
import GlobalError from '../../components/form/GlobalError';
import { HTTPMethod } from '../../constants/rest';
import { FetcherError, apiFetcher } from '../../utils/fetcher';

function EditModal({
	onClose,
	onUpdate,
	initialValues,
	uniqueName,
	itemType,
	variant = 'edit',
}: {
	onUpdate: () => void;
	onClose: () => void;
	uniqueName: string;
	variant: 'add' | 'edit';
} & (
	| {
			itemType: 'basic';
			initialValues: BasicListItem | null;
	  }
	| {
			itemType: 'equipment';
			initialValues: EquipmentListItem | null;
	  }
	| { itemType: 'warehouse'; initialValues: WarehouseListItem | null }
	| { itemType: 'vehicleModel'; initialValues: VehicleModelListItem | null }
	| { itemType: 'rowColor'; initialValues: RowColorListItem | null }
	| { itemType: 'bank'; initialValues: BankListItem | null }
	| { itemType: 'productionStatus'; initialValues: ProductionStatusListItem | null }
	| { itemType: 'salesPerson'; initialValues: SalesPersonListItem | null }
	)): JSX.Element {
	const allowedRoles = ["Admin", "Manager"]
	const [isButtonVisible, setIsButtonVisible] = useState(false);

	useEffect(() => {
		isAuthorizeForAction();
	}, []);

	const isAuthorizeForAction = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
		setIsButtonVisible(isAuthorize);
	}

	const mutation = useMutation<ListItem, FetcherError>({
		// @ts-ignore
		mutationFn: (formData: any) => {
			// ts:ignore
			return variant === 'add'
				? apiFetcher(`configurations/${uniqueName}`, {
						method: HTTPMethod.POST,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(formData),
				  })
				: apiFetcher(`configurations/${uniqueName}`, {
						method: HTTPMethod.PUT,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ ...formData, id: initialValues?.id }),
				  });
		},
		onSuccess: () => {
			onUpdate();
		},
	});
	const renderForm = (setValue: (name: string, value: any) => void): JSX.Element | null => {
		switch (itemType) {
			case 'basic':
				return <BasicListForm initialValues={initialValues} variant={variant} />;
			case 'equipment':
				return <EquipmentListForm initialValues={initialValues} />;
			case 'warehouse':
				return <WarehouseListForm initialValues={initialValues} />;
			case 'vehicleModel':
				return <VehicleModelsListForm initialValues={initialValues} />;
			case 'rowColor':
				return <RowColorsListForm initialValues={initialValues} setValue={setValue} />;
			case 'bank':
				return <BanksListForm initialValues={initialValues} />;
			case 'salesPerson':
				return <SalesPersonListForm initialValues={initialValues} variant={variant} />;
			case 'productionStatus':
				return <ProductionStatusListForm initialValues={initialValues} variant={variant} />;
			default:
				return null;
		}
	};
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>{variant === 'add' ? 'Přidat položku' : 'Upravit položku'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{mutation.error && <GlobalError />}
				<Form
					onSubmit={(values) => {
						return mutation.mutate(values as any);
					}}
				>
					{({ form: { change }, handleSubmit, hasValidationErrors }) => (
						<>
							{renderForm(change)}
							{isButtonVisible && <Button
								variant="primary"
								type="submit"
								className="mt-2"
								onClick={handleSubmit}
								disabled={mutation.isLoading}
							>
								Uložit
							</Button>}
						</>
					)}
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default EditModal;
