import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import ModalLoan from './ModalLoan';
import useLoans from './useLoans';
import CreateButton from '../../../components/buttons/CreateButton';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { LOANS } from '../../../constants/detailViews';
import Navigation from '../Navigation';

function Loans(): JSX.Element {
	const { id } = useParams();
	const [showModal, setShowModal] = useState<'add' | 'edit' | undefined>(undefined);
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const { isLoading, loans, warehouses, refetch } = useLoans({
		id,
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 20% 20% 20% 40%;`,
		},
	]);
	const data = { nodes: loans };
	return (
		<Layout>
			<Navigation
				activeView={LOANS}
				id={id as string}
			/>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{showModal && (
						<ModalLoan
							variant={showModal}
							loan={selectedItem}
							onClose={() => setShowModal(undefined)}
							onUpdate={() => {
								setShowModal(undefined);
                                refetch();
							}}
							vehicleId={id as string}
							warehouses={warehouses}
						/>
					)}
					<CreateButton onClick={() => setShowModal('add')} label="Vytvořit zápůjčku" />
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>HOM</HeaderCell>
										<HeaderCell>Zapůjčeno</HeaderCell>
										<HeaderCell>Vráceno</HeaderCell>
										<HeaderCell>Poznámka</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item) => (
										<TableRow
											key={item.id}
											item={item}
											onDoubleClick={(item) => {
												setShowModal('edit');
												setSelectedItem(item);
											}}
											style={{ cursor: 'pointer' }}
										>
											<Cell>
												{warehouses.find(({ value }) => value === item.warehouseId)?.label}
											</Cell>
											<Cell>{format(new Date(item.loanedFrom), 'dd.MM.yyyy')}</Cell>
											<Cell>
												{item.loanedTo ? format(new Date(item.loanedTo), 'dd.MM.yyyy') : ''}
											</Cell>
											<Cell>{item.description}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</>
			)}
		</Layout>
	);
}
export default Loans;
