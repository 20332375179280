import React, { PropsWithChildren } from 'react';
import Form from 'react-bootstrap/Form';

function ControlLayout({
	label,
	name,
	children,
    simple,
}: PropsWithChildren<{
	label?: string;
	name?: string;
	simple?: boolean;
}>): JSX.Element {
	const { Label, Group } = Form;
	return (
		<Group className={simple ? "" : "mb-3"}>
			{label && <Label htmlFor={name}>{label}</Label>}
			{children}
		</Group>
	);
}

export default ControlLayout;
