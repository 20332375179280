import { AuthUser } from "../../@types/authUser";
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher, FetcherError } from "../../utils/fetcher";

export class AuthorizeService {

	async isAuthorizeForAction(requiredRole: string[]): Promise<boolean> {
		const user = await authService.getUser();
		if (user && typeof user !== "string") {
			for (let reqRole of requiredRole) {
				if (user.roles.map(role => role.name).includes(reqRole)) {
					return true;
				}
			}
			return false;
		}

		return false;
	}

	async isAuthenticated() : Promise<boolean | string> {
		const user = await this.getUser();
		if (user && typeof user === "string") {
			return user;
		}
		return !!user;
	}

	async getUser(): Promise<AuthUser | string | undefined> {
		const sessionStorageData = window.sessionStorage.getItem("AuthData");
		if (sessionStorageData) {
			return JSON.parse(sessionStorageData);
		}

		const user = await this.getUserFromService();
		if (user && typeof user !== "string") {
			if (this.validate(user)) {
				window.sessionStorage.setItem("AuthData", JSON.stringify(user));
			} else {
				return `Uživatel ${user.userName} namá žádné role. Prosím kontaktuje administrátora`;
			}
		}

		return user;
	}

	async getUserFromService(): Promise<AuthUser | string | undefined> {
		try {
			const response = await apiFetcher(`authentication`, {
				method: HTTPMethod.GET,
				body: "",
				headers: {
					'Content-Type': 'application/json',
				},
			});

			return response.json as AuthUser;
		} catch (ex) {
			const message = (ex as FetcherError).response.json as string;

			if (message) {
				const regex = /Username:([^ ]+), SID:([^' ]+)/;
				const result = message.match(regex);
				const resultMessage = result ? result[0] : '';
				return resultMessage;
			}
			return undefined;

		}
	}

	validate(user: AuthUser): boolean {
		return user.roles.length > 0;
	}
}

const authService = new AuthorizeService();
export default authService;