import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import { globalStyles } from './App.styled';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/Authorization/AuthorizeRoute';
// import { Home } from './components/Home';
import Layout from './components/Layout';

function App() {
	const queryClientRef = useRef<QueryClient>();
	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient();
	}

	return (
		<QueryClientProvider client={queryClientRef.current}>
			<Global styles={globalStyles} />
			<Routes>
				{AppRoutes.map((route, index) => {
					const { element, requireAuth, ...rest } = route;
					return (
						<Route key={index} {...rest} element={<AuthorizeRoute element={element} />} />
					);
				})}
			</Routes>
		</QueryClientProvider>
	);
}

export default App;