import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useMutation } from '@tanstack/react-query';
import { Palette } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ListItem, RowColorListItem } from '../../@types/lists';
import { LINE_COLOR } from '../../constants/lists';
import { HTTPMethod } from '../../constants/rest';
import useListQuery from '../../screens/Lists/useListQuery';
import { apiFetcher, FetcherError } from '../../utils/fetcher';
import authService from "../Authorization/AuthorizationService";
import GlobalError from '../form/GlobalError';
import Loader from '../Loader';

interface ColumnsColorsProps {
	ids: string[];
	onSubmit: () => void;
}

interface ColumnsColorsModalProps extends ColumnsColorsProps {
	show?: boolean;
	onHide: () => void;
}

function ColumnsColorsModal(props: ColumnsColorsModalProps): JSX.Element {
	const { show, onHide } = props;
	const [selectedColor, setSelectedColor] = useState<null | RowColorListItem>(null);

	const { isLoading, data: queryData } = useListQuery(LINE_COLOR);
	const data = {
		nodes: queryData,
	};

	const mutation = useMutation<ListItem, FetcherError>({
		// @ts-ignore
		mutationFn: () => {
			// ts:ignore
			apiFetcher(`vehicles/bulk`, {
				method: HTTPMethod.PUT,
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					ids: props.ids,
					name: 'LineColors_Id',
					value: selectedColor?.id,
				}),
			});
		},
		onSuccess: () => {
			props.onSubmit();
		},
	});

	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 25% 75%;`,
		},
	]);

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="lg"
			scrollable
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<strong>Označit výběr ({props.ids.length || 0})</strong>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{mutation.error && <GlobalError />}

				{selectedColor && <p className="text-center fs-5 fw-bold">Označit výběr jako:</p>}

				{isLoading ? (
					<Loader />
				) : (
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Barva</HeaderCell>
										<HeaderCell>Popis</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{(selectedColor ? [selectedColor] : tableList).map((item) => (
										<TableRow
											key={item.id}
											item={item}
											onClick={(item) => setSelectedColor(item)}
											style={{ cursor: 'pointer' }}
										>
											<Cell style={{ backgroundColor: item.colorHex }}>{item.colorHex}</Cell>
											<Cell style={{ backgroundColor: item.colorHex }}>{item.description}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				)}

				{selectedColor && (
					<p className="text-center">
						<Button variant="link" onClick={() => setSelectedColor(null)}>
							Zrušit výběr
						</Button>
					</p>
				)}
			</Modal.Body>
			<Modal.Footer>
				{selectedColor && (
					<Button onClick={() => mutation.mutate()} size="lg" disabled={mutation.isLoading}>
						Uložit
					</Button>
				)}
				<Button onClick={onHide} variant="secondary" size="lg">
					Zrušit
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

function ColumnsColors(props: ColumnsColorsProps): JSX.Element {
	const allowedRoles = ["Admin", "Manager"]

	const [modalShow, setModalShow] = useState(false);
	const [isButtonVisible, setIsButtonVisible] = useState(false);

	useEffect(() => {
		isAuthorizeForColoring();
	}, []);

	function onSubmit() {
		props.onSubmit();
		setModalShow(false);
	}

	const isAuthorizeForColoring = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
		setIsButtonVisible(isAuthorize);
	}

	return isButtonVisible ? (
		<>
			<OverlayTrigger
				placement="bottom"
				trigger={["hover", "focus"]}
				overlay={<Tooltip>Obarvit výběr</Tooltip>}
			>
				<Button variant="info" onClick={() => setModalShow(true)}>
					<Palette size={26} />
				</Button>
			</OverlayTrigger>

			<ColumnsColorsModal
				onSubmit={onSubmit}
				show={modalShow}
				onHide={() => setModalShow(false)}
				ids={props.ids}
			/>
		</>
	)
		: <div />;
}

export default ColumnsColors;
