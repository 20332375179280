
import {
    Settings,
    Info
} from 'lucide-react';
import React, { useState, useEffect } from 'react';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


import AuthData from './AuthData'
import authService from "./Authorization/AuthorizationService";
import { BMW_LIST, LISTS, MOTO_LIST, OTHER_LIST, EXTENRAL_STORAGE, ABOUT, SETTINGS, USER_MANAGEMENT, IMPORT_AUDIT } from '../constants/routes';

interface NavMenuProps {
    menu?: React.ReactNode;
    importMenu?: React.ReactNode;
    submenu?: React.ReactNode;
}

function NavMenu({ menu, importMenu, submenu }: NavMenuProps) {

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        isAuthorizeForAction();
    }, []);

    const isAuthorizeForAction = async () => {
        const isAuthorize = await authService.isAuthorizeForAction(["Admin"]);
        setIsAdmin(isAuthorize);
    }

    return (
        <header className="position-sticky fixed-top">
            <Navbar bg="primary" data-bs-theme="dark">
                <Container fluid>
                    <Nav className="me-auto">
                        <Navbar.Brand href={BMW_LIST}>BMW</Navbar.Brand>
                        <Navbar.Brand href={MOTO_LIST}>Motorky</Navbar.Brand>
                        <Navbar.Brand href={OTHER_LIST}>Ostatní</Navbar.Brand>
                        <Navbar.Brand href={EXTENRAL_STORAGE}>Cizí sklad</Navbar.Brand>
                        <Navbar.Brand href={IMPORT_AUDIT}>Audit</Navbar.Brand>
                    </Nav>
                    <Nav className="me">
                        <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="tooltip-lists">Nastavení</Tooltip>}
                        >
                            <NavDropdown title={<Settings />} id="basic-nav-dropdown">
                                <NavDropdown.Item href={LISTS}>Seznamy</NavDropdown.Item>
                                {isAdmin && <NavDropdown.Item href={USER_MANAGEMENT}>Správa uživatelů</NavDropdown.Item>}
                                {isAdmin && <NavDropdown.Item href={SETTINGS}>Obecné</NavDropdown.Item>}
                            </NavDropdown>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip-lists">O programu</Tooltip>}
                        >
                            <Nav.Link href={ABOUT}><Info /></Nav.Link>
                        </OverlayTrigger>
                        <AuthData />
                    </Nav>
                </Container>
            </Navbar>
            {menu && (
                <Navbar bg="white" data-bs-theme="light">
                    <Container fluid>
                        <Nav className="me-auto">
                            <ButtonToolbar aria-label="Toolbar with button groups">
                                {menu}
                            </ButtonToolbar>
                        </Nav>
                        <Nav className="me">
                            <ButtonToolbar aria-label="Toolbar with import info">
                                {importMenu}
                            </ButtonToolbar>
                        </Nav>
                    </Container>
                </Navbar>
            )}
            {submenu && (
                <Navbar bg="white" data-bs-theme="light" className="pt-0">
                    <Container fluid>
                        <Nav className="me-auto">
                            <ButtonToolbar aria-label="Toolbar with button groups">
                                {submenu}
                            </ButtonToolbar>
                        </Nav>
                    </Container>
                </Navbar>
            )}
        </header>
    );
}

export default NavMenu;
