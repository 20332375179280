import { PlusIcon } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import authService from "../Authorization/AuthorizationService";

function CreateButton(props: { onClick: () => void; label: string }): JSX.Element {
	const allowedRoles = ["Admin", "Manager"]
	const [isButtonVisible, setIsButtonVisible] = useState(false);

	useEffect(() => {
		isAuthorizeForAction();
	}, []);

	const isAuthorizeForAction = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
		setIsButtonVisible(isAuthorize);
	}

	return isButtonVisible ? (
		<Button
			variant="outline-success"
			onClick={props.onClick}
			className="d-flex align-items-center gap-2 mb-3"
		>
			<PlusIcon />
			<span>{props.label}</span>
		</Button>
	) : <div />;
}

export default CreateButton;
