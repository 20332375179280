import { useQuery } from '@tanstack/react-query';

import {
	BankListItem,
	BasicListItem,
	EquipmentListItem,
	ProductionStatusListItem,
	RowColorListItem,
	SalesPersonListItem,
	VehicleModelListItem,
	WarehouseListItem,
} from '../../@types/lists';
import { LIST_DATA } from '../../constants/queryKeys';
import { apiFetcher } from '../../utils/fetcher';

type QueryDataType =
	| BasicListItem[]
	| BankListItem[]
	| EquipmentListItem[]
	| ProductionStatusListItem[]
	| RowColorListItem[]
	| SalesPersonListItem[]
	| VehicleModelListItem[]
	| WarehouseListItem[];

const useListQuery = (listName: string, vehicleId?: string, typeName?: string) => {
	const query = useQuery<QueryDataType, Error>({
		queryKey: [LIST_DATA, { listName, vehicleId }],
		queryFn: async (): Promise<QueryDataType> => {
			const res = await apiFetcher(
				vehicleId
					? typeName
						? `configurations/uniqueName/${listName}?vehicleId=${vehicleId}&typeStr=${typeName}`
						: `configurations/uniqueName/${listName}?vehicleId=${vehicleId}`
					: typeName
						? `configurations/uniqueName/${listName}?typeStr=${typeName}`
						: `configurations/uniqueName/${listName}`
			);

			return res.json as QueryDataType;
		},
		refetchOnWindowFocus: false,
	});
	return query;
};

export default useListQuery;
