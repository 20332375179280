export const priceToNumber = (price: number): number => price * 100;

export const numberToPrice = (number: number): number => number / 100;

export const calculateBonus = ({
	bonus,
	transportPrice,
	realPrice,
}: {
	bonus: number;
	transportPrice: number;
	realPrice: number;
}): string | undefined => {
	return bonus && transportPrice && realPrice
		? (
				(priceToNumber(bonus) * 100) /
				(priceToNumber(realPrice) + priceToNumber(bonus) - priceToNumber(transportPrice))
		  ).toFixed(2)
		: undefined;
};
