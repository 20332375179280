import {QueryObserverResult} from '@tanstack/react-query';
import React, {useState} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import BanksListTable from './BanksListTable';
import BasicListTable from './BasicListTable';
import EquipmentListTable from './EquipmentListTable';
import ProductionStatusListTable from './ProductionStatusListTable';
import RowColorsListTable from './RowColorsListTable';
import SalesPersonListTable from './SalesPersonListTable';
import SideNavigation from './SideNavigation';
import useListQuery from './useListQuery';
import VehicleModelsListTable from './VehicleModelsListTable';
import WarehousesListTable from './WarehouseListTable';
import {
    BankListItem,
    BasicListItem,
    EquipmentListItem,
    ProductionStatusListItem,
    RowColorListItem,
    SalesPersonListItem,
    VehicleModelListItem,
    WarehouseListItem,
} from '../../@types/lists';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import QueryError from '../../components/QueryError';
import {
    BANK,
    BRAND,
    EQUIPMENT_LISTS,
    LINE_COLOR,
    PRODUCTION_STATUS,
    SALES_PERSON,
    VEHICLE_MODEL,
    WAREHOUSE,
} from '../../constants/lists';

function Lists(): JSX.Element {
    const [listName, setListName] = useState<string>(BRAND);
    const {isLoading, error, data: queryData, refetch} = useListQuery(listName);
    const {data: brands} = useListQuery(BRAND);
    const data = {
        nodes: queryData,
    };
    return (
        <Layout>
            <Row>
                <SideNavigation
                    activeListName={listName}
                    onChange={(selectedListName: string) => setListName(selectedListName)}
                />
                {isLoading ? (
                    <Col>
                        <Loader/>
                    </Col>
                ) : error ? (
                    <Col>
                        <QueryError onClick={refetch}/>
                    </Col>
                ) : EQUIPMENT_LISTS.includes(listName) ? (
                    <EquipmentListTable
                        data={data as { nodes: EquipmentListItem[] }}
                        brands={brands as BasicListItem[]}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<EquipmentListItem, Error>>}
                    />
                ) : listName === BANK ? (
                    <BanksListTable
                        data={data as { nodes: BankListItem[] }}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<BankListItem, Error>>}
                    />
                ) : listName === PRODUCTION_STATUS ? (
                    <ProductionStatusListTable
                        data={data as { nodes: ProductionStatusListItem[] }}
                        brands={brands as BasicListItem[]}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<ProductionStatusListItem, Error>>}
                    />
                ) : listName === LINE_COLOR ? (
                    <RowColorsListTable
                        data={data as { nodes: RowColorListItem[] }}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<RowColorListItem, Error>>}
                    />
                ) : listName === SALES_PERSON ? (
                    <SalesPersonListTable
                        data={data as { nodes: SalesPersonListItem[] }}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<SalesPersonListItem, Error>>}
                    />
                ) : listName === VEHICLE_MODEL ? (
                    <VehicleModelsListTable
                        data={data as { nodes: VehicleModelListItem[] }}
                        brands={brands as BasicListItem[]}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<VehicleModelListItem, Error>>}
                    />
                ) : listName === WAREHOUSE ? (
                    <WarehousesListTable
                        data={data as { nodes: WarehouseListItem[] }}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<WarehouseListItem, Error>>}
                    />
                ) : (
                    <BasicListTable
                        data={data as { nodes: BasicListItem[] }}
                        uniqueName={listName}
                        refetch={refetch as () => Promise<QueryObserverResult<BasicListItem, Error>>}
                    />
                )}
            </Row>
        </Layout>
    );
}

export default Lists;
