import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader(): JSX.Element {
	return (
		<div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
}

export default Loader;
