import { delay } from "lodash";
import { useState, useEffect } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { ImportStatus } from "../../@types/importStatus";
import ImportButton from '../ImportButton';
import ImportInfo from '../ImportInfo';

interface ImportProps {
    type: string
}

function Import(props: ImportProps): JSX.Element {

    const [refresh, setRefresh] = useState<boolean>(false);
    const [status, setStatus] = useState<ImportStatus>();

    useEffect(() => {
        if (status?.status === "InProgress") {
            autoRefresh();
        }
    });

    const autoRefresh = async () => {
        await setTimeout(() => {
            setRefresh(true)
        }, 30000);
    }

    return (
        <>
            <ButtonGroup className="me-2">
                <ImportInfo
                    type={props.type} setStatus={setStatus} status={status} shouldRefresh={refresh}
                />
                <ImportButton
                    type={props.type} setRefresh={setRefresh} status={status?.status}
                />
            </ButtonGroup>
        </>
    )

}

export default Import;