import styled from '@emotion/styled';

export const SFlex = styled.div<{
	justify?: 'center' | 'end' | 'start';
	align?: 'center' | 'end' | 'start';
}>`
	display: flex;
	align-items: ${({ align }) => align || 'end;'};
	justify-content: ${({ justify }) => justify || 'space-between;'};
`;
