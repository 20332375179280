import styled from '@emotion/styled';

import { size } from '../../styles/spacing';

const getPaddingValue = (paddingValues: (string | number)[]) => {
	const paddings = paddingValues.map((value) => {
		if (typeof value === 'number') {
			return size(value);
		}
		return value;
	});

	return paddings.join(' ');
};

type SContentProps = {
	innerSpacing?: number | number[];
};

export const SContent = styled.div<SContentProps>`
	width: 100%;
	padding: ${(props) => {
		if (props.innerSpacing) {
			if (Array.isArray(props.innerSpacing)) {
				return getPaddingValue(props.innerSpacing);
			} else {
				return size(props.innerSpacing);
			}
		}
		return size(5);
	}};
`;
