import { useQuery } from '@tanstack/react-query';

import { BasicListItem } from '../../../@types/lists';
import { VehicleDetail } from '../../../@types/vehicleDetail';
import { VehicleReward } from '../../../@types/vehicleReward';
import { VehicleSupport } from '../../../@types/vehicleSupport';
import { CATEGORY, LEASING_COMPANIES, SALES_PERSONAL_REWARDS } from '../../../constants/lists';
import { apiFetcher } from '../../../utils/fetcher';
import { mapBasicListToOptions } from '../../../utils/lists';
import useListQuery from '../../Lists/useListQuery';
import useType from '../useType';

const useBonuses = ({ id }: { id?: string }) => {
	const { id: typeID } = useType();
	const categoriesQuery = useListQuery(CATEGORY, id);
	const leasingCompaniesQuery = useListQuery(LEASING_COMPANIES, id);
	const rewardsQuery = useListQuery(SALES_PERSONAL_REWARDS, id);
	const vehicleRewardsQuery = useQuery({
		queryKey: ['vehicleRewards', { id }],
		queryFn: async (): Promise<VehicleReward[]> => {
			const res = await apiFetcher(`vehicles/${id}/rewards`);

			return res.json as VehicleReward[];
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const vehicleSupportsQuery = useQuery({
		queryKey: ['vehicleSupports', { id }],
		queryFn: async (): Promise<VehicleSupport[]> => {
			const res = await apiFetcher(`vehicles/${id}/supports`);

			return res.json as VehicleSupport[];
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const detailQuery = useQuery<VehicleDetail, Error>({
		queryKey: ['vehicleDetail', { id }],
		queryFn: async (): Promise<VehicleDetail> => {
			const res = await apiFetcher(`vehicles/${id}?type=${typeID}`);

			return res.json as VehicleDetail;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const isLoading = [
		categoriesQuery.isLoading,
		leasingCompaniesQuery.isLoading,
		rewardsQuery.isLoading,
		vehicleRewardsQuery.isLoading,
		vehicleSupportsQuery.isLoading,
		detailQuery.isLoading,
	].some((item) => item === true);
	return {
		isLoading,
		categories: mapBasicListToOptions(categoriesQuery.data as BasicListItem[]),
		leasingCompanies: mapBasicListToOptions(leasingCompaniesQuery.data as BasicListItem[]),
		rewards: mapBasicListToOptions(rewardsQuery.data as BasicListItem[]),
		vehicleRewards: vehicleRewardsQuery.data,
		vehicleSupports: vehicleSupportsQuery.data,
		vehicleDetail: detailQuery.data,
		vehicleDetailRefetch: () => {
			detailQuery.refetch();
			vehicleRewardsQuery.refetch();
			vehicleSupportsQuery.refetch();
		},
	};
};

export default useBonuses;
