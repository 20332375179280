import { useQuery } from '@tanstack/react-query';

import { USERVIEW_DATA} from '../constants/queryKeys';
import { apiFetcher } from '../utils/fetcher';

const useUserViewQuery = (structureName: string) => {
	const query = useQuery<any, Error>({
		queryKey: [USERVIEW_DATA, { structureName }],
		queryFn: async (): Promise<any> => {
			const res = await apiFetcher(`userview/list/${structureName}`);

			return res.json as any;
		},
		refetchOnWindowFocus: false,
	});
	return query;
};

export default useUserViewQuery;
