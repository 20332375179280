import { useMutation } from "@tanstack/react-query";
import React, { Component } from 'react';
import Button from 'react-bootstrap/esm/Button';

import Loader from '../../components/Loader';
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher, FetcherError } from "../../utils/fetcher";

import './initPage.css';

interface InitPageProps {
	message: string | undefined
	loading: boolean
}

function InitPage(props: InitPageProps): JSX.Element {

	const mutation = useMutation<string, FetcherError>({
		// @ts-ignore
		mutationFn: async () => {
			// ts:ignore

			const response = await apiFetcher('authentication/data', {
				method: HTTPMethod.GET,
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.status === 200) {
				alert(response.json);
			} else {
				alert("Error při komuniaci se serverem.");
			}

		},
		onSuccess: () => {
			
		},
		onError: () => {
			
		}
	});

	return props.loading ? (
		<div className="App">
			<header className="App-header">
				<p>Probíhá přihlašování do systému CRV</p>

				<Loader />
			</header>
		</div>
	) : (
		<div className="App">
			<header className="App-header">
				<p>Uživatel není authorizován pro systém CRV</p>
				<p>
					Pro možnost přihlášení, nahlašte Vašemu Administrátorovi tyto údaje
					<br />
						{!!props.message
							? props.message
							: <Button variant="light" onClick={() => mutation.mutate()}>
								Zjistit údaje
							</Button>}
				</p>
			</header>

		</div>
	);
}

export default InitPage;
