import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";

import { ImportStatus } from "../../@types/importStatus";
import { HTTPMethod } from "../../constants/rest";
import refresh from '../../icons/refresh.svg';
import { apiFetcher } from "../../utils/fetcher";

interface ImportInfoProps {
    type: string,
    setStatus: (value: ImportStatus) => void,
    status: ImportStatus | undefined,
    shouldRefresh: boolean | undefined
}

function ImportInfo(props: ImportInfoProps): JSX.Element {

    useEffect(() => {
        getState();
    }, [props.shouldRefresh]);

    const getInProgressMessage = () => {
        return props.status?.startedAt
            ? `Probíhá import z ${new Date(props.status?.startedAt).toLocaleDateString()}`
            : `Naposled spuštěný import stále běží.`
    }

    const getFailedMessage = () => {
        return props.status?.startedAt
            ? `Import z ${new Date(props.status?.startedAt).toLocaleDateString()} proběhl s chybou.`
            : `Poslední import proběhl s chybou.`
    }

    const getState = async () => {
        const response = await apiFetcher(`import/${props.type}`, {
            method: HTTPMethod.GET,
            body: "",
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            props.setStatus(response.json as ImportStatus);
        }
    }

    return (
        <>
            <ButtonGroup className="me-2">
                <Button variant={props.status?.status === "InProgress" ? "info" : props.status?.status === "Failed" ? "danger" : "success"} disabled>
                    {props.status?.details
                        ? props.status?.details
                        : props.status?.status === "InProgress"
                            ? getInProgressMessage()
                            : getFailedMessage()
                    }
                </Button>
                {props.status && props.status.status == "InProgress" && (
                    <OverlayTrigger
                        placement="bottom"
                        trigger={['hover', 'focus']}
                        overlay={<Tooltip>Obnovit</Tooltip>}
                    >
                        <Button variant="light" onClick={() => getState()}>
                            <img style={{ height: '1.5em' }} src={refresh} alt="Obnovit" />
                        </Button>
                    </OverlayTrigger>
                )}
            </ButtonGroup>
        </>
    )

}

export default ImportInfo;