import { useParams } from 'react-router-dom';

import Navigation from './Navigation';
import Detail from './views/Detail';
import Layout from '../../components/Layout';
import {
	DETAIL,
} from '../../constants/detailViews';

function BMWDetail(): JSX.Element {
	const { id } = useParams();
	return (
		<Layout>
			<Navigation
				activeView={DETAIL}
				isCreating={id === undefined}
				id={id as string}
			/>
			<Detail />
		</Layout>
	);
}

export default BMWDetail;
