import { noop } from 'lodash';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-final-form';

import { About } from '../../@types/about';
import TextField from '../../components/form/fields/TextField';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { HTTPMethod } from "../../constants/rest";
import { apiFetcher } from "../../utils/fetcher";

interface AboutPageProps {

}

function AboutPage(props: AboutPageProps): JSX.Element {

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<About>();

	useEffect(() => {
		getState();
	}, []);

	const getState = async () => {
		const response = await apiFetcher('about', {
			method: HTTPMethod.GET,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (response.status === 200) {
			setData(response.json as About);
			setIsLoading(false);
		}
	}

	return isLoading ? (
			<Layout>
				<Loader />
			</Layout>
		) : (
			<Layout>
				<Form onSubmit={noop}
					render={() => (
						<form>
							<Col md={{ span: 6, offset: 3 }}>
								<TextField
									disabled={true}
									label="Verze"
									name="Version"
									initialValue={data?.version}
									/>
								<TextField
									disabled={true}
									label="Dodavatel"
									name="Supplier"
									initialValue={data?.data.supplier}
								/>
								<TextField
									disabled={true}
									label="Adresa"
									name="Address"
									initialValue={data?.data.address}
								/>
								<TextField
									disabled={true}
									label="Email"
									name="Email"
									initialValue={data?.data.email}
								/>
								<TextField
									disabled={true}
									label="Web"
									name="Web"
									initialValue={data?.data.www}
								/>
								<TextField
									disabled={true}
									label="Telefon"
									name="Phone"
									initialValue={data?.data.phone}
								/>
								<TextField
									disabled={true}
									label="IČO"
									name="ice"
									initialValue={data?.data.ico}
								/>
								<TextField
									disabled={true}
									label="DIČ"
									name="dic"
									initialValue={data?.data.dic}
									/>
							</Col>
						</form>
					)}
				/>
			</Layout>
		);
}

export default AboutPage;
