import { useMutation } from '@tanstack/react-query';
import { Sheet, Trash2, ExternalLink, Ban } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from 'react-router-dom';

import useType from './useType';
import { DeleteType } from '../../@types/deleteType';
import authService from '../../components/Authorization/AuthorizationService';
import Section from '../../components/Section';
import {
	BONUSES,
	DETAIL,
	DOCUMENTS,
	INTERS,
	LOANS,
	SPECIFICATION,
	AUDIT,
} from '../../constants/detailViews';
import { HTTPMethod } from '../../constants/rest';
import { BMW_LIST, MOTO_LIST, OTHER_LIST } from '../../constants/routes';
import { FetcherError, apiFetcher } from '../../utils/fetcher';

function Navigation({
	activeView,
	isCreating,
	id,
}: {
	activeView: string;
	isCreating?: boolean;
	id: string;
}): JSX.Element {
	const allowedRolesForDelete = ['Admin'];
	const allowedRolesForStorno = ['Admin', 'Manager'];
	const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState<boolean>(false);
	const [isStornoButtonVisible, setIsStornoButtonVisible] = useState<boolean>(false);
	const type = useType();
	const listRoute = type.id  === 0 ? BMW_LIST : type.id === 1 ? MOTO_LIST : OTHER_LIST;
	const { Item } = ListGroup;
	const navigate = useNavigate();
	const mutation = useMutation({
		// @ts-ignore
		mutationFn: (deleteType: DeleteType) => {
			return apiFetcher(`vehicles/${id}?deleteType=${deleteType}`, {
				method: HTTPMethod.DELETE,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		},
		onSuccess: () => {
			navigate(listRoute, { state: { hasDeleted: true }, replace: true });
		},
	});

	useEffect(() => {
		isAuthorizeForAction();
	}, []);

	const isAuthorizeForAction = async () => {
		const isAuthorizeForDelete = await authService.isAuthorizeForAction(allowedRolesForDelete);
		const isAuthorizeForStorno = await authService.isAuthorizeForAction(allowedRolesForStorno);
		setIsDeleteButtonVisible(isAuthorizeForDelete);
		setIsStornoButtonVisible(isAuthorizeForStorno);
	};


	return (
		<Section>
			<ListGroup horizontal className="mb-3">
				<Item href={`/${type.name}/${id}/detail`} active={activeView === DETAIL} action>
					Detail
				</Item>
				<Item
					href={`/${type.name}/${id}/specification`}
					active={activeView === SPECIFICATION}
					action
					disabled={isCreating}
				>
					Specifikace
				</Item>
				<Item
					href={`/${type.name}/${id}/bonuses`}
					active={activeView === BONUSES}
					action
					disabled={isCreating}
				>
					Bonusy
				</Item>
				<Item href={`/${type.name}/${id}/loans`} active={activeView === LOANS} action disabled={isCreating}>
					Zápůjčky
				</Item>
				<Item href={`/${type.name}/${id}/audits`} active={activeView === AUDIT} action disabled={isCreating}>
					Audit
				</Item>

				<Item
					href={`/${type.name}/${id}/documents`}
					active={activeView === DOCUMENTS}
					action
					disabled={isCreating}
				>
					Dokumenty
				</Item>
				<Item
					className="pr-0"
					href={`/${type.name}/${id}/inters`}
					active={activeView === INTERS}
					action
					disabled={isCreating}
				>
					Interky
				</Item>

				<Item style={{ minWidth: '230px' }} className="d-flex gap-2 justify-content-end">
					{!isCreating && isStornoButtonVisible && (
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Storno</Tooltip>}
						>
							<Button
								variant="danger"
								onClick={() => {
									if (window.confirm('Opravdu chcete stronovat kartu?')) {
										mutation.mutate(DeleteType.Storno);
									}
								}}
							>
								<Ban size={18} />
							</Button>
						</OverlayTrigger>
					)}
					{!isCreating && isDeleteButtonVisible && (
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Smazat kartu</Tooltip>}
						>
							<Button
								variant="danger"
								onClick={() => {
									if (window.confirm('Opravdu chcete smazat kartu?')) {
										mutation.mutate(DeleteType.Delete);
									}
								}}
							>
								<Trash2 size={18} />
							</Button>
						</OverlayTrigger>
					)}
					<OverlayTrigger
						placement="bottom"
						trigger={['hover', 'focus']}
						overlay={<Tooltip>Zpět na přehled</Tooltip>}
					>
						<Button
							variant="light"
							href={`${listRoute}${
								sessionStorage.getItem('listSearchParamsRedirect')
									? sessionStorage.getItem('listSearchParamsRedirect')
									: ''
							}`}
							onClick={() => sessionStorage.removeItem('listSearchParamsRedirect')}
						>
							<Sheet size={18} />
						</Button>
					</OverlayTrigger>
					{!isCreating && (
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Otevřit v nové záložce</Tooltip>}
						>
							<Button variant="light" href={window.location.href} target="_blank">
								<ExternalLink size={18} />
							</Button>
						</OverlayTrigger>
					)}
				</Item>
			</ListGroup>
		</Section>
	);
}

export default Navigation;
