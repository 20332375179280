import React from 'react';
import { Field } from 'react-final-form';
import { NumericFormat } from 'react-number-format';

import { FormFieldType } from '../../../../@types/formField';
import ControlLayout from '../ControlLayout';
import Error from '../Error';

function CurrencyField({
	disabled,
	initialValue,
	label,
	name,
	onBlur,
	placeholder = '0,00',
	validate,
	shouldParseValue,
}: FormFieldType & {
	initialValue?: string | number;
	onBlur?: () => void;
	placeholder?: string;
	shouldParseValue?: boolean;
}): JSX.Element {
	return (
		<ControlLayout name={name} label={label}>
			<Field
				name={name}
				validate={validate}
				initialValue={initialValue}
				{...(shouldParseValue && {
					parse: (value) => value ?? null,
				})}
			>
				{({ input, meta }) => (
					<>
						<NumericFormat
							thousandSeparator=" "
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							defaultValue={initialValue}
							placeholder={placeholder}
							type="text"
							name={name}
							autoComplete="off"
							onBlur={(e) => {
								input.onBlur?.(e);
								onBlur?.();
							}}
							onFocus={input.onFocus}
							onValueChange={({ floatValue }) => input.onChange(floatValue)}
							readOnly={disabled}
							disabled={disabled}
							className="form-control"
							value={input.value}
						/>
						<Error {...meta} />
					</>
				)}
			</Field>
		</ControlLayout>
	);
}

export default CurrencyField;
