import { useMutation } from '@tanstack/react-query';
import { Archive, Trash2, Ban } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import { DeleteType } from '../../@types/deleteType';
import { ListItem } from '../../@types/lists';
import { HTTPMethod } from '../../constants/rest';
import { apiFetcher, FetcherError } from '../../utils/fetcher';
import authService from "../Authorization/AuthorizationService";
import GlobalError from '../form/GlobalError';

interface ColumnsDeleteProps {
	ids: string[];
	onSubmit: () => void;
	enabled: boolean
}

interface ColumnsDeleteModalProps extends ColumnsDeleteProps {
	show?: boolean;
	onHide: () => void;
	deleteType: DeleteType;
}

function ColumnsDeleteModal(props: ColumnsDeleteModalProps): JSX.Element {
	const { deleteType, show, onHide } = props;

	const params = new URLSearchParams();

	params.append('ids', props.ids.toString());
	params.append('deleteType', deleteType.toString());

	const mutation = useMutation<ListItem, FetcherError>({
		// @ts-ignore
		mutationFn: () => {
			// ts:ignore
			apiFetcher(`vehicles/bulk?${params.toString()}`, {
				method: HTTPMethod.DELETE,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		},
		onSuccess: () => {
			props.onSubmit();
		},
	});

	const getText = (deleteType: DeleteType, isLower: boolean) => {
		switch (deleteType) {
			case DeleteType.Archive:
				return isLower ? "archivovat" : "Archivovat";
			case DeleteType.Storno:
				return isLower ? "stornovat" : "Stornovat";
			case DeleteType.Delete:
				return isLower ? "trvale smazat" : "Trvale smazat";
		}
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			size="lg"
			scrollable
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<strong>
						{getText(deleteType, false)} výběr ({props.ids.length || 0})
					</strong>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{mutation.error && <GlobalError />}

				<p className="text-center fs-5 fw-bold">
					Opravdu chcete výběr {getText(deleteType, true)}?
				</p>
				<Row className="align-items-center justify-content-center">
					<Col className="col-auto">
						<Button
							onClick={() => mutation.mutate()}
							size="lg"
							disabled={mutation.isLoading}
							variant="danger"
						>
							{getText(deleteType, false)}
						</Button>
					</Col>
					<Col className="col-auto">
						<Button onClick={onHide} variant="secondary" size="lg">
							Zrušit
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
}

function ColumnsDelete(props: ColumnsDeleteProps): JSX.Element {
	const allowedRolesForDeletion = ["Admin"]
	const allowedRolesForStorno = ["Admin", "Manager", "Contributor"]
	const allowedRolesForArchive = ["Admin", "Manager"]

	const [modalShow, setModalShow] = useState(false);
	const [deleteType, setDeleteType] = useState<DeleteType>();
	const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
	const [isArchiveButtonVisible, setIsArchiveButtonVisible] = useState(false);
	const [isStornoButtonVisible, setIsStornoButtonVisible] = useState(false);

	useEffect(() => {
		isAuthorizeForDeletion();
		isAuthorizeForStorno();
		isAuthorizeForArchive();
	}, []);

	function onSubmit() {
		props.onSubmit();
		setModalShow(false);
	}

	const isAuthorizeForDeletion = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRolesForDeletion);
		setIsDeleteButtonVisible(isAuthorize && props.enabled);
	}

	const isAuthorizeForStorno = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRolesForStorno);
		setIsStornoButtonVisible(isAuthorize && props.enabled);
	}

	const isAuthorizeForArchive = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRolesForArchive);
		setIsArchiveButtonVisible(isAuthorize && props.enabled);
	}

	return (
		<>
			{isArchiveButtonVisible && (<OverlayTrigger
				placement="bottom"
				trigger={["hover", "focus"]}
				overlay={<Tooltip>Archivovat výběr</Tooltip>}
			>
				<Button
					variant="info"
					onClick={() => {
						setModalShow(true);
						setDeleteType(DeleteType.Archive);
					}}
				>
					<Archive size={26} />
				</Button>
			</OverlayTrigger>)}
			{isStornoButtonVisible && (<OverlayTrigger
				placement="bottom"
				trigger={["hover", "focus"]}
				overlay={<Tooltip>Storno</Tooltip>}
			>
				<Button
					variant="info"
					onClick={() => {
						setModalShow(true);
						setDeleteType(DeleteType.Storno);
					}}
				>
					<Ban size={26} />
				</Button>
			</OverlayTrigger>)}
			{isDeleteButtonVisible && (<OverlayTrigger
				placement="bottom"
				trigger={["hover", "focus"]}
				overlay={<Tooltip>Smazat výběr</Tooltip>}
			>
				<Button
					variant="danger"
					onClick={() => {
						setModalShow(true);
						setDeleteType(DeleteType.Delete);
					}}
				>
					<Trash2 size={26} />
				</Button>
			</OverlayTrigger>)}

			<ColumnsDeleteModal
				deleteType={!!deleteType ? deleteType : DeleteType.Archive }
				onSubmit={onSubmit}
				show={modalShow}
				onHide={() => setModalShow(false)}
				ids={props.ids}
				enabled={props.enabled}
			/>
		</>
	);
}

export default ColumnsDelete;
