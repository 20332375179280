import React from 'react';

import ListTable from "../../components/ListTable";
import { UNSPECIFIED, IMPORT_AUDIT } from "../../constants/vehicleFilters";

function ImportAudit(): JSX.Element {
    return (
        <ListTable type={UNSPECIFIED} uniqueName={IMPORT_AUDIT} title="Audit" config={{ createButtonEnabled: false, deleteButtonEnabled: false, stornoButtonEnabled: false, importEnabled: false, detailEnabled: true }} />
    );
}

export default ImportAudit;
