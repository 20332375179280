import { ClipboardCopy, XIcon } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Form } from 'react-final-form';

import { SButtons, SFields, SForm } from './TableLightFilter.styled';
import { Column, Filter } from '../../@types/vehicleFilters';
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { useClipboard } from '../../hooks/useClipboard';
import { renderTranslation } from '../ColumnFilter';

interface TableLightFilterProps {
	columns: Column[];
	filters: { [key: string]: Filter[] };
	onSubmit: (filters: { [key: string]: Filter[] }) => void;
}

type AnyObject = Record<string, any>;

function removeUndefinedProps(obj: AnyObject): AnyObject {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const value = obj[key];

			if (value === undefined || (Array.isArray(value) && value.length === 0)) {
				delete obj[key];
			} else if (typeof value === 'object' && value !== null) {
				removeUndefinedProps(value);
			}
		}
	}
	return obj;
}

const TableLightFilter = (props: TableLightFilterProps): JSX.Element => {
	const { filters, columns } = props;
	const [initialValues, setInitialValues] = useState({});

	const formRef = useRef();

	const selectedColumns = columns.slice(0, 10);
	const [clipboardContent, handleClipboardClick] = useClipboard();

	useEffect(() => {
		let newInitialValues = {};
		selectedColumns.forEach((column) => {
			if (column.hasPredifenedFilter) {
				//@ts-expect-error
				newInitialValues[column.name] = filters[column.name]?.find((c) => c.type === 'equal')
					?.value;
			} else {
				//@ts-expect-error
				newInitialValues[column.name] = filters[column.name]?.find((c) => c.type === 'in')?.value;
			}
		});
		setInitialValues(newInitialValues);
		//@ts-expect-error
		formRef.current.reset();
	}, [filters.toString(), columns.toString()]);

	const handleReset = () => {
		let newInitialValues = {};
		selectedColumns.forEach((column) => {
			if (column.hasPredifenedFilter) {
				//@ts-expect-error
				newInitialValues[column.name] = undefined;
			}
		});
		setInitialValues(newInitialValues);

		//@ts-expect-error
		formRef.current.reset();

		setTimeout(() => {
			//@ts-expect-error
			formRef.current.submit();
		}, 100);
	};

	// @ts-expect-error
	function handleCopy(n) {
		handleClipboardClick();
		if (clipboardContent) {
			const values = clipboardContent
				.replace(/[\r\n]/gm, '\n')
				.split('\n')
				.filter(Boolean);

			const initialValuesCurrent = initialValues;

			if (n === 'Vehicles_VIN') {
				const changedValues = values.map((value) => value.slice(-7));
				setInitialValues({
					...initialValuesCurrent,
					[n]: changedValues.join(',').toString(),
				});
			} else {
				setInitialValues({
					...initialValuesCurrent,
					[n]: values.join(',').toString(),
				});
			}
		}
	}

	return (
		<Form
			initialValues={initialValues}
			onSubmit={(formObj) => {
				const filtersKeys = Object.keys(filters);

				let resetFilters = filters;
				filtersKeys.forEach((propertyName) => {
					if (columns.find((item) => item.name === propertyName)) {
						// @ts-expect-error
						resetFilters[propertyName] = undefined;
					}
				});
				const transformed = Object.entries(formObj).reduce(
					// @ts-expect-error
					(accumulator, [key, value]) => {
						return {
							...accumulator,
							[key]: value
								? [
										{
											type: columns.find((item) => item.name === key)?.hasPredifenedFilter
												? 'equal'
												: 'in',
											value: value,
										},
								  ]
								: undefined,
						};
					},
					{} as Record<string, Array<{ type: string; value: string }>>
				);

				const newFilters = {
					...filters,
					...resetFilters,
					...transformed,
				};

				props.onSubmit(removeUndefinedProps(newFilters));
			}}
			render={({ handleSubmit, form }) => {
				//@ts-expect-error
				formRef.current = form;

				return (
					<SForm
						onSubmit={handleSubmit}
						className="d-flex justify-content-center align-items-center flex-column"
					>
						<SFields>
							{columns.map((column) => {
								if (!column.isInOverheadFilter) {
									return null;
								}
								if (column.hasPredifenedFilter) {
									return (
										<SelectField
											key={column.name}
											placeholder="Vyberte"
											label={column.label}
											// @ts-expect-error
											options={column.options?.map(({ name, value }) => ({
												value,
												label: renderTranslation(name),
											}))}
											name={column.name}
											simple
										/>
									);
								}
								return (
									<div>
										<TextField
											key={column.name}
											placeholder="Zadejte hodnotu"
											label={column.label}
											name={column.name}
											simple
										/>
										<OverlayTrigger
											placement="bottom"
											trigger={['hover', 'focus']}
											overlay={<Tooltip>Vložit obsah schránky</Tooltip>}
										>
											<Button onClick={() => handleCopy(column.name)} variant="secondary" size="sm">
												<ClipboardCopy width={20} />
											</Button>
										</OverlayTrigger>
									</div>
								);
							})}
							<SButtons>
								<Button type="submit">Filtrovat</Button>
								<OverlayTrigger
									placement="bottom"
									trigger={['hover', 'focus']}
									overlay={<Tooltip>Vymazat formulář</Tooltip>}
								>
									<Button onClick={handleReset} variant="light" size="sm">
										<XIcon width={20} className="text-danger" />
									</Button>
								</OverlayTrigger>
							</SButtons>
						</SFields>
					</SForm>
				);
			}}
		/>
	);
};

export default TableLightFilter;
