import { useMutation } from '@tanstack/react-query';

import GlobalError from '../../../components/form/GlobalError';
import GlobalSuccess from '../../../components/form/GlobalSuccess';
import { HTTPMethod } from '../../../constants/rest';
import { FetcherError, apiFetcher } from '../../../utils/fetcher';

export const useIntersMutation = ({ id, onSuccess }: { id?: string; onSuccess: () => void}) => {
	const mutation = useMutation<{ value: boolean }, FetcherError, boolean>({
		// @ts-ignore
		mutationFn:  (value: boolean) => apiFetcher(`internaldocuments/${id}/count-to-internal?count=${value ? 'true' : 'false'}`, {
			method: HTTPMethod.PUT,
			headers: {
				'Content-Type': 'application/json',
			},
		}),
		onSuccess,
	});
	return {
		mutation,
		errorMessage: mutation.error ? (
			// @ts-ignore
			<GlobalError />
		) : undefined,
		successMessage: mutation.isSuccess ? <GlobalSuccess /> : undefined,
	};
};

export const useIntersMarginMutation = ({ id, onSuccess }: { id?: string; onSuccess: () => void}) => {
	const mutation = useMutation<null, FetcherError, {documentId: string, value: boolean}>({
		// @ts-ignore
		mutationFn:  ({documentId, value}: {documentId: string, value: boolean}) => apiFetcher(`internaldocuments/${id}/internaldocument/${documentId}?margin=${value ? 'true' : 'false'}`, {
			method: HTTPMethod.PUT,
			headers: {
				'Content-Type': 'application/json',
			},
		}),
		onSuccess,
	});
	return {
		mutation,
		errorMessage: mutation.error ? (
			// @ts-ignore
			<GlobalError />
		) : undefined,
		successMessage: mutation.isSuccess ? <GlobalSuccess /> : undefined,
	};
};

export const useIntersMarginAllMutation = ({ id, documentId, onSuccess }: { id?: string; documentId?: string | null; onSuccess: () => void}) => {
	const mutation = useMutation<{ value: boolean }, FetcherError, boolean>({
		// @ts-ignore
		mutationFn: (value: boolean) => apiFetcher(`internaldocuments/${id}/${value ? 'include-all' : 'exclude-all'}/${documentId}`, {
			method: HTTPMethod.PUT,
			headers: {
				'Content-Type': 'application/json',
			},
		}),
		onSuccess,
	});
	return {
		mutation,
		errorMessage: mutation.error ? (
			// @ts-ignore
			<GlobalError />
		) : undefined,
		successMessage: mutation.isSuccess ? <GlobalSuccess /> : undefined,
	};
};

