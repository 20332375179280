import { getTheme } from '@table-library/react-table-library/baseline';
import {
	Body,
	Cell,
	Header,
	HeaderCell,
	HeaderRow,
	Row as TableRow,
	Table,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { QueryObserverResult } from '@tanstack/react-query';
import React from 'react';
import Col from 'react-bootstrap/Col';

import useEditModal from './useEditModal';
import { BasicListItem, ListItem, VehicleModelListItem } from '../../@types/lists';
import CreateButton from '../../components/buttons/CreateButton';
import Content from '../../components/Content';
import { getBrand } from '../../utils/brand';

function VehicleModelsListTable({
	data,
	brands,
	uniqueName,
	refetch,
}: {
	data: { nodes: Array<VehicleModelListItem> };
	brands: Array<BasicListItem>;
	uniqueName: string;
	refetch: () => Promise<QueryObserverResult<VehicleModelListItem, Error>>;
}): JSX.Element {
	const { editModal, handleShowEditModal } = useEditModal({
		itemType: 'vehicleModel',
		uniqueName,
		refetch,
	});
	const { editModal: addModal, handleShowEditModal: handleShowModal } = useEditModal({
		itemType: 'vehicleModel',
		uniqueName,
		refetch,
		variant: 'add',
	});
	const theme = useTheme([
		getTheme(),
		{
			Table: `grid-template-columns: 23.3% 23.3% 23.3% 10% 10% 10% `,
		},
	]);
	return (
		<>
			{editModal}
			{addModal}
			<Col>
				<CreateButton onClick={() => handleShowModal(null)} label="Přidat položku" />
				<Content innerSpacing={[5, 0]}>
					<Table data={data} theme={theme}>
						{(tableList: Array<any>) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCell>Značka</HeaderCell>
										<HeaderCell>Series</HeaderCell>
										<HeaderCell>Model</HeaderCell>
										<HeaderCell>Kód</HeaderCell>
										<HeaderCell>Model Group</HeaderCell>
										<HeaderCell>Body</HeaderCell>
									</HeaderRow>
								</Header>
								<Body>
									{tableList.map((item) => (
										<TableRow
											key={item.id}
											item={item}
											onDoubleClick={(item) => handleShowEditModal(item as ListItem)}
											style={{ cursor: 'pointer' }}
										>
											<Cell>{getBrand(brands, item.brandId)}</Cell>
											<Cell>{item.series}</Cell>
											<Cell>{item.model}</Cell>
											<Cell>{item.code}</Cell>
											<Cell>{item.modelGroup}</Cell>
											<Cell>{item.body}</Cell>
										</TableRow>
									))}
								</Body>
							</>
						)}
					</Table>
				</Content>
			</Col>
		</>
	);
}

export default VehicleModelsListTable;
