import { css } from '@emotion/react';

export const globalStyles = css`
	:root {
		--bs-body-font-weight: 500;
		--bs-border-color: #adadad;
		--bs-body-bg: var(--bs-light);
		--bs-secundary-bg: white;
	}
	body {
		font-size: 14px;
	}
	.form-control {
		background: white;
	}
	.form-select:disabled,
	select:disabled,
	.form-control:disabled {
		opacity: 0.6;
	}
	.accordion-button:focus {
		box-shadow: none;
		border-color: rgba(0, 0, 0, 0.125);
	}
	.accordion-header.error {
		button {
			background-color: rgba(220, 53, 69, 0.4);
		}
	}
	label,
	input,
	textarea,
	select,
	.form-select,
	.form-control,
	.accordion-header .accordion-button,
	.invalid-feedback,
	.btn,
	.alert {
		font-size: 14px;
	}
	.form-select, .form-check-input {
		background-color: white;
	}
	.modal-lg .modal-dialog {
		max-width: 800px;
	}
	th,
	td {
		font-size: 14px !important;
	}

	.list-group-horizontal > .list-group-item {
		border: none !important;
	}
	a.list-group-item.list-group-item-action {
		line-height: 2;
	}
	.accordion-body {
		background-color: var(--bs-body-bg);
	}
`;
