import React from 'react';

import useListQuery from './useListQuery';
import { BasicListItem, VehicleModelListItem } from '../../@types/lists';
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { BRAND } from '../../constants/lists';
import { mapBasicListToOptions } from '../../utils/lists';

function VehicleModelsListForm({
	initialValues,
}: {
	initialValues?: VehicleModelListItem | null;
}): JSX.Element {
	const brandsQuery = useListQuery(BRAND);
	return (
		<>
			<SelectField
				label="Brand"
				name="brandId"
				options={mapBasicListToOptions(
					(brandsQuery.data as BasicListItem[]) || []
				)}
				initialValue={initialValues?.brandId}
				placeholder="Vyberte brand"
			/>
			<TextField label="Series" name="series" initialValue={initialValues?.series} />
			<TextField label="Model" name="model" initialValue={initialValues?.model} />
			<TextField label="Kód" name="code" initialValue={initialValues?.code} />
			<TextField label="Model Group" name="modelGroup" initialValue={initialValues?.modelGroup} />
			<TextField label="Body" name="body" initialValue={initialValues?.body} />
		</>
	);
}

export default VehicleModelsListForm;
