import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { size } from '../../styles/spacing';

export const globalStyles = css`
	:root,
	[data-bs-theme='light'] {
		--bs-primary: #1c69d4;
		--bs-primary-rgb: 28, 105, 212;
	}

	:root {
		--bs-border-radius: ${size(2)};
	}

	.list-group {
		--bs-list-group-item-padding-y: ${size(4)};
	}

	.list-group-item {
		border-top: none;
		border-left: none;
		border-right: none;

		&:last-of-type {
			border: none;
		}
	}

	html,
	body {
		font-family: 'Open Sans', sans-serif;
		text-rendering: optimizeLegibility !important;
		font-smoothing: antialiased !important;
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale;
		filter: 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=hIEfix.png,sizingMethod=crop)' !important;
		font-smooth: always;
		text-decoration-skip-ink: auto;
	}
	body {
		background: var(--bs-light);
	}
`;

export const SContainer = styled.div`
	padding: ${size(5)};
`;
