import React from 'react';

import { SalesPersonListItem } from '../../@types/lists';
import SelectField from '../../components/form/fields/SelectField';
import TextField from '../../components/form/fields/TextField';
import { branchOptions, vehicleTypeOptions } from '../../constants/lists';

function SalesPersonListForm({
	initialValues,
	variant,
}: {
	initialValues?: SalesPersonListItem | null;
	variant: 'add' | 'edit';
}): JSX.Element {
	return (
		<>
			<SelectField
				options={vehicleTypeOptions}
				label="Modul"
				name="vehicleType"
				initialValue={initialValues?.vehicleType}
			/>
			<TextField label="Jméno prodejce" name="name" initialValue={initialValues?.name} />
			<SelectField
				options={branchOptions}
				label="Pobočka"
				name="branch"
				initialValue={initialValues?.branch}
			/>
			<TextField label="Login" name="login" initialValue={initialValues?.login} />
		</>
	);
}

export default SalesPersonListForm;
