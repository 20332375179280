import { useQuery } from '@tanstack/react-query';

import { VehicleDetail } from '../../../@types/vehicleDetail';
import { apiFetcher } from '../../../utils/fetcher';

type InternaldocumentsValues = {
	internalDocumentFigure: number,
	interestsCosts: number,
	countInterestsToInternalCosts: boolean
}

const useInters = ({ id }: { id?: string }) => {
	const internaldocumentsValuesQuery = useQuery<InternaldocumentsValues, Error>({
		queryKey: ['internaldocumentsValues', { id }],
		queryFn: async (): Promise<InternaldocumentsValues> => {
			const res = await apiFetcher(`internaldocuments/${id}/values`);

			return res.json as InternaldocumentsValues;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});

	const internaldocumentsQuery = useQuery<VehicleDetail, Error>({
		queryKey: ['internaldocuments', { id }],
		queryFn: async (): Promise<VehicleDetail> => {
			const res = await apiFetcher(`internaldocuments/${id}`);

			return res.json as VehicleDetail;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});


	return {
		documentsQuery: internaldocumentsQuery,
		documentsValuesQuery: internaldocumentsValuesQuery,
	};
};

type InternaldocumentsDocuments = {
	id: string,
	vehicleId: string,
	margin: boolean,
	documentId: string
	internalDocumentType: number,
	number: string,
	name: string,
	amount: number
	figure: number,
	center: string,
	exhibitedBy : string
}

export const useIntersDocuments = ({ vehicleId, documentId }: { vehicleId?: string, documentId?:string }) => {
	const internaldocumentsValuesQuery = useQuery<InternaldocumentsDocuments, Error>({
		queryKey: ['internaldocumentsDocuments', { vehicleId, documentId }],
		queryFn: async (): Promise<InternaldocumentsDocuments> => {
			const res = await apiFetcher(`internaldocuments/${vehicleId}/document/${documentId}`);

			return res.json as InternaldocumentsDocuments;
		},
		refetchOnWindowFocus: false,
		enabled: !!vehicleId && !!documentId,
	});

	return {
		documentsQuery: internaldocumentsValuesQuery,
	};
};

export default useInters;
