import merge from 'deepmerge';
import qs from 'query-string';
//import authService from '../components/api-authorization/AuthorizeService';

import { HTTPMethod } from '../constants/rest';

type SearchParams = Record<string, unknown>;

export type FetchArgs = Omit<RequestInit, 'body' | 'method'> & {
	body?: Record<string, unknown> | FormData | string;
	toBlob?: boolean;
	method?: HTTPMethod;
	searchParams?: SearchParams;
};

export interface FetchResponse {
	status: number;
	headers?: Headers;
}

export interface FetchJsonResponse<T> extends FetchResponse {
	json?: T;
}

export interface FetchBlobResponse extends FetchResponse {
	blob?: Blob;
}

export interface Fetcher {
	(path: string, args: FetchArgs & { toBlob: true }): Promise<FetchBlobResponse>;
	<Json>(path: string, args?: FetchArgs & { toBlob?: false }): Promise<FetchJsonResponse<Json>>;
}

export class FetcherError extends Error {
	statusCode: number;

	constructor(
		public readonly path: string,
		public readonly response: FetchJsonResponse<{
			Message?: string;
		}>
	) {
		super();
		this.statusCode = response.status;
	}
}

export function addSearchParams(url: string, args: SearchParams = {}): string {
	const { query: initialQuery, url: baseUrl } = qs.parseUrl(url);

	const queryObj = merge(initialQuery, args);
	const querystring = qs.stringify(queryObj, { skipNull: true });
	return querystring.length > 0 ? `${baseUrl}?${querystring}` : baseUrl;
}

export function createFetcher(baseUrl: string): Fetcher {
	async function fetchCall<T = unknown | Blob>(
		path: string,
		fetchArgs?: FetchArgs
	): Promise<FetchJsonResponse<T> | FetchBlobResponse> {
		const body = fetchArgs?.body;
		const method = fetchArgs?.method || HTTPMethod.GET;

		const options: RequestInit = {
			...fetchArgs,
			body: undefined,
			credentials: 'include',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				...(global.FormData != null && body instanceof FormData
					? undefined
					: { 'Content-Type': 'application/json' }),
				...(fetchArgs?.headers || {}),
			},
		};

		const canIncludeBody = ![HTTPMethod.GET, HTTPMethod.HEAD].includes(method);
		if (canIncludeBody) {
			if (global.FormData != null && body instanceof FormData) {
				options.body = body;
			} else {
				options.body = body !== Object(body) ? String(body) : JSON.stringify(body);
			}
		}

		const url = addSearchParams(`${baseUrl}${path}`, fetchArgs?.searchParams);
		try {
			document.body.style.cursor = 'wait'

			const result = await fetch(url, options);

			const contentType = result.headers?.get('Content-Type') || '';
			const isJSON = contentType.includes('application/json');

			if (!result.ok) {
				let errorText = isJSON ? await result.json() : await result.text();
				if (result.status === 500 && (errorText as string).includes("No authentication handler is registered for the scheme")) {
					console.error(
						'*** Uživatel není authorizován pro danou akci ***',
						errorText,
						'*************************************************'
					);
					errorText = "Uživatel nemá oprávnění pro danou akci";
					throw new FetcherError(path, {
						status: 403,
						headers: result.headers,
						json: errorText,
					});
				} else {
					console.error(
						'*** CHYBA, NAHLÁSTE PROSÍM ADMINISTRÁTOROVI ***',
						errorText,
						'*************************************************'
					);

					throw new FetcherError(path, {
						status: result.status,
						headers: result.headers,
						json: errorText,
					});
				}


			}

			if (fetchArgs?.toBlob) {
				const response: FetchBlobResponse = {
					status: result.status,
					headers: result.headers,
					blob: await result.blob(),
				};

				return response;
			} else {
				const response: FetchJsonResponse<T> = {
					status: result.status,
					headers: result.headers,
					json: isJSON ? await result.json() : null,
				};

				return response;
			}
		} finally {
			document.body.style.cursor = 'default'
		}
	}

	return fetchCall;
}

export const apiFetcher = createFetcher(process.env.REACT_APP_API_URL as string);
