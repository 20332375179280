import { createForm } from 'final-form';
import { noop } from 'lodash';
import { Search } from 'lucide-react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { SFlex } from './index.styled';
import ModalColor from './ModalEquipment';
import ModalGearOverview from './ModalGearOverview';
import useDetailMutation from './useDetailMutation';
import useSpecification from './useSpecification';
import { EquipmentListItem } from '../../../@types/lists';
import SaveButton from '../../../components/buttons/SaveButton';
import DatePickerField from '../../../components/form/fields/DatePickerField';
import TextField from '../../../components/form/fields/TextField';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { SPECIFICATION } from '../../../constants/detailViews';
import { usePrompt } from '../../../hooks/useBlocker';
import { getInitialDate } from '../../../utils/date';
import Navigation from '../Navigation';

function Specification(): JSX.Element {
	const { id } = useParams();
	const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
	const { colors, gear, isLoading, upholster, vehicleDetail, vehicleDetailRefetch } =
		useSpecification({
			id,
		});
	const [form] = useState(() =>
		createForm({
			onSubmit: (values, form) => {
				const dirtyFields = form.getState().dirtyFields;
				const { color_Code, upholster_Code, ...changedFields } = dirtyFields;
				//@ts-ignore
				return mutation.mutate({
					formData: values,
					changedFields,
				});
			},
		})
	);
	const { mutation, errorMessage, successMessage } = useDetailMutation({
		id,
		onSuccess: () => {
			vehicleDetailRefetch().then(() => form.reset());
		},
	});
	const [modalEquipmentOpen, setModalEquipmentOpen] = useState<boolean>(false);
	const [modalGearOpen, setModalGearOpen] = useState<boolean>(false);
	const [equipmentType, setEquipmentType] = useState<'color' | 'upholster' | undefined>(undefined);
	usePrompt('Opravdu chcete opustit formulář?', isFormDirty && !mutation.isLoading);
	return (
		<Layout>
			<Navigation activeView={SPECIFICATION} id={id as string} />
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Form form={form} onSubmit={noop}>
						{({
							dirty,
							form: { change },
							handleSubmit,
							values,
						}: {
							dirty: boolean;
							form: any;
							handleSubmit: () => void;
							values: any;
						}) => {
							if (!isFormDirty && dirty) {
								setIsFormDirty(true);
							} else if (isFormDirty && !dirty) {
								setIsFormDirty(false);
							}
							return (
								<>
									{modalEquipmentOpen && (
										<ModalColor
											colors={colors as EquipmentListItem[]}
											upholster={upholster as EquipmentListItem[]}
											equipmentType={equipmentType}
											code={equipmentType === 'color' ? values.color_Code : values.upholster_Code}
											onClose={() => setModalEquipmentOpen(false)}
											onDeny={() => {
												change(equipmentType === 'color' ? 'color_Code' : 'upholster_Code', null);
												change(equipmentType === 'color' ? 'color_Id' : 'upholster_Id', null);
												setModalEquipmentOpen(false);
											}}
											onConfirm={({ id, description, code }) => {
												change(
													equipmentType === 'color' ? 'color_Code' : 'upholster_Code',
													`${code}: ${description}`
												);
												change(equipmentType === 'color' ? 'color_Id' : 'upholster_Id', id);
												setModalEquipmentOpen(false);
											}}
										/>
									)}
									{modalGearOpen && (
										<ModalGearOverview
											gear={gear as EquipmentListItem[]}
											selectedGear={values.vehicles_GearCodes}
											onClose={() => setModalGearOpen(false)}
										/>
									)}
									<Row>
										<Col lg={10}>
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-12 col-md-6">
														<SFlex align="start">
															<div className="col">
																<TextField
																	label="Barva"
																	name="color_Code"
																	initialValue={
																		vehicleDetail?.color_Code
																			? `${vehicleDetail?.color_Code}: ${vehicleDetail?.color_Description}`
																			: undefined
																	}
																	shouldCopyOnDoubleClick
																/>
															</div>
															<TextField
																name="color_Id"
																initialValue={vehicleDetail?.color_Id}
																type="hidden"
															/>
															<div className="mb-3 ms-2" style={{ marginTop: '26px' }}>
																<button
																	type="button"
																	className="btn btn-info"
																	onClick={() => {
																		setModalEquipmentOpen(true);
																		setEquipmentType('color');
																	}}
																	disabled={!values.color_Code}
																>
																	<Search color="white" size={20} />
																</button>
															</div>
														</SFlex>
													</div>
													<div className="col-12 col-md-6">
														<SFlex align="start">
															<div className="col">
																<TextField
																	label="Polstrování"
																	name="upholster_Code"
																	initialValue={
																		vehicleDetail?.upholster_Code
																			? `${vehicleDetail?.upholster_Code}: ${vehicleDetail?.upholster_Description}`
																			: undefined
																	}
																	shouldCopyOnDoubleClick
																/>
															</div>
															<TextField
																name="upholster_Id"
																initialValue={vehicleDetail?.upholster_Id}
																type="hidden"
															/>
															<div className="mb-3 ms-2" style={{ marginTop: '26px' }}>
																<button
																	type="button"
																	className="btn btn-info"
																	onClick={() => {
																		setEquipmentType('upholster');
																		setModalEquipmentOpen(true);
																	}}
																	disabled={!values.upholster_Code}
																>
																	<Search color="white" size={20} />
																</button>
															</div>
														</SFlex>
													</div>
												</div>
												<div className="row position-relative">
													<div className="col-12 position-relative">
														<TextField
															as="textarea"
															label="Výbava"
															rows={3}
															name="vehicles_GearCodes"
															initialValue={vehicleDetail?.vehicles_GearCodes}
															shouldCopyOnDoubleClick
														/>
														{values.vehicles_GearCodes && (
															<Button
																variant="link"
																className="position-absolute"
																onClick={() => setModalGearOpen(true)}
																style={{ top: '-6px', right: '0' }}
															>
																Rozpis výbavy
															</Button>
														)}
													</div>
												</div>
												<div className="row">
													<div className="col-12 col-md-3">
														<TextField
															label="Plánovaný týden výroby"
															name="vehicleProductions_RequestedWeek"
															initialValue={vehicleDetail?.vehicleProductions_RequestedWeek}
															shouldCopyOnDoubleClick
															disabled
														/>
													</div>
													<div className="col-12 col-md-3">
														<TextField
															label="Potvrzený týden výroby"
															name="vehicleProductions_ConfirmedWeek"
															initialValue={vehicleDetail?.vehicleProductions_ConfirmedWeek}
															shouldCopyOnDoubleClick
															disabled
														/>
													</div>
													<div className="col-12 col-md-3">
														<TextField
															label="Vyrobní status"
															name="vehicleProductions_ProductionStatusCode"
															initialValue={vehicleDetail?.vehicleProductions_ProductionStatusCode}
															shouldCopyOnDoubleClick
														/>
													</div>
													<div className="col-12 col-md-3">
														<DatePickerField
															name="vehicleProductions_ProductionDate"
															label="Datum výrobního statusu"
															placeholder="Vyberte datum"
															initialValue={getInitialDate(
																vehicleDetail?.vehicleProductions_ProductionDate
															)}
															disabled
														/>
													</div>
												</div>
											</form>
										</Col>
										<Col xs={2}>
											<div className="mt-4">
												{errorMessage}
												{successMessage}
												<SaveButton
													onClick={handleSubmit}
													disabled={mutation.isLoading || !dirty}
													size="lg"
													/>
											</div>
										</Col>
									</Row>
								</>
							);
						}}
					</Form>
				</>
			)}
		</Layout>
	);
}

export default Specification;
