import { useMutation } from '@tanstack/react-query';

import { VehicleDetail } from '../../../@types/vehicleDetail';
import GlobalError from '../../../components/form/GlobalError';
import GlobalSuccess from '../../../components/form/GlobalSuccess';
import { HTTPMethod } from '../../../constants/rest';
import { FetcherError, apiFetcher } from '../../../utils/fetcher';
import useType from '../useType';

const useDetailMutation = ({ id, onSuccess }: { id?: string; onSuccess: () => void }) => {
	const { id: typeID } = useType();
	const mutation = useMutation<VehicleDetail, FetcherError>({
		// @ts-ignore
		mutationFn: ({ formData, changedFields, extraData }) => {
			const isCreating = id === undefined || id === null;
			let values = {} as VehicleDetail;
			for (const [key] of Object.entries(changedFields)) {
				values = { ...values, [key]: formData[key] };
			}
			if (extraData) {
				values = { ...values, ...extraData };
			}
			return isCreating
				? apiFetcher(`vehicles`, {
						method: HTTPMethod.POST,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ ...values, vehicles_Type: typeID }, (k, v) =>
							v === undefined ? null : v
						),
				  })
				: apiFetcher(`vehicles/${id}`, {
						method: HTTPMethod.PUT,
						headers: {
							'Content-Type': 'application/json',
						},
						// ts:ignore
						body: JSON.stringify(values, (k, v) => (v === undefined ? null : v)),
				  });
		},
		onSuccess,
	});
	return {
		mutation,
		errorMessage: mutation.error ? (
			// @ts-ignore
			<GlobalError />
		) : undefined,
		successMessage: mutation.isSuccess ? <GlobalSuccess /> : undefined,
	};
};

export default useDetailMutation;
