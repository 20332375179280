import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { EquipmentListItem } from '../../../@types/lists';

function ModalEquipment({
	colors,
	equipmentType,
	upholster,
	code,
	onClose,
	onConfirm,
	onDeny,
}: {
	colors: EquipmentListItem[];
	equipmentType: 'color' | 'upholster' | undefined;
	upholster: EquipmentListItem[];
	code: string;
	onClose: () => void;
	onConfirm: (selectedOption: EquipmentListItem) => void;
	onDeny: () => void;
}): React.JSX.Element {
	const selectedOption =
		equipmentType === 'color'
			? colors.find((color: EquipmentListItem) => color.code?.toLowerCase() === code.toLowerCase())
			: upholster.find(
					(upholster: EquipmentListItem) => upholster.code?.toLowerCase() === code.toLowerCase()
			  );
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{equipmentType === 'color' ? 'Vybraná barva' : 'Vybrané polstrování'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!selectedOption ? (
					<>
						<p className="text-danger">Nenalezeno</p>
						<Button variant="link" className="ps-0" onClick={onDeny}>
							Zadat jiný kód
						</Button>
					</>
				) : (
					<>
						<p>
							{selectedOption.code}: {selectedOption.description}
						</p>
						<div className="mt-2 gap-2 d-flex flex-wrap">
							<Button variant="primary" onClick={() => onConfirm(selectedOption)}>
								Potvrdit
							</Button>
							<Button variant="link" onClick={onDeny}>
								Zadat jiný kód
							</Button>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default ModalEquipment;
