import { CellSelect, useRowSelect } from '@table-library/react-table-library/select';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { format } from 'date-fns';
import {
	ArrowDownUp,
	ArrowDownAZ,
	ArrowUpAZ,
	ArrowUpRightSquare,
	ChevronFirst,
	ChevronLast,
	ChevronLeft,
	ChevronRight,
	Plus,
	Sheet,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BSRow from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';

import { SCell, SNumber, SortIcon, STable } from './ListTable.styled';
import { Column, ColumnFilters, Filter } from '../../@types/vehicleFilters';
import authService from "../../components/Authorization/AuthorizationService";
import ColumnFilter from '../../components/ColumnFilter';
import ColumnsFilter from '../../components/ColumnsFilter';
import ColumnsSelect from '../../components/ColumnsSelect';
import Content from '../../components/Content';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import Section from '../../components/Section';
import { BMW_CREATE, MOTO_CREATE, OTHER_CREATE } from '../../constants/routes';
import { BMW_ALL, defaultHiddenColumns, MOTO_ALL, OTHERS_ALL } from '../../constants/vehicleFilters';
import useStructureQuery from '../../hooks/useStructureQuery';
import useVehiclesQuery from '../../hooks/useVehiclesQuery';
import { renderValue } from '../ColumnFilter';
import ColumnsColors from '../ColumnsColors';
import ColumnsDelete from '../ColumnsDelete';
import ExportToExcel from '../ExportToExcel';
import GlobalSuccess from '../form/GlobalSuccess';
import Import from '../Import';
import TableLightFilter from '../TableLightFilter';

export type ListTableConnfig = {
	createButtonEnabled: boolean,
	stornoButtonEnabled: boolean,
	deleteButtonEnabled: boolean,
	importEnabled: boolean,
	detailEnabled: boolean
}

function unCapitalizeFirstLetter(string: string): string {
	return string.charAt(0).toLowerCase() + string.slice(1);
}

function ListTable({ type, uniqueName, title, config }: { type: string; uniqueName: string, title: string, config: ListTableConnfig }): JSX.Element {
	const allowedRoles = ["Admin", "Manager"]
	const [isCreateButtonVisible, setIsCreateButtonVisible] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		isAuthorizeForAction();
	}, []);

	const isAuthorizeForAction = async () => {
		const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
		setIsCreateButtonVisible(isAuthorize && config.createButtonEnabled);
	}

	const detailPath = (id: string, innerType: string) => {
		switch (type) {
			case BMW_ALL:
				return `/bmw/${id}/detail`;
			case MOTO_ALL:
				return `/moto/${id}/detail`;
			case OTHERS_ALL:
				return `/other/${id}/detail`;
			default:
				switch (innerType) {
					case "BMW":
						return `/bmw/${id}/detail`;
					case "MOTO":
						return `/moto/${id}/detail`;
					case "OTHER":
						return `/other/${id}/detail`;
					default:
						return `/bmw/${id}/detail`;
				}
		}
	}

	const createPath = type === BMW_ALL ? BMW_CREATE : type === MOTO_ALL ? MOTO_CREATE : OTHER_CREATE;

	const {
		data: vehicles,
		isLoading: isVehiclesLoading,
		isError: isVehiclesError,
		changeFilter,
		changeParameters,
		changeOrderBy,
		orderBy,
		sortOrder,
		refetch,
	} = useVehiclesQuery(uniqueName);
	const {
		data: structure,
		isLoading: isStructureLoading,
		isError: isStructureError,
	} = useStructureQuery(uniqueName);

	const [selectedColumns, setSelectedColumns] = useState<Column[]>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFilters>({});
	const [isRefetching, setIsRefetching] = useState(false);

	const handleRefreshWithDelay = () => {
		setIsRefetching(true);
		setTimeout(() => {
			setIsRefetching(false);
			refetch();
		}, 1000);
	};

	const handleColumnsChange = (columns: Column[]) => {
		setSelectedColumns(columns);
		sessionStorage.setItem(`selectedColumns-${type}`, JSON.stringify(columns));
	};

	useEffect(() => {
		const currentParams = new URLSearchParams(location.search);
		const urlFilter = currentParams.get('filter');

		if (urlFilter) {
			setColumnFilters(JSON.parse(urlFilter));
		}
	}, []);

	const updateColumnFilter = (columnName: string, newFilter: Filter[]) => {
		const newFilters: ColumnFilters = {
			...columnFilters,
			[columnName]: Array.isArray(newFilter) ? newFilter : [newFilter],
		};

		setColumnFilters(newFilters);
		changeFilter(newFilters);
	};

	const storedColumns = sessionStorage.getItem(`selectedColumns-${type}`) || '[]';

	useEffect(() => {
		if (!isStructureLoading) {
			if (storedColumns && JSON.parse(storedColumns).length > 0) {
				setSelectedColumns(JSON.parse(storedColumns));
			} else if (structure && structure.columns) {
				const updatedColumns: Column[] = structure.columns.map((column: Column) => {
					if (defaultHiddenColumns.includes(column.name)) {
						return { ...column, hidden: true };
					}
					return column;
				});
				handleColumnsChange(updatedColumns);
			}
		}
	}, [storedColumns, structure, isStructureLoading]);

	const isLoading = isVehiclesLoading || isStructureLoading || isRefetching;
	const isError = isVehiclesError || isStructureError;

	const data =
		isVehiclesLoading || isVehiclesError
			? { nodes: [] }
			: {
					nodes: vehicles?.data,
			  };

	const select = useRowSelect(data);

	const {
		state: { ids: selectedIds = [] },
	} = select;

	useEffect(() => {
		select.fns.onRemoveAll();
	}, [isLoading]);

	const tableCss = selectedColumns
		.map((item) => (item.hidden ? undefined : `${item.columnWidth || 150}px`))
		.filter(Boolean)
		.join(' ');

	const getTableTheme = () => {
		return config.detailEnabled ? `
            --data-table-library_grid-template-columns: 50px 50px  ${tableCss}!important;
        `
		:
			`
            --data-table-library_grid-template-columns: ${tableCss}!important;
        `
		;
	};
	const theme = useTheme([
		{
			Table: getTableTheme(),

			BaseCell: `
				&:not(:last-of-type) {
				  border-right: 1px solid #a0a8ae;
				}
			  `,
		},
	]);

	function renderButtons() {
		const values = [10, 25, 50, 100, 200];

		return values.map((value, index) => (
			<OverlayTrigger
				key={index}
				placement="bottom"
				trigger={['hover', 'focus']}
				overlay={<Tooltip>{`${value} záznamů na stránce`}</Tooltip>}
			>
				<Button
					variant="light"
					onClick={() => changeParameters(value, 1)}
					disabled={isLoading || isError}
				>
					<SNumber>
						{!(isLoading || isError) && value === vehicles?.pageSize ? (
							<strong>{value}</strong>
						) : (
							value
						)}
					</SNumber>
				</Button>
			</OverlayTrigger>
		));
	}

	return (
		<Layout
			menu={
				<>
					{isCreateButtonVisible && <Button variant="light" onClick={() => navigate(`${createPath}`)} className="me-2">
						<Plus size={26} />
					</Button>}
					<ButtonGroup className="me-2">{renderButtons()}</ButtonGroup>
					<ButtonGroup className="me-2">
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>První stránka</Tooltip>}
						>
							<Button
								variant="light"
								onClick={() => changeParameters(isLoading || isError ? 0 : vehicles.pageSize, 1)}
								disabled={isLoading || isError}
							>
								<ChevronFirst size={26} />
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Předchozí stránka</Tooltip>}
						>
							<Button
								variant="light"
								onClick={() =>
									changeParameters(
										isLoading || isError ? 0 : vehicles.pageSize,
										isLoading || isError ? 0 : vehicles.page - 1
									)
								}
								disabled={isLoading || isError || !vehicles.hasPrevisouPage}
							>
								<ChevronLeft size={26} />
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Další stránka</Tooltip>}
						>
							<Button
								variant="light"
								onClick={() =>
									changeParameters(
										isLoading || isError ? 0 : vehicles.pageSize,
										isLoading || isError ? 0 : vehicles.page + 1
									)
								}
								disabled={isLoading || isError || !vehicles.hasNextPage}
							>
								<ChevronRight size={26} />
							</Button>
						</OverlayTrigger>
						<OverlayTrigger
							placement="bottom"
							trigger={['hover', 'focus']}
							overlay={<Tooltip>Poslední stránka</Tooltip>}
						>
							<Button
								variant="light"
								onClick={() =>
									changeParameters(
										isLoading || isError ? 0 : vehicles.pageSize,
										isLoading || isError ? 0 : vehicles.totalPages
									)
								}
								disabled={isLoading || isError}
							>
								<ChevronLast size={26} />
							</Button>
						</OverlayTrigger>
					</ButtonGroup>
					<ButtonGroup className="me-2">
						<ColumnsFilter
							columns={selectedColumns}
							filters={columnFilters}
							onSubmit={(filters) => {
								setColumnFilters(filters);
								changeFilter(filters);
							}}
						/>
						<ColumnsSelect
							type={type}
							columns={selectedColumns}
							onColumnsChange={handleColumnsChange}
						/>
					</ButtonGroup>
					<ButtonGroup className="me-2">
						<ExportToExcel
							uniqueName={uniqueName}
							selectedColumns={selectedColumns
								.filter((col) => !col.hidden)
								.map((col) => {
									return { name: col.name, label: col.label };
								})}
						/>
					</ButtonGroup>

					{selectedIds.length > 0 && (
						<ButtonGroup className="me-2">
							<Button variant="info" disabled>
								Vybráno: <strong>{selectedIds.length}</strong>
							</Button>
							<ColumnsColors ids={selectedIds} onSubmit={handleRefreshWithDelay} />
							<ColumnsDelete ids={selectedIds} onSubmit={handleRefreshWithDelay} enabled={config.deleteButtonEnabled} />
						</ButtonGroup>
					)}
				</>
			}
			importMenu={config.importEnabled &&
				<ButtonGroup className="me-2">
					<Import
						type={type}
					/>
				</ButtonGroup>
			}
			submenu={
				!isStructureLoading &&
				structure &&
				structure.columns && (
					<TableLightFilter
						columns={structure.columns}
						filters={columnFilters}
						onSubmit={(filters) => {
							setColumnFilters(filters);
							changeFilter(filters);
						}}
					/>
				)
			}
		>
			<>
				{location.state?.hasDeleted && <GlobalSuccess message="Karta byla úspěšně smazana" />}
				<Section>
					<Content innerSpacing={[5, 5, 0, 5]}>
						<BSRow className="align-items-center">
							<Col>
								<h2>{title}</h2>
							</Col>
							{!(isLoading || isError) && (
								<Col xs="auto">
									Zobrazeno{' '}
									<strong>
										{(vehicles.page - 1) * vehicles.pageSize + 1} -{' '}
										{vehicles.page * vehicles.pageSize}
									</strong>{' '}
									z celkem <strong>{vehicles.count}</strong>
								</Col>
							)}
						</BSRow>
					</Content>
					{isLoading ? (
						<Section>
							<Content innerSpacing={[5]}>
								<Loader />
							</Content>
						</Section>
					) : isError ? (
						<Section>
							<Content innerSpacing={[5]}>
								<div className="text-center">
									Při načítání dat se vyskytla chyba, prosím zkuste načíst stránku znovu.
								</div>
							</Content>
						</Section>
					) : (
						<STable width={`${selectedColumns
							.map((item) => (item.hidden ? 0 : item.columnWidth || 150))
							.reduce((acc, width) => acc + width, 0) + 130}px`}>
							<Table
								data={data}
								select={select}
								layout={{ horizontalScroll: true, fixedHeader: true }}
								theme={theme}
							>
								{(tableList: Array<any>) => (
									<>
										<Header>
											<HeaderRow>
												{selectedColumns.map((column, index) => {
													const headerCell = (
														<HeaderCell
															key={column.order}
															hide={column.hidden}
															className="HeaderCell"
														>
															<div className="mb-2">
																<ButtonGroup className="me-2">
																	<ColumnFilter
																		title={column.label}
																		filters={(columnFilters && columnFilters[column.name]) || null}
																		column={column}
																		onSubmit={(newFilters) =>
																			updateColumnFilter(column.name, newFilters)
																		}
																	/>
																	<OverlayTrigger
																		placement="bottom"
																		trigger={['hover', 'focus']}
																		overlay={<Tooltip>Řazení</Tooltip>}
																	>
																		<Button
																			variant="light"
																			size="sm"
																			onClick={() =>
																				changeOrderBy(
																					column.name,
																					orderBy !== column.name ? 0 : sortOrder === 0 ? 1 : -1
																				)
																			}
																			disabled={isLoading}
																		>
																			<SortIcon>
																				{orderBy !== column.name ? (
																					<ArrowDownUp size={16} />
																				) : sortOrder === 0 ? (
																					<ArrowDownAZ size={16} />
																				) : (
																					<ArrowUpAZ size={16} />
																				)}
																			</SortIcon>
																		</Button>
																	</OverlayTrigger>
																</ButtonGroup>
															</div>
															{column.label}
														</HeaderCell>
													);

													if (index === 0 && config.detailEnabled) {
														return [
															<HeaderCell className="text-center">
																<input
																	type="checkbox"
																	aria-label="Vybrat vše"
																	checked={select.state.all}
																	onChange={select.fns.onToggleAll}
																/>
															</HeaderCell>,
															<HeaderCell className="text-center" />,
															headerCell,
														];
													}

													return headerCell;
												})}
											</HeaderRow>
										</Header>

										<Body>
											{tableList.map((item) => {
												return (
													<Row
														key={item.id}
														item={item}
														onDoubleClick={(item) => {
															if (config.detailEnabled) {
																sessionStorage.setItem('listSearchParamsRedirect', location.search);
																navigate(detailPath(item.id, item['vehicles_Type']));
															}
														}}
													>
														{selectedColumns.map((selectedColumn, index) => (
															<>
																{index === 0 && config.detailEnabled && [
																	<CellSelect
																		item={item}
																		// @ts-expect-error
																		style={{ backgroundColor: item['lineColors_ColorHex'], height: "30px!important" }}
																	/>,
																	<SCell bgColor={item['lineColors_ColorHex']}>
																		<a href={detailPath(item.id, item['vehicles_Type'])} target="_blank">
																			<ArrowUpRightSquare />
																		</a>
																	</SCell>,
																]}
																{!selectedColumn.hidden && (
																	<SCell
																		key={`${item.id}-${selectedColumn.order}`}
																		bgColor={item['lineColors_ColorHex']}
																	>
																		{selectedColumn.type === 'DATE' &&
																		item[unCapitalizeFirstLetter(selectedColumn.name)]
																			? format(
																					new Date(
																						item[unCapitalizeFirstLetter(selectedColumn.name)]
																					),
																					'dd.MM.yyyy'
																			  )
																			: renderValue(
																					selectedColumn,
																					item[unCapitalizeFirstLetter(selectedColumn.name)]
																			  )}
																	</SCell>
																)}
															</>
														))}
													</Row>
												);
											})}
										</Body>
									</>
								)}
							</Table>
						</STable>
					)}

					{!isLoading && data.nodes.length === 0 && (
						<Content innerSpacing={[10]} className="text-center">
							Nebyly nalezeny žádné položky.
						</Content>
					)}
				</Section>
			</>
		</Layout>
	);
}

export default ListTable;
