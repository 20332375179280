import { useMutation } from '@tanstack/react-query';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-final-form';

import { Loan } from './useLoans';
import DatePickerField from '../../../components/form/fields/DatePickerField';
import SelectField from '../../../components/form/fields/SelectField';
import TextField from '../../../components/form/fields/TextField';
import GlobalError from '../../../components/form/GlobalError';
import { required } from '../../../components/form/utils/validators';
import { HTTPMethod } from '../../../constants/rest';
import { getInitialDate } from '../../../utils/date';
import { FetcherError, apiFetcher } from '../../../utils/fetcher';

function ModalLoan({
	onClose,
	onUpdate,
	loan,
	warehouses,
	variant = 'edit',
	vehicleId,
}: {
	onUpdate: () => void;
	onClose: () => void;
	loan?: Loan;
	variant: 'add' | 'edit';
	vehicleId: string;
	warehouses: Array<{ value: string; label: string }>;
}): JSX.Element {
	const mutation = useMutation<Loan, FetcherError>({
		// @ts-ignore
		mutationFn: (formData: any) => {
			// ts:ignore
			return variant === 'add'
				? apiFetcher(`loans/${vehicleId}`, {
						method: HTTPMethod.POST,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(formData),
				  })
				: apiFetcher(`loans/${loan?.id}`, {
						method: HTTPMethod.PUT,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ ...formData, id: loan?.id }),
				  });
		},
		onSuccess: () => {
			onUpdate();
		},
	});
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>{variant === 'add' ? 'Vytvořit zápůjčku' : 'Upravit zápůjčku'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{mutation.error && <GlobalError />}
				<Form
					onSubmit={(values) => {
						return mutation.mutate(values as any);
					}}
				>
					{({ handleSubmit, hasValidationErrors, values }) => (
						<>
							<SelectField
								label="HOM"
								name="warehouseId"
								options={warehouses}
								placeholder="Vyberte"
								validate={required}
								initialValue={loan?.warehouseId}
							/>
							<DatePickerField
								name="loanedFrom"
								label="Zapůjčeno"
								placeholder="Vyberte datum"
								initialValue={getInitialDate(loan?.loanedFrom)}
								maxDate={getInitialDate(values.loanedTo)}
								validate={required}
							/>
							<DatePickerField
								name="loanedTo"
								label="Vráceno"
								placeholder="Vyberte datum"
								initialValue={getInitialDate(loan?.loanedTo)}
								minDate={getInitialDate(values.loanedFrom)}
							/>
							<TextField
								label="Popis"
								name="description"
								as="textarea"
								initialValue={loan?.description}
								shouldCopyOnDoubleClick
							/>
							<Button
								variant="primary"
								type="submit"
								className="mt-2"
								onClick={handleSubmit}
								disabled={mutation.isLoading || hasValidationErrors}
							>
								Uložit
							</Button>
						</>
					)}
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default ModalLoan;
