import { useEffect, useState } from 'react';

export const useClipboard = (): [string | null, () => Promise<void>] => {
    const [clipboardContent, setClipboardContent] = useState<string | null>(null);

    const handleClipboardClick = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setClipboardContent(text);
        } catch (error) {
            console.error('Failed to read clipboard content:', error);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClipboardClick);
        return () => {
            document.removeEventListener('click', handleClipboardClick);
        };
    }, []);

    return [clipboardContent, handleClipboardClick];
};