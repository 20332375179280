const map = {
	Vehicle_UniqueId: 'Položka',
	Vehicle_Identification: 'Systém',
	Vehicle_VIN: 'VIN',
	Vehicle_Status: 'Status',
	Vehicle_State: 'Stav',
	Vehicle_IsOnWeb: 'Je na webu?',
	Vehicle_Milage: 'Tachometr',
	Vehicle_LicencePlate: 'SPZ',
	Vehicle_TextAnalysis: 'Rozbor',
	Vehicle_MarketingAnalysis: 'Marketingový rozbor',
	Vehicle_HasBmwPremiumSelection: 'BMW premium selection',
	Vehicle_ChassisNumber: 'Chassis Number',
	Vehicle_ProcessingType: 'Processing type',
	Vehicle_IsBmwM: 'BMW M',
	Vehicle_IsBonusRelevant: 'Bonus relevantní',
	Vehicle_IsFinanceRelevant: 'Financ relevantní',
	Vehicle_IsRetailRelevant: 'Retail relevantí',
	Vehicle_MandatoryRegistrationAmountOfMonts: 'Povinná registrace',
	Vehicle_EndOfMandatoryRegistrationAt: 'Konec povinné registrace',
	Vehicle_CommissioningDateAt: 'Datum uvedení',
	Vehicle_CommissioningDate2At: 'Datum uvedení 2',
	Vehicle_OtherCosts: 'Ostatni náklady',
	Vehicle_BmwFs: 'Datum vykázání BMW FS',
	Vehicle_AmountOfMonts: 'Splacení BMW FS',
	Vehicle_DealerSearchingCode: 'DSC',
	Vehicle_GearCodes: 'Výbava',
	VehicleModel_Brand: 'Značka',
	VehicleModel_Model: 'Model',
	VehicleModel_Code: 'Model code',
	VehicleModel_Series: 'Model Series',
	VehicleModel_ModelGroup: 'Model group',
	VehicleModel_Body: 'Model body',
	VehiclePrices_ActionPrice: 'Akční cena',
	VehiclePrices_ActualListingPrice: 'Aktuálni skladová cena',
	VehiclePrices_Tax: 'Daň',
	VehiclePrices_ListPriceWithPDI: 'Ceníková cena včetně PDI',
	VehiclePrices_ListPriceWithTax: 'Ceníková cena s DPH',
	VehiclePrices_BaseModelPriceWithTax: 'Cena základního modelu včetně DPH',
	VehiclePrices_ContractPriceWithTax: 'Smluvní cena s DPH',
	Color_Code: 'Barva',
	Color_Description: 'Popis barvy',
	Upholstered_Code: 'Polstrování',
	Upholstered_Description: 'Polstrování popis',
	FleetBonuses_Percentage: 'Fleet bonus %',
	FleetBonuses_Estimate: 'Fleet bonus dohad',
	FleetBonuses_Value: 'Fleet bonus skutečnos',
	FleetBonuses_Text: 'Fleet bonus text',
	VehicleInsurances_HasMandatory: 'Povinné ručení',
	VehicleInsurances_HasAccident: 'Havarijní pojištění',
	VehicleInsurances_Since: 'Pojištění od',
	VehicleInsurances_To: 'Pojištění do',
	VehicleInsurances_Company: 'Pojišťovna',
	VehicleDispositions_IsCustomerOrder: 'Interní obj. DISPO',
	VehicleDispositions_CustomerOrderCheckedAt: 'Interní obj. DISPO',
	VehicleDeposit_Deposit: 'Záloha',
	VehicleDeposit_AlpinaDeposit: 'Alpina VZ',
	VehicleDeposit_AlpinaDepositAt: 'Datum alpina VZ',
	VehicleInterests_Costs: 'Úrokové náklady',
	VehicleInterests_CountToInternalCosts: 'Počítat do úrokových nákladů',
	VehicleLocations_WarehouseName: 'Sklad',
	VehicleLocations_LocationCode: 'Umístění (kód)',
	VehicleProduction_ManufactureDate: 'Datum výroby',
	VehicleProduction_RequestedWeek: 'Plánovaný týden výroby',
	VehicleProduction_ConfirmedWeek: 'Potvrzený týden výroby',
	VehicleProduction_ProductionDate: 'Datum výrobního statusu',
	VehicleProduction_ProductionStatusCode: 'Výrobní status',
	VehicleProduction_Description: 'Výrobní status popis',
	VehicleProduction_ProductionStatusId: 'Výrobní status id',
	VehicleRevenues_Estimate: 'Ostatní výnosy - dohad',
	VehicleRevenues_Real: 'Ostatní výnosy - skutečnost',
	VehicleRevenues_Description: 'Ostatní výnosy - popis',
	TechnicalCertificates_Number: 'Číslo technického průkazu',
	TechnicalCertificates_WasRequested: 'Bylo zažádáno o technický průkaz',
	TechnicalCertificates_RequestedAt: 'Kdy bylo zažádáno o technický průkaz',
	Loans_WarehouseName: 'Sklad zápůjčky',
	Loans_LoanedFrom: 'Zapůjčeno od',
	Loans_LoanedTo: 'Zapůjčeno do',
	Loans_Description: 'Zapůjčeno popis',
	VehiclePurchases_BwmInvoiceNumber: 'Fakturace z AG',
	VehiclePurchases_IsPaidBySupplier: 'Placeno dodavateli',
	VehiclePurchases_PriceIncludingTax: 'Nákupní cena s DPH',
	VehiclePurchases_TaxRate: 'Sazba DPH',
	VehiclePurchases_IsFinanceByLoan: 'Financováno',
	VehiclePurchases_BankAbbrevation: 'Zkratka banky',
	VehiclePurchases_BankName: 'Jméno banky',
	VehicleTransports_ArrivedAt: 'Příjezd kamionu',
	VehicleTransports_Price: 'Cena transportu',
	VehicleTransports_WasDamaged: 'Poškozeno během transportu',
	VehicleTransports_DamagedInvoiceNumber: 'Číslo faktury při poškození',
	VehicleTransport_WasDamagedPaid: 'Poškození při transportu uhrazeno',
	VehicleTransports_DamagedSendToBmwAt: 'Odesláno na LO',
	Configuration_FleetContractCategoryCode: 'Kategorie',
	Configuration_FleetContractCategoryText: 'Kategorie text',
	Configuration_OfferCode: 'Nabídka',
	Configuration_OfferText: 'Nabídka text',
	Configuration_OfferTwoCode: 'Nabídka 2',
	Configuration_OfferTwoText: 'Nabídka 2 text',
	Configuration_WebSectionCode: 'Sekce na webu',
	Configuration_WebSectionText: 'Sekce na webu text',
	VehicleSales_InvoiceNumber: 'Číslo faktury',
	VehicleSales_SoldAt: 'Prodáno',
	VehicleSales_InternalForBwmSoldAt: 'Retail Sales datum AG',
	VehicleSales_SalesNote: 'Poznámka prodej',
	VehicleSales_EstimatedHandoverAt: 'Odhad předání',
	VehicleSales_CustomerForBmw: 'Zákazník BMW',
	VehicleSales_InvoiceCreatedAt: 'Datum vystavení faktury',
	VehicleSales_PaymentMethod: 'Způsob financování',
	VehicleSales_Customer2: 'Zákazník Invelt',
	VehicleSales_SalesBonus: 'Předplatba bonusu',
	VehicleSales_SalesPerson: 'Prodejce',
	VehicleSales_Dealer: 'Dealer AG',
	VehicleSales_ReservationTill: 'Rezervace do',
	VehicleSales_ContractSignedAt: 'Datum podpisu smlouvy',
	VehicleSales_StraighteningMarginEstimate: 'Dorovnání marže dohad',
	VehicleSales_StraighteningMarginEstimatePercentage: 'Dorovnání marže %',
	VehicleSales_IsWarehouseOrder: 'Skladová objednávka',
	VehicleSales_WarehouseSalesPerson: 'Prodejce skladové objednávky',
	VehicleSales_OfferFromSA3: 'Nabídka SA3',
	VehicleSales_LeasingCompany: 'Leasingová společnost',
	Vehicle_IsElectricBonusRelevant: 'Elektrický bonus relevantní',
	Vehicle_IsLuxuryBonusRelevant: 'Luxury bonus relevantní',
	VehiclePrices_RetailPriceWithTax: 'Prodejní cena s DPH',
	VehicleDispositions_Note: 'Dispozice poznámka',
	VehicleLocations_LocationOnSet: 'Umístění ',
	VehiclePurchases_OrderNumber: 'Číslo objednávky',
	Vehicle_FleetContractCategoryCode: 'Kategorie',
	Vehicle_FleetContractCategoryText: 'Kategorie text',
	Vehicle_OfferCode: 'Nabídka',
	Vehicle_OfferText: 'Nabídka text',
	Vehicle_OfferTwoCode: 'Nabídka 2',
	Vehicle_OfferTwoText: 'Nabídka 2 text',
	Vehicle_WebSectionCode: 'Webová sekce',
	Vehicle_WebSectionText: 'Webová sekce text',
	VehicleSales_ReservationFor: 'Reservace pro',
	VehicleSales_LeasingBonusEstimate: 'Leasing bonus dohad',
	VehicleSales_LeasingBonus: 'Leasing bonus skutečnost ',
	Calculated_CurrentWarehouseName: 'HOM',
	Vehicle_Earnings: 'Výnos',
	Calculated_DaysOnWarehouse: 'Dní na skladě',
	Calculated_SalesBonusPercentage: 'Předplatba bonusu',
	Calculated_PurchasePriceWithoutTax: 'Nákupní cena bez DPH',
	Calculated_SalesPersonReward: 'Odměna prodejci',
	Audit_ModifiedOn: 'Datum změny',
	Audit_OldValue: 'Stará hodnota',
	Audit_NewValue: 'Nová hodnota',
	Audit_FieldName: 'Pole',
	Vehicle_ColorId: "Barva",
};

export const mapFieldToName = (field: string): string | undefined => {
	
	// @ts-ignore
	let value = map[field];
	if (!!value) {
		return value;
	}

	if (typeof field === 'string') {
		return field.toLowerCase().endsWith('brandid')
			? 'Značka'
			: undefined;
	}

	return field;
};

export const mapNameToField = (field: string): string => {

	const invertedMap = Object.fromEntries(Object.entries(map).map(([key, value]) => [value, key]));

	// @ts-ignore
	let value = invertedMap[field];
	if (!!value) {
		return value;
	}

	if (typeof field === 'string') {
		return field.toLowerCase().endsWith('brandid')
			? 'Značka'
			: field;
	}

	return field;
};

