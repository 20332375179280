import React from 'react';
import Alert from 'react-bootstrap/Alert';

function GlobalError(): JSX.Element {
	return (
		<Alert variant="danger" className="mt-2">
			<strong>Změny se nepodařilo uložit, kontaktujte prosím Administrátora.</strong>
		</Alert>
	);
}

export default GlobalError;
