import { useState, useEffect } from "react";

import authService from "./AuthorizationService";
import InitPage from '../../screens/InitPage';

interface AuthorizeRouteProps {
    element: any
}

function AuthorizeRoute(props: AuthorizeRouteProps): JSX.Element {
    const [loading, setLoading] = useState<boolean>(true);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        populateAuthenticationState();
    }, []);

    const populateAuthenticationState = async () => {
        const authenticated = await authService.isAuthenticated();
        if (authenticated && typeof authenticated === "string") {
            setErrorMessage(authenticated as string);
        }
        else {
            setAuthenticated(authenticated as boolean)
        } 
        setLoading(false);
    };

    return authenticated ? (
        <>
            { props.element}
        </>
        )
        : (
            <InitPage message={errorMessage} loading={loading} />
        )

}

export default AuthorizeRoute;