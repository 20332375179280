import {getTheme} from '@table-library/react-table-library/baseline';
import {
    Body,
    Cell,
    Header,
    HeaderCell,
    HeaderRow,
    Row as TableRow,
    Table,
} from '@table-library/react-table-library/table';
import {useTheme} from '@table-library/react-table-library/theme';
import React from 'react';
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from 'react-bootstrap/Modal';

import {useIntersDocuments} from "./useInters";
import {useIntersMarginAllMutation, useIntersMarginMutation} from "./useIntersMutation";
import Loader from "../../../components/Loader";
import {renderIcon} from "../../Lists/BasicListTable";

const documentTypeMap =(index: string) =>  {
    switch (index) {
        case "Account":
            return "Konto";
        case "Goods":
            return "Zboží";
        case "Work":
            return "Práce";
        default:
            return "Neznámý typ";

    }
}

function ModalInters({
                         vehicleId,
                         documentId,
                         onClose,
                     }: {
    documentId?: string | null;
    vehicleId?: string
    onClose: () => void;
}): React.JSX.Element {


    const {documentsQuery} = useIntersDocuments({
        vehicleId,
        documentId: documentId || ''
    });

    const {mutation: mutationAll, errorMessage, successMessage} = useIntersMarginAllMutation({
        id: vehicleId,
        documentId: documentId,
        onSuccess: () => {
            documentsQuery.refetch();
        },
    });

    const {
        mutation: mutationMargin,
        errorMessage: errorMarginMessage,
        successMessage: successMarginMessage
    } = useIntersMarginMutation({
        id: vehicleId,
        onSuccess: () => {
            documentsQuery.refetch();
        },
    });

    const getTableTheme = () => `--data-table-library_grid-template-columns: 10% 5% 10% 25% 10% 10% 10% 10% 10%  !important;`;

    const theme = useTheme([
        {
            Table: getTableTheme(),
        }
    ]);

    const tableData = {nodes: documentsQuery?.data || []};
    return (
        <Modal show={true} onHide={onClose} className='modal-xl'>
            <Modal.Header closeButton>
                <Modal.Title>Interky - {documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage}
                {successMessage}
                {successMarginMessage}
                {errorMarginMessage}
                <ButtonGroup className="mt-2 mb-4">
                    <Button
                        variant="primary"
                        onClick={() => mutationAll.mutate(true)}
                        disabled={false}
                    >
                        Přidat vše
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => mutationAll.mutate(false)}
                        disabled={false}
                    >
                        Odebrat vše
                    </Button>
                </ButtonGroup>
                {documentsQuery.isLoading ? (
                    <Loader/>
                ) : (
                        <Table data={tableData} theme={theme}>
                        {(tableList: Array<any>) => (
                            <>
                                <Header>
                                    <HeaderRow>
                                        <HeaderCell>Doklad</HeaderCell>
                                        <HeaderCell>Typ</HeaderCell>
                                        <HeaderCell>Číslo</HeaderCell>
                                        <HeaderCell>Název</HeaderCell>
                                        <HeaderCell>Množství</HeaderCell>
                                        <HeaderCell>Částka</HeaderCell>
                                        <HeaderCell>Středisko</HeaderCell>
                                        <HeaderCell>Vystavil</HeaderCell>
                                        <HeaderCell>Do nákladů</HeaderCell>
                                    </HeaderRow>
                                </Header>
                                <Body>
                                    {tableList.map((item, id) => (
                                        <TableRow
                                            key={item.id}
                                            item={item}
                                            onDoubleClick={(item) => {
                                                mutationMargin.mutate({documentId: item.id, value: !item.margin})
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <Cell>{item.documentId}</Cell>
                                            <Cell>{documentTypeMap(item.documentType)}</Cell>
                                            <Cell>{item.number}</Cell>
                                            <Cell>{item.name}</Cell>
                                            <Cell>{item.amount}</Cell>
                                            <Cell>{item.figure}</Cell>
                                            <Cell>{item.center}</Cell>
                                            <Cell>{item.exhibitedBy}</Cell>
                                            <Cell>{renderIcon(item.margin)}</Cell>
                                        </TableRow>
                                    ))}
                                </Body>
                            </>
                        )}
                    </Table>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default ModalInters;
