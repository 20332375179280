import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from "react-bootstrap/Tooltip";

import { ListItem } from "../../@types/lists";
import { HTTPMethod } from "../../constants/rest";
import csvImport from '../../icons/csvImport.svg';
import { apiFetcher, FetcherError } from "../../utils/fetcher";
import authService from "../Authorization/AuthorizationService";

interface ImportButtonProps {
    type: string,
    setRefresh: (value: boolean) => void,
    status: string | undefined
}

function ImportButton(props: ImportButtonProps): JSX.Element {

    const allowedRoles = ["Admin", "Manager"]

    const [isButtonVisible, setIsButtonVisible] = useState(false);

    useEffect(() => {
        isAuthorizeForAction();
    }, []);

    const mutation = useMutation<ListItem, FetcherError>({
        // @ts-ignore
        mutationFn: async () => {
            // ts:ignore

            await apiFetcher(`import/${props.type}`, {
                method: HTTPMethod.POST,
                body: "",
                headers: {
                    'Content-Type': 'application/json',
                },
            });

        },
        onSuccess: () => {
            alert("Import byl úspěšně spuštěn. Operace může trvat několik minut.");
            props.setRefresh(true);
        },
        onError: () => {
            alert("Při spuštení importu vznikla chyba. Prosím opakujte akci. V případě opakovaného problému se obraťte na Administrátora.");
            props.setRefresh(true);
        }
    });

    const isAuthorizeForAction = async () => {
        const isAuthorize = await authService.isAuthorizeForAction(allowedRoles);
        setIsButtonVisible(isAuthorize);
    }

    return (
        <>
            {isButtonVisible && <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                overlay={<Tooltip>CSV Import</Tooltip>}
            >
                <Button variant="light" onClick={() => mutation.mutate()} disabled={props.status === "InProgress"}>
                    <img style={{ height: '1.5em' }} src={csvImport} alt="CSV Import" />
                </Button>
            </OverlayTrigger>}
        </>
    )

}

export default ImportButton;