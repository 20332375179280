import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-final-form';

import { VehicleModel } from '../../../@types/vehicleModel';
import CheckGroup from '../../../components/form/fields/CheckGroup';

function ModalDataModel({
	modelData,
	onClose,
	onSelect,
	selectedModelId,
}: {
	modelData?: VehicleModel[];
	onClose: () => void;
	onSelect: (value: VehicleModel | undefined) => void;
	selectedModelId: string | undefined;
}): React.JSX.Element {
	const modelDataToCheckGroup = modelData?.map((model: VehicleModel) => ({
		label: [model.model, model.code, model.body].filter(Boolean).join(' | '),
		value: model.id,
	}));
	return (
		<Modal show={true} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Zvolte Model</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!modelData ? (
					<p className="text-danger">Vyskytla se chyba, zkuste to prosím znovu</p>
				) : (
					<Form
						onSubmit={(value) => {
							return onSelect(modelData?.find((model: VehicleModel) => model.id === value.id));
						}}
					>
						{({ handleSubmit }) => {
							return (
								<>
									<CheckGroup
										type="radio"
										name="id"
										fields={modelDataToCheckGroup as Array<{ label: string; value: string }>}
										initialValue={selectedModelId}
									/>
									<Button variant="primary" type="submit" className="mt-2" onClick={handleSubmit}>
										Potvrdit
									</Button>
								</>
							);
						}}
					</Form>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default ModalDataModel;
