import { useQuery } from '@tanstack/react-query';

import { VEHICLE_MODEL_DATA } from '../constants/queryKeys';
import { apiFetcher } from '../utils/fetcher';

const useVehicleModel = ({
	model,
	brandId,
	onSuccess,
	onError,
}: {
	model: string | undefined;
	brandId: string | undefined;
	onSuccess: () => void;
	onError: () => void;
}) => {
	const query = useQuery<any, Error>({
		queryKey: [VEHICLE_MODEL_DATA, { model, brandId }],
		queryFn: async (): Promise<any> => {
			const res = await apiFetcher(`configurations/model?model=${model}&brandId=${brandId}`);

			return res.json as any;
		},
		refetchOnWindowFocus: false,
		enabled: !!model && !!brandId,
		onSuccess,
		onError,
	});
	return query;
};

export default useVehicleModel;
