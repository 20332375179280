import styled from '@emotion/styled';
import {
	Cell,
} from '@table-library/react-table-library/table';

export const SCell = styled(Cell)<{ bgColor?: string}>`
	background-color: ${({ bgColor }) => bgColor || 'initial'}!important;
	height: 30px!important;
`;
export const STable = styled.div<{ width?: string}>`
	overflow-x: auto;
	font-size: 14px;
	scrollbar-gutter: stable;
	
	> table {
		min-width: 100%;
		width: ${({width}) => width || 'auto'};
		margin: 0;
		max-height: calc(100vh - 20rem);
		
		.stiff {
			width: 50px;
			height: 30px!important;
			text-align: center;
		}
	}

	.HeaderCell {
        align-items: start;
		border-right: 1px solid #F2F2F2;
    }
	
	.th:first-of-type {
		width: 50px!important;
	}
	
	.HeaderCell > div {
		white-space: normal!important;
	}
`;

export const SNumber = styled.span`
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
`;

export const SortIcon = styled.span`
	width: 24px;
	height: 24px;
`
