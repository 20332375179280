import React from 'react';

import ListTable from "../../components/ListTable";
import {OTHERS_ALL} from "../../constants/vehicleFilters";

function OtherList(): JSX.Element {
    return (
        <ListTable type={OTHERS_ALL} uniqueName={OTHERS_ALL} title="Ostatní" config={{ createButtonEnabled: true, deleteButtonEnabled: true, stornoButtonEnabled: true, importEnabled: false, detailEnabled:true }} />
    );
}

export default OtherList;
