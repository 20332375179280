export const ROOT = '/';
export const BMW_LIST = '/bmw/list';
export const BMW_CREATE = '/bmw/create';
export const BMW_DETAIL = '/bmw/:id/detail';
export const BMW_BONUSES = '/bmw/:id/bonuses';
export const BMW_SPECIFICATION = '/bmw/:id/specification';
export const BMW_LOANS = '/bmw/:id/loans';
export const BMW_AUDITS = '/bmw/:id/audits';
export const BMW_DOCUMENTS = '/bmw/:id/documents';
export const BMW_INTERS = '/bmw/:id/inters';
export const MOTO_LIST = '/moto/list';
export const MOTO_CREATE = '/moto/create';
export const MOTO_DETAIL = '/moto/:id/detail';
export const MOTO_BONUSES = '/moto/:id/bonuses';
export const MOTO_SPECIFICATION = '/moto/:id/specification';
export const MOTO_LOANS = '/moto/:id/loans';
export const MOTO_AUDITS = '/moto/:id/audits';
export const MOTO_DOCUMENTS = '/moto/:id/documents';
export const MOTO_INTERS = '/moto/:id/inters';
export const OTHER_LIST = '/other/list';
export const OTHER_CREATE = '/other/create';
export const OTHER_DETAIL = '/other/:id/detail';
export const OTHER_BONUSES = '/other/:id/bonuses';
export const OTHER_SPECIFICATION = '/other/:id/specification';
export const OTHER_LOANS = '/other/:id/loans';
export const OTHER_AUDITS = '/other/:id/audits';
export const OTHER_DOCUMENTS = '/other/:id/documents';
export const OTHER_INTERS = '/other/:id/inters';
export const LISTS = '/lists';
export const EXTENRAL_STORAGE = '/external/list';
export const ABOUT = '/about';
export const SETTINGS = '/settings'
export const USER_MANAGEMENT = '/users'
export const IMPORT_AUDIT = '/ImportAudit/list'
