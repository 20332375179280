import { useQuery } from '@tanstack/react-query';

import useInvoiceQuery from './useInvoiceQuery'
import {
	BankListItem,
	BasicListItem,
	SalesPersonListItem,
	WarehouseListItem,
} from '../../../@types/lists';
import { VehicleDetail } from '../../../@types/vehicleDetail';
import {
	BANK,
	BRAND,
	DEALER,
	INSURANCES,
	LEASING_COMPANIES,
	LOCATION,
	MARKETING,
	OFFER,
	OFFER_TWO,
	WAREHOUSE,
	WEB_SECTION,
	SALES_PERSON,
} from '../../../constants/lists';
import { apiFetcher } from '../../../utils/fetcher';
import { mapBasicListToOptions, mapListWithNameToOptions } from '../../../utils/lists';
import useListQuery from '../../Lists/useListQuery';
import useType from '../useType';

const useDetail = ({ id }: { id?: string }) => {
	const { id: typeID, name: typeName } = useType();
	const brandsQuery = useListQuery(BRAND, id, typeName);
	const webSectionsQuery = useListQuery(WEB_SECTION, id, typeName);
	const marketingQuery = useListQuery(MARKETING, id, typeName);
	const locationQuery = useListQuery(LOCATION, id, typeName);
	const dealerQuery = useListQuery(DEALER, id, typeName);
	const offerQuery = useListQuery(OFFER, id, typeName);
	const offerTwoQuery = useListQuery(OFFER_TWO, id, typeName);
	const leasingCompaniesQuery = useListQuery(LEASING_COMPANIES, id, typeName);
	const insuranceCompaniesQuery = useListQuery(INSURANCES, id, typeName);
	const warehousesQuery = useListQuery(WAREHOUSE, id, typeName);
	const salesPersonsQuery = useListQuery(SALES_PERSON, id, typeName);
	const banksQuery = useListQuery(BANK, id, typeName);
	const invoiceNumbersQuery = useInvoiceQuery(id)
	const detailQuery = useQuery<VehicleDetail, Error>({
		queryKey: ['vehicleDetail', { id }],
		queryFn: async (): Promise<VehicleDetail> => {
			const res = await apiFetcher(`vehicles/${id}?type=${typeID}`);

			return res.json as VehicleDetail;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
	const isLoading = [
		brandsQuery.isLoading,
		webSectionsQuery.isLoading,
		marketingQuery.isLoading,
		locationQuery.isLoading,
		dealerQuery.isLoading,
		offerQuery.isLoading,
		offerTwoQuery.isLoading,
		leasingCompaniesQuery.isLoading,
		insuranceCompaniesQuery.isLoading,
		warehousesQuery.isLoading,
		salesPersonsQuery.isLoading,
		banksQuery.isLoading,
		invoiceNumbersQuery.isLoading,
		id && detailQuery.isLoading,
	].some((item) => item === true);
	return {
		isLoading,
		brands: mapBasicListToOptions(brandsQuery.data as BasicListItem[]),
		webSections: mapBasicListToOptions(webSectionsQuery.data as BasicListItem[]),
		marketing: mapBasicListToOptions(marketingQuery.data as BasicListItem[]),
		locations: mapBasicListToOptions(locationQuery.data as BasicListItem[]),
		dealers: mapBasicListToOptions(dealerQuery.data as BasicListItem[]),
		offers: mapBasicListToOptions(offerQuery.data as BasicListItem[]),
		offersTwo: mapBasicListToOptions(offerTwoQuery.data as BasicListItem[]),
		leasingCompanies: mapBasicListToOptions(leasingCompaniesQuery.data as BasicListItem[]),
		insuranceCompanies: mapBasicListToOptions(insuranceCompaniesQuery.data as BasicListItem[]),
		warehouses: mapListWithNameToOptions(warehousesQuery.data as WarehouseListItem[]),
		salesPersons: mapListWithNameToOptions(salesPersonsQuery.data as SalesPersonListItem[]),
		banks: mapListWithNameToOptions(banksQuery.data as BankListItem[]),
		invoiceNumbers: invoiceNumbersQuery.invoiceNumbers,
		invoiceNumbersData: invoiceNumbersQuery.data,
		vehicleDetail: detailQuery.data,
		vehicleDetailRefetch: detailQuery.refetch,
	};
};

export default useDetail;
