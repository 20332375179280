export const BRAND = 'Brand';
export const MARKETING = 'Marketing';
export const CATEGORY = 'Category';
export const DEALER = 'Dealer';
export const INSURANCES = 'Insurances';
export const LEASING_COMPANIES = 'LeasingCompanies';
export const LOCATION = 'Location';
export const OFFER = 'Offer';
export const OFFER_TWO = 'OfferTwo';
export const BRANCH = 'Branch';
export const SALES_PERSONAL_REWARDS = 'SalesPersonRewards';
export const SELLING_CENTER = 'SellingCenter';
export const SUPPLIERS = 'Suppliers';
export const TP = 'TP';
export const WEB_SECTION = 'WebSection';

export const BANK = 'Bank';
export const PRODUCTION_STATUS = 'ProductionStatus';
export const LINE_COLOR = 'LineColor';
export const SALES_PERSON = 'SalesPerson';
export const VEHICLE_MODEL = 'VehicleModel';
export const WAREHOUSE = 'Warehouse';

export const COLOR = 'Color';
export const UPHOLSTER = 'Upholster';
export const GEAR = 'Gear';

export const BASIC_LISTS = [
	BRAND,
	CATEGORY,
	DEALER,
	LOCATION,
	OFFER,
	OFFER_TWO,
	BRANCH,
	SALES_PERSONAL_REWARDS,
	SELLING_CENTER,
	SUPPLIERS,
	TP,
	WEB_SECTION,
];

export const EQUIPMENT_LISTS = [COLOR, UPHOLSTER, GEAR];

export const branchOptions = [
	{ label: 'Praha', value: 'A' },
	{ label: 'Plzeň', value: 'P' },
];

export const vehicleTypeOptions = [
	{ label: 'BMW', value: 'BMW' },
	{ label: 'MOTO', value: 'MOTO' },
	{ label: 'BMWi', value: 'BMW' },
	{ label: 'Ostatní', value: 'OTHER' },
];
