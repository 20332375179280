import { createForm } from 'final-form';
import { noop, unset } from 'lodash';
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import useBonuses from './useBonuses';
import useDetailMutation from './useDetailMutation';
import SaveButton from '../../../components/buttons/SaveButton';
import CheckField from '../../../components/form/fields/CheckField';
import CurrencyField from '../../../components/form/fields/CurrencyField';
import DatePickerField from '../../../components/form/fields/DatePickerField';
import NumberField from '../../../components/form/fields/NumberField';
import RewardField from '../../../components/form/fields/RewardField';
import SelectField from '../../../components/form/fields/SelectField';
import SupportField from '../../../components/form/fields/SupportField';
import TextField from '../../../components/form/fields/TextField';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { BONUSES } from '../../../constants/detailViews';
import { usePrompt } from '../../../hooks/useBlocker';
import Navigation from '../Navigation';

function Bonuses(): JSX.Element {
	const { id } = useParams();
	const {
		categories,
		leasingCompanies,
		rewards,
		isLoading,
		vehicleRewards,
		vehicleSupports,
		vehicleDetail,
		vehicleDetailRefetch,
	} = useBonuses({
		id,
	});
	const [form] = useState(() =>
		createForm({
			onSubmit: (values: any, form) => {
				const dirtyFields = form.getState().dirtyFields;
				const { color_Code, upholster_Code, ...changedFields } = dirtyFields;
				const salesrewards = [
					values.salesrewards_0,
					values.salesrewards_1,
					values.salesrewards_2,
					values.salesrewards_3,
				];
				const supports = [
					values.vehiclesupports_0,
					values.vehiclesupports_1,
					values.vehiclesupports_2,
					values.vehiclesupports_3,
				];

				let extraData = {};
				salesrewards.forEach((reward, index) => {
					if (Object.keys(changedFields).some((key) => key.includes(`salesrewards_${index}`))) {
						extraData = { ...extraData, [`salesrewards_${index}`]: reward };
						unset(changedFields, `salesrewards_${index}.amount`);
						unset(changedFields, `salesrewards_${index}.codeId`);
						unset(changedFields, `salesrewards_${index}.isActive`);
					}
				});
				supports.forEach((support, index) => {
					if (Object.keys(changedFields).some((key) => key.includes(`vehiclesupports_${index}`))) {
						extraData = { ...extraData, [`vehiclesupports_${index}`]: support };
						unset(changedFields, `vehiclesupports_${index}.description`);
						unset(changedFields, `vehiclesupports_${index}.documentNumber`);
						unset(changedFields, `vehiclesupports_${index}.estimate`);
						unset(changedFields, `vehiclesupports_${index}.onePercent`);
						unset(changedFields, `vehiclesupports_${index}.real`);
					}
				});
				// @ts-ignore
				return mutation.mutate({
					formData: values,
					changedFields,
					extraData,
				});
			},
		})
	);
	const { mutation, errorMessage, successMessage } = useDetailMutation({
		id,
		onSuccess: () => {
			vehicleDetailRefetch();
			form.reset();
		},
	});
	const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
	usePrompt('Opravdu chcete opustit formulář?', isFormDirty && !mutation.isLoading);
	return (
		<Layout>
			<Navigation activeView={BONUSES} id={id as string} />
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Form form={form} onSubmit={noop}>
						{({ dirty, handleSubmit, dirtyFields, values }) => {
							if (!isFormDirty && dirty) {
								setIsFormDirty(true);
							} else if (isFormDirty && !dirty) {
								setIsFormDirty(false);
							}
							return (
								<>
									<Row>
										<Col>
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-12 col-md-2">
														<CurrencyField
															label="Nákupní cena bez DPH"
															name="calculated_PurchasePriceWithoutTax"
															initialValue={vehicleDetail?.calculated_PurchasePriceWithoutTax}
															disabled
														/>
													</div>
													<div className="col-12 col-md-2">
														<CurrencyField
															label="Nákupní cena s DPH"
															name="vehiclePurchases_PriceIncludingTax"
															initialValue={vehicleDetail?.vehiclePurchases_PriceIncludingTax}
														/>
													</div>
													<div className="col-12 col-md-2">
														<CurrencyField
															label="Cena základního modelu vč. DPH"
															name="vehiclePrices_BaseModelPriceWithTax"
															initialValue={vehicleDetail?.vehiclePrices_BaseModelPriceWithTax}
														/>
													</div>
													<div className="col-12 col-md-2">
														<CurrencyField
															label="Ceníková cena vč. DPH"
															name="vehiclePrices_ListPriceWithTax"
															initialValue={vehicleDetail?.vehiclePrices_ListPriceWithTax}
														/>
													</div>
													<div className="col-12 col-md-2">
														<CurrencyField
															label="Výnos"
															name="calculated_Earnings"
															initialValue={vehicleDetail?.calculated_Earnings}
															disabled
														/>
													</div>
													<div className="col-12 col-md-2">
														<div className="mt-4">
															{errorMessage}
															{successMessage}
															<SaveButton
																onClick={handleSubmit}
																disabled={mutation.isLoading || !dirty}
																size="lg"
																	/>
														</div>
													</div>
												</div>
												<Accordion alwaysOpen defaultActiveKey={['0']}>
													<Accordion.Item eventKey="0">
														<Accordion.Header>Podpory</Accordion.Header>
														<Accordion.Body>
															<div className="row align-center">
																<div className="col-12 col-md-3">
																	<CheckField
																		label="Bonus relevantní"
																		name="vehicles_IsBonusRelevant"
																		initialValue={vehicleDetail?.vehicles_IsBonusRelevant}
																		type="checkbox"
																	/>
																</div>
															</div>
															<div className="row align-items-center">
																<div className="col-12 col-md-1">
																	<strong>Fleet Bonus:</strong>
																</div>
																<div className="col-12 col-md-1">
																	<NumberField
																		label="%"
																		name="fleetBonuses_Percentage"
																		initialValue={vehicleDetail?.fleetBonuses_Percentage}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Dohad"
																		name="fleetBonuses_Estimate"
																		initialValue={vehicleDetail?.fleetBonuses_Estimate}
																		disabled={
																			values.fleetBonuses_Percentage &&
																			Number(values.fleetBonuses_Percentage) !== 0
																		}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Skutečnost"
																		name="fleetBonuses_Value"
																		initialValue={vehicleDetail?.fleetBonuses_Value}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<TextField
																		label="Popis"
																		name="fleetBonuses_Text"
																		initialValue={vehicleDetail?.fleetBonuses_Text}
																		shouldCopyOnDoubleClick
																	/>
																</div>
															</div>
															{/* @ts-ignore */}
															{vehicleSupports?.map((support, index) => (
																<SupportField
																	initialValues={support}
																	values={values}
																	name={`vehiclesupports_${index}`}
																	key={index}
																	number={index + 1}
																/>
															))}
															{Array(4 - (vehicleSupports?.length || 0))
																.fill(null)
																.map((_, index) => (
																	<SupportField
																		name={`vehiclesupports_${
																			(vehicleSupports?.length || 0) + index
																		}`}
																		key={index}
																		number={(vehicleSupports?.length || 0) + index + 1}
																		values={values}
																	/>
																))}
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="1">
														<Accordion.Header>Bonusy ostatní</Accordion.Header>
														<Accordion.Body>
															<div className="row">
																<div className="col-12 col-md-3">
																	<NumberField
																		label="Předplatba bonusu %"
																		name="calculated_SalesBonusPercentage"
																		initialValue={vehicleDetail?.calculated_SalesBonusPercentage}
																		disabled
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<CurrencyField
																		label="Předplatba bonusu"
																		name="vehicleSales_SalesBonus"
																		initialValue={vehicleDetail?.vehicleSales_SalesBonus}
																	/>
																</div>
															</div>
															<div className="row align-items-center">
																<div className="col-12 col-md-1">
																	<strong>Dorovnání marže:</strong>
																</div>
																<div className="col-12 col-md-2">
																	<NumberField
																		label="%"
																		name="vehicleSales_StraighteningMarginEstimatePercentage"
																		initialValue={
																			vehicleDetail?.vehicleSales_StraighteningMarginEstimatePercentage
																		}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Dohad"
																		name="vehicleSales_StraighteningMarginEstimate"
																		initialValue={
																			vehicleDetail?.vehicleSales_StraighteningMarginEstimate
																		}
																		disabled={
																			values.vehicleSales_StraighteningMarginEstimatePercentage &&
																			Number(
																				values.vehicleSales_StraighteningMarginEstimatePercentage
																			) !== 0
																		}
																	/>
																</div>
															</div>

															<div className="row align-items-center">
																<div className="col-12 col-md-1">
																	<strong>Leasing Bonus:</strong>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Dohad"
																		name="vehicleSales_LeasingBonusEstimate"
																		initialValue={vehicleDetail?.vehicleSales_LeasingBonusEstimate}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Skutečnost"
																		name="vehicleSales_LeasingBonus"
																		initialValue={vehicleDetail?.vehicleSales_LeasingBonus}
																	/>
																</div>
															</div>

															<div className="row align-items-center">
																<div className="col-12 col-md-1">
																	<strong>Ostatní výnosy:</strong>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Dohad"
																		name="vehicleRevenues_Estimate"
																		initialValue={vehicleDetail?.vehicleRevenues_Estimate}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<CurrencyField
																		label="Skutečnost"
																		name="vehicleRevenues_Real"
																		initialValue={vehicleDetail?.vehicleRevenues_Real}
																	/>
																</div>
																<div className="col-12 col-md-2">
																	<TextField
																		label="Popis"
																		name="vehicleRevenues_Description"
																		initialValue={vehicleDetail?.vehicleRevenues_Description}
																	/>
																</div>
															</div>
															<div className="row">
																<div className="col-12 col-md-3">
																	<CurrencyField
																		label="Ostatní náklady"
																		name="vehicles_OtherCosts"
																		initialValue={vehicleDetail?.vehicles_OtherCosts}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<CurrencyField
																		label="Interní náklady"
																		name="calculated_InternalDocumentsFigure"
																		initialValue={vehicleDetail?.calculated_InternalDocumentsFigure}
																		disabled
																	/>
																</div>
															</div>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="2">
														<Accordion.Header>Fleet</Accordion.Header>
														<Accordion.Body>
															<div className="row">
																<div className="col-12 col-md-3">
																	<SelectField
																		label="Kategorie"
																		name="vehicles_FleetContractCategoryId"
																		options={categories}
																		placeholder="Vyberte kategorii"
																		initialValue={vehicleDetail?.vehicles_FleetContractCategoryId}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<CheckField
																		label="Relevatní pro retail"
																		name="vehicles_IsRetailRelevant"
																		initialValue={vehicleDetail?.vehicles_IsRetailRelevant}
																		type="checkbox"
																		simple
																	/>

																	<CheckField
																		label="Financovaní relevantní"
																		name="vehicles_IsFinanceRelevant"
																		initialValue={vehicleDetail?.vehicles_IsFinanceRelevant}
																		type="checkbox"
																	/>
																</div>
															</div>
															<div className="row">
																{/* TODO: kde se bere, co je to za ciselnik? */}
																<div className="col-12 col-md-3">
																	<SelectField
																		label="Způsob financování"
																		name="vehicleSales_PaymentMethod"
																		options={[
																			{ label: 'Hotovost', value: 'Cash' },
																			{ label: 'Operativní leasing', value: 'OperativeLeasing' },
																			{ label: 'Ostatní způsoby financování', value: 'Other' },
																		]}
																		placeholder="Vyberte"
																		initialValue={vehicleDetail?.vehicleSales_PaymentMethod}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<SelectField
																		label="Leasingová firma"
																		name="vehicleSales_LeasingCompanyId"
																		options={leasingCompanies}
																		placeholder="Vyberte firmu"
																		initialValue={vehicleDetail?.vehicleSales_LeasingCompanyId}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<DatePickerField
																		label="Datum vykázání BMW FS"
																		name="vehicles_BmwFs"
																		initialValue={vehicleDetail?.vehicles_BmwFs}
																	/>
																</div>
																<div className="col-12 col-md-3">
																	<TextField
																		label="Počet měsíců splácení Leasingu"
																		name="vehicles_AmountOfMonts"
																		initialValue={vehicleDetail?.vehicles_AmountOfMonts}
																		shouldCopyOnDoubleClick
																	/>
																</div>
															</div>
														</Accordion.Body>
													</Accordion.Item>
													<Accordion.Item eventKey="3">
														<Accordion.Header>Odměny prodejci</Accordion.Header>
														<Accordion.Body>
															<div className="row">
																<div className="col-12 col-md-3">
																	<CurrencyField
																		label="Odměna prodejci"
																		name="calculated_SalesPersonReward"
																		initialValue={vehicleDetail?.calculated_SalesPersonReward}
																		disabled
																	/>
																</div>
															</div>
															{/* @ts-ignore */}
															{vehicleRewards?.map((reward, index) => (
																<RewardField
																	initialValues={reward}
																	rewards={rewards}
																	name={`salesrewards_${index}`}
																	key={index}
																/>
															))}
															{Array(4 - (vehicleRewards?.length || 0))
																.fill(null)
																.map((_, index) => (
																	<RewardField
																		rewards={rewards}
																		name={`salesrewards_${(vehicleRewards?.length || 0) + index}`}
																		key={index}
																	/>
																))}
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
												
											</form>
										</Col>
									</Row>
								</>
							);
						}}
					</Form>
				</>
			)}
		</Layout>
	);
}

export default Bonuses;
