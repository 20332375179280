import {
	BankListItem,
	BasicListItem,
	SalesPersonListItem,
	WarehouseListItem,
} from '../@types/lists';

export const mapBasicListToOptions = (
	listItems: BasicListItem[] | undefined
): Array<{ value: string; label: string }> => {
	if (!listItems || listItems.length === 0) return [];
	return listItems.map(({ id, text }) => ({ value: id, label: text }));
};

export const mapListWithNameToOptions = (
	listItems: WarehouseListItem[] | SalesPersonListItem[] | BankListItem[] | undefined
): Array<{ value: string; label: string }> => {
	if (!listItems || listItems.length === 0) return [];
	return listItems.map(({ id, name }) => ({ value: id, label: name }));
};
